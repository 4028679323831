import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import AppStorage from '~/lib/AppStorage';
import type {UserPrivate} from '~/records/UserRecord';
import * as RouterUtils from '~/utils/RouterUtils';

export enum LoginState {
	Default = 'default',
	Mfa = 'mfa',
}

enum StorageKey {
	Token = 'token',
	UserId = 'userId',
}

type State = Readonly<{
	loginState: LoginState;
	mfaTicket: string | null;
	token: string | null;
	userId: string | null;
}>;

const getStoredValue = (key: StorageKey): string | null => {
	try {
		return AppStorage.getItem(key);
	} catch (error) {
		console.error(`Error reading ${key} from localStorage:`, error);
		return null;
	}
};

const setStoredValue = (key: StorageKey, value: string): void => {
	try {
		AppStorage.setItem(key, value);
	} catch (error) {
		console.error(`Error writing ${key} to localStorage:`, error);
	}
};

const removeStoredValue = (key: StorageKey): void => {
	try {
		AppStorage.removeItem(key);
	} catch (error) {
		console.error(`Error removing ${key} from localStorage:`, error);
	}
};

const initialState: State = {
	loginState: LoginState.Default,
	mfaTicket: null,
	token: getStoredValue(StorageKey.Token),
	userId: getStoredValue(StorageKey.UserId),
};

class AuthenticationStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'AUTH_SESSION_CHANGE':
				return this.handleAuthSessionChange(action);
			case 'CONNECTION_CLOSED':
				return this.handleConnectionClosed(action);
			case 'SESSION_START':
				return this.handleSessionStart(action);
			case 'MFA_TICKET_SET':
				return this.handleMfaTicketSet(action);
			case 'MFA_TICKET_CLEAR':
				return this.handleMfaTicketClear();
			case 'LOGOUT':
				return this.handleLogout();
			default:
				return false;
		}
	}

	getId(): string {
		return this.state.userId!;
	}

	getToken(): string {
		return this.state.token!;
	}

	getMfaTicket(): string {
		return this.state.mfaTicket!;
	}

	isAuthenticated(): boolean {
		return !!this.state.token && !!this.state.userId;
	}

	isInMfaState(): boolean {
		return this.state.loginState === LoginState.Mfa;
	}

	private handleConnectionOpen({user}: {user: UserPrivate}): boolean {
		setStoredValue(StorageKey.UserId, user.id);
		this.setState((prevState) => ({
			...prevState,
			userId: user.id,
		}));
		return true;
	}

	private handleAuthSessionChange({token}: {token: string}): boolean {
		setStoredValue(StorageKey.Token, token);
		this.setState((prevState) => ({
			...prevState,
			token,
		}));
		return true;
	}

	private handleConnectionClosed({code}: {code: number}): boolean {
		if (code === 4004) {
			this.handleLogout();
		}
		return true;
	}

	private handleSessionStart({token}: {token: string}): boolean {
		setStoredValue(StorageKey.Token, token);
		this.setState((prevState) => ({
			...prevState,
			loginState: LoginState.Default,
			token,
		}));
		return true;
	}

	private handleMfaTicketSet({ticket}: {ticket: string}): boolean {
		this.setState((prevState) => ({
			...prevState,
			loginState: LoginState.Mfa,
			mfaTicket: ticket,
		}));
		return true;
	}

	private handleMfaTicketClear(): boolean {
		this.setState((prevState) => ({
			...prevState,
			loginState: LoginState.Default,
			mfaTicket: null,
		}));
		return true;
	}

	private handleLogout(): boolean {
		removeStoredValue(StorageKey.Token);
		removeStoredValue(StorageKey.UserId);

		this.setState({
			loginState: LoginState.Default,
			mfaTicket: null,
			token: null,
			userId: null,
		});

		RouterUtils.replaceWith('/login');
		return true;
	}
}

export default new AuthenticationStore();
