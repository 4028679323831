import {ChatTeardrop, Clock, MapPin, Pencil} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {UserTypes} from '~/Constants';
import * as UserNoteActionCreators from '~/actions/UserNoteActionCreators';
import * as UserSettingsModalActionCreators from '~/actions/UserSettingsModalActionCreators';
import {UserTag} from '~/components/channel/UserTag';
import {FluxerIcon} from '~/components/icons/FluxerIcon';
import * as Modal from '~/components/modals/Modal';
import {GuildIcon} from '~/components/popouts/GuildIcon';
import {UserProfileSection} from '~/components/popouts/UserProfilePopout';
import {Avatar} from '~/components/uikit/Avatar';
import {Button} from '~/components/uikit/Button/Button';
import {Scroller} from '~/components/uikit/Scroller';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {useForceUpdate} from '~/hooks/useForceUpdate';
import {i18n} from '~/i18n';
import type {ProfileRecord} from '~/records/ProfileRecord';
import type {UserRecord} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import PresenceStore from '~/stores/PresenceStore';
import UserNoteStore from '~/stores/UserNoteStore';
import UserStore from '~/stores/UserStore';
import markupStyles from '~/styles/Markup.module.css';
import * as DateUtils from '~/utils/DateUtils';
import * as MarkupUtils from '~/utils/MarkupUtils';
import * as NicknameUtils from '~/utils/NicknameUtils';
import * as TimezoneUtils from '~/utils/TimezoneUtils';

type UserProfileModalProps = {
	userId: string;
	profile: ProfileRecord;
	autoFocusNote?: boolean;
};

type UserInfoProps = {
	user: UserRecord;
};

type UserNoteEditorProps = {
	userId: string;
	initialNote: string | null;
	autoFocus?: boolean;
};

type ProfileContentProps = {
	profile: ProfileRecord;
	user: UserRecord;
	userNote: string | null;
	autoFocusNote?: boolean;
};

const UserInfo: React.FC<UserInfoProps> = ({user}) => {
	return (
		<div className="select-text">
			<div className="flex items-center gap-0.5">
				<span className="inline whitespace-normal break-words break-all align-middle font-bold text-2xl text-text-primary leading-[1.25]">
					{NicknameUtils.getNickname(user)}
				</span>
			</div>

			<div className="block overflow-hidden font-medium text-[14px] text-text-tertiary leading-[18px]">
				<span className="inline overflow-auto whitespace-normal break-all align-middle">{user.handle}</span>

				{user.pronouns && (
					<>
						<div
							aria-hidden={true}
							className="ml-1 inline-block h-1 w-1 rounded-full bg-text-primary-muted align-middle"
						/>
						<Tooltip text={i18n.Messages.PRONOUNS}>
							<span className="ml-1 align-middle">{user.pronouns}</span>
						</Tooltip>
					</>
				)}

				<div className="inline">{user.type === UserTypes.AUTOMATED && <UserTag className="mt-[.3em]" />}</div>
			</div>
		</div>
	);
};

const UserNoteEditor: React.FC<UserNoteEditorProps> = ({userId, initialNote, autoFocus}) => {
	const [isEditing, setIsEditing] = React.useState(false);
	const [localNote, setLocalNote] = React.useState<string | null>(null);
	const noteRef = React.useRef<HTMLTextAreaElement | null>(null);

	React.useEffect(() => {
		if (autoFocus && noteRef.current) {
			noteRef.current.focus();
		}
	}, [autoFocus]);

	const handleBlur = () => {
		if (localNote != null && localNote !== initialNote) {
			UserNoteActionCreators.update(userId, localNote);
		}
		setIsEditing(false);
	};

	const handleFocus = () => {
		setIsEditing(true);
		if (noteRef.current) {
			const length = noteRef.current.value.length;
			noteRef.current.setSelectionRange(length, length);
		}
	};

	return (
		<div className="flex flex-col gap-1">
			<span className="font-semibold text-sm text-text-primary">{i18n.Messages.NOTE}</span>
			<TextareaAutosize
				ref={noteRef}
				aria-label={i18n.Messages.NOTE}
				className={clsx(
					'no-scrollbar relative flex max-h-[88px] min-h-[44px]',
					'w-full resize-none overflow-x-hidden overflow-y-scroll',
					'whitespace-pre-wrap break-words rounded-md p-1',
					'text-[14px] leading-[16px]',
					isEditing
						? 'border border-background-header-secondary bg-background-secondary'
						: 'border border-transparent bg-transparent',
				)}
				defaultValue={initialNote ?? undefined}
				maxLength={256}
				onBlur={handleBlur}
				onChange={(event) => setLocalNote(event.target.value)}
				onFocus={handleFocus}
				placeholder={isEditing ? undefined : i18n.Messages.NOTE_PLACEHOLDER}
			/>
		</div>
	);
};

const ProfileContent: React.FC<ProfileContentProps> = ({profile, user, userNote, autoFocusNote}) => {
	const forceUpdate = useForceUpdate();
	const timeAndOffset = profile?.timezoneOffset != null ? TimezoneUtils.getTimeAndOffset(profile.timezoneOffset) : null;

	React.useEffect(() => {
		if (profile?.timezoneOffset == null) return;

		const updateOnFullMinute = () => {
			forceUpdate();
			setTimeout(updateOnFullMinute, 60_000 - (Date.now() % 60_000));
		};

		const timeout = setTimeout(updateOnFullMinute, 60_000 - (Date.now() % 60_000));
		return () => clearTimeout(timeout);
	}, [forceUpdate, profile?.timezoneOffset]);

	const renderBioAndLocation = () => {
		if (!profile?.userProfile.bio && !profile?.userProfile.location && !timeAndOffset) {
			return null;
		}

		return (
			<div className="flex flex-col gap-2">
				{profile?.userProfile.bio && (
					<div className={clsx(markupStyles.markup, markupStyles.bio)}>
						{MarkupUtils.safeParse(profile.userProfile.bio, {type: 'bio'})}
					</div>
				)}

				{(profile.userProfile.location || timeAndOffset) && (
					<div className="flex flex-col gap-0.5">
						{profile.userProfile.location && (
							<UserProfileSection icon={MapPin} label={i18n.Messages.LOCATION}>
								<span className="select-text text-sm text-text-primary">{profile.userProfile.location}</span>
							</UserProfileSection>
						)}

						{timeAndOffset && (
							<UserProfileSection icon={Clock} label={i18n.Messages.LOCAL_TIME}>
								<span className="select-text text-sm text-text-primary">
									{timeAndOffset.time} <span className="text-text-primary-muted">— {timeAndOffset.offset}</span>
								</span>
							</UserProfileSection>
						)}
					</div>
				)}
			</div>
		);
	};

	const renderMembershipInfo = () => {
		if (profile?.guild && profile.guildMember) {
			return (
				<div className="flex flex-col gap-1">
					<span className="font-semibold text-sm text-text-primary">{i18n.Messages.MEMBER_SINCE}</span>
					<div className="flex items-center gap-2">
						<div className="flex items-center gap-1">
							<Tooltip text="Fluxer">
								<div className="cursor-pointer">
									<FluxerIcon className="h-4 w-4 text-text-chat" />
								</div>
							</Tooltip>
							<span className="text-sm text-text-chat">{DateUtils.getFormattedShortDate(user.createdAt)}</span>
						</div>
						<div className="flex items-center gap-1">
							<Tooltip text={profile.guild.name}>
								<div className="cursor-pointer">
									<GuildIcon
										id={profile.guild.id}
										name={profile.guild.name}
										icon={profile.guild.icon}
										className="h-4 w-4 text-[8px]"
									/>
								</div>
							</Tooltip>
							<span className="text-sm text-text-chat">
								{DateUtils.getFormattedShortDate(profile.guildMember.joinedAt)}
							</span>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="flex flex-col gap-1">
				<span className="font-semibold text-sm text-text-primary">{i18n.Messages.FLUXER_MEMBER_SINCE}</span>
				<span className="text-sm text-text-chat">{DateUtils.getFormattedShortDate(user.createdAt)}</span>
			</div>
		);
	};

	return (
		<div className="flex h-full flex-col gap-5">
			<div className="flex flex-shrink-0 flex-col gap-3">
				{renderBioAndLocation()}
				{renderMembershipInfo()}
				<UserNoteEditor userId={user.id} initialNote={userNote} autoFocus={autoFocusNote} />
			</div>
		</div>
	);
};

export const UserProfileModal: React.FC<UserProfileModalProps> = ({userId, profile, autoFocusNote}) => {
	const status = PresenceStore.useUserStatus(userId);
	const user = UserStore.useUser(userId)!;
	const userNote = UserNoteStore.useUserNote(userId);
	const isCurrentUser = user.id === AuthenticationStore.getId();

	const handleEditProfile = () => {
		UserSettingsModalActionCreators.openUserSettingsTab('edit_profile');
	};

	return (
		<Modal.Root
			label={`User Profile: ${user.handle}`}
			size="small"
			className="flex h-[780px] w-[600px] flex-col overflow-hidden rounded-md border-4 border-brand-primary bg-background-primary"
		>
			<header>
				<div className="h-[210px] w-[600px] bg-brand-primary" />

				<div className="relative min-h-[38px] px-4 pt-4">
					<div className="-top-[68px] absolute left-[14px] z-0 rounded-full border-[8px] border-background-primary bg-background-primary">
						<Avatar forceAnimate={true} size={120} status={status} user={user} />
					</div>

					<div className="flex h-[38px] min-h-[38px] items-center justify-end gap-2">
						{isCurrentUser ? (
							<Button
								variant="brand"
								small={true}
								leftIcon={<Pencil className="h-4 w-4" />}
								onClick={handleEditProfile}
							>
								{i18n.Messages.EDIT_PROFILE}
							</Button>
						) : (
							<Button variant="brand" small={true} leftIcon={<ChatTeardrop className="h-4 w-4" />}>
								{i18n.Messages.MESSAGE}
							</Button>
						)}
					</div>
				</div>
			</header>

			<div className="z-elevated-1 m-4 flex h-full flex-col gap-[12px] overflow-hidden">
				<UserInfo user={user} />

				<div className="h-full overflow-auto rounded-md border border-background-header-secondary bg-background-primary p-4">
					<Scroller className="h-full">
						<ProfileContent profile={profile} user={user} userNote={userNote} autoFocusNote={autoFocusNote} />
					</Scroller>
				</div>
			</div>
		</Modal.Root>
	);
};
