import {X} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import {Permissions} from '~/Constants';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ReactionActionCreators from '~/actions/ReactionActionCreators';
import * as Modal from '~/components/modals/Modal';
import {Avatar} from '~/components/uikit/Avatar';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {useHover} from '~/hooks/useHover';
import {i18n} from '~/i18n';
import type {MessageReaction} from '~/records/MessageRecord';
import type {UserRecord} from '~/records/UserRecord';
import ChannelStore from '~/stores/ChannelStore';
import MessageReactionsStore from '~/stores/MessageReactionsStore';
import MessageStore from '~/stores/MessageStore';
import markupStyles from '~/styles/Markup.module.css';
import * as PermissionUtils from '~/utils/PermissionUtils';
import {emojiEquals, getEmojiName, getReactionKey, useEmojiURL} from '~/utils/ReactionUtils';

const MessageReactionItem = ({
	reaction,
	selectedReaction,
	setSelectedReaction,
}: {
	reaction: MessageReaction;
	selectedReaction: MessageReaction;
	setSelectedReaction: (reaction: MessageReaction) => void;
}) => {
	const [hoverRef, isHovering] = useHover();
	const emojiName = getEmojiName(reaction.emoji);
	const emojiUrl = useEmojiURL({emoji: reaction.emoji, isHovering});

	return (
		<Tooltip text={emojiName} position="left">
			<div
				aria-label={`${emojiName}, ${reaction.count} reaction${reaction.count === 1 ? '' : 's'}`}
				className={clsx(
					'mb-[4px] flex flex-row items-center overflow-hidden rounded-[8px] px-[4px]',
					emojiEquals(selectedReaction.emoji, reaction.emoji)
						? 'bg-background-tertiary text-text-tertiary'
						: 'text-text-tertiary-muted hover:bg-background-tertiary hover:text-text-tertiary',
				)}
				role="button"
				tabIndex={0}
				onClick={() => setSelectedReaction(reaction)}
				onKeyDown={(event) => event.key === 'Enter' && setSelectedReaction(reaction)}
				ref={hoverRef}
			>
				<img
					className={clsx(markupStyles.emoji, 'mt-[4px] mr-[8px] mb-[4px] ml-[4px] h-4 min-h-[auto] w-4 min-w-[auto]')}
					src={emojiUrl}
					alt={emojiName}
				/>
				<div className="font-bold text-[14px] leading-[1.2857]">{reaction.count}</div>
			</div>
		</Tooltip>
	);
};

export const MessageReactionsModal = ({
	channelId,
	messageId,
	openToReaction,
}: {
	channelId: string;
	messageId: string;
	openToReaction: MessageReaction;
}) => {
	const [reactors, setReactors] = React.useState<Array<UserRecord>>([]);
	const [selectedReaction, setSelectedReaction] = React.useState(openToReaction);
	const message = MessageStore.useMessage(channelId, messageId);
	const canManageMessages = PermissionUtils.can(Permissions.MANAGE_MESSAGES, {
		guildId: ChannelStore.getChannel(channelId)!.guildId,
		channelId,
	});

	React.useEffect(() => {
		const handleReactions = () => {
			setReactors(
				MessageReactionsStore.getReactions(channelId, messageId, selectedReaction.emoji) as Array<UserRecord>,
			);
		};
		const unsubscribe = MessageReactionsStore.subscribe(handleReactions);
		handleReactions();
		return unsubscribe;
	}, [channelId, messageId, selectedReaction.emoji]);

	React.useEffect(() => {
		if (!message || message.reactions.length === 0) {
			ModalActionCreators.pop();
		}
	}, [message]);

	if (!message) {
		return null;
	}

	return (
		<Modal.Root
			label={i18n.Messages.REACTIONS}
			size="small"
			className="flex h-[440px] w-[500px] flex-none flex-row overflow-hidden rounded-md border border-background-header-secondary bg-background-primary"
		>
			<ScrollArea className="w-[90px] flex-none border border-background-header-secondary border-r bg-background-primary p-2">
				{message.reactions.map((reaction) => (
					<MessageReactionItem
						key={getReactionKey(message.id, reaction.emoji)}
						reaction={reaction}
						selectedReaction={selectedReaction}
						setSelectedReaction={setSelectedReaction}
					/>
				))}
			</ScrollArea>
			<ScrollArea className="flex w-[410px] flex-1 flex-col bg-background-primary">
				{reactors.map((reactor) => (
					<div key={reactor.id} className="relative h-[44px]">
						<div className="flex h-[44px] flex-row flex-nowrap items-center justify-start truncate border border-background-header-secondary border-b px-4">
							<Avatar user={reactor} size={24} />
							<div className="ml-2 flex flex-auto flex-row flex-nowrap items-center justify-start gap-1 truncate">
								<span className="truncate text-base text-text-chat">{reactor.displayName}</span>
								<span className="text-sm text-text-chat-muted">{reactor.handle}</span>
							</div>
							{canManageMessages && (
								<button
									type="button"
									onClick={() =>
										ReactionActionCreators.removeReaction(channelId, messageId, selectedReaction.emoji, reactor.id)
									}
									className="ml-2 flex-none text-text-chat-muted hover:text-text-chat"
								>
									<X weight="regular" className="h-4 w-4" />
								</button>
							)}
						</div>
					</div>
				))}
			</ScrollArea>
		</Modal.Root>
	);
};
