import {ArrowLeft, ArrowRight, Globe, Hash, Info} from '@phosphor-icons/react';
import {noop} from 'lodash';
import {AnimatePresence, motion} from 'motion/react';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import * as UserActionCreators from '~/actions/UserActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import UserStore from '~/stores/UserStore';
import * as FormUtils from '~/utils/FormUtils';

type SetupMode = 'initial' | 'username' | 'domain';

type FormInputs = {
	username: string;
	password: string;
	domain?: string;
};

const slideVariants = {
	enter: (direction: number) => ({
		x: direction > 0 ? 300 : -300,
		opacity: 0,
	}),
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction: number) => ({
		zIndex: 0,
		x: direction < 0 ? 300 : -300,
		opacity: 0,
	}),
};

const DnsInputField = ({label, value}: {label: string; value: string}) => {
	const handleClick = () => {
		TextCopyActionCreators.copy(value);
	};

	return (
		<Input
			value={value}
			readOnly
			className="flex-1 cursor-pointer"
			aria-label={label}
			label={label}
			onClick={handleClick}
			onFocus={(e) => e.target.select()}
		/>
	);
};

const DomainVerificationSection = ({domain, userId}: {domain: string; userId: string}) => (
	<div className="rounded-lg border border-background-header-secondary bg-background-tertiary p-6 shadow-sm">
		<div className="space-y-4">
			<div className="flex items-center gap-2">
				<Info className="h-5 w-5 text-text-primary" />
				<h4 className="font-medium text-text-primary">DNS Verification Required</h4>
			</div>

			<div className="space-y-4 rounded-lg border border-background-header-secondary bg-background-secondary p-4">
				<DnsInputField label="Name" value={`_fluxer.${domain}`} />
				<DnsInputField label="Type" value="TXT" />
				<DnsInputField label="Content" value={`user=${userId}`} />
			</div>

			<p className="text-sm text-text-primary-muted">It may take a few minutes for the DNS record to propagate.</p>
		</div>
	</div>
);

export const UsernameChangeModal = () => {
	const user = UserStore.getCurrentUser()!;
	const [setupMode, setSetupMode] = useState<SetupMode>('initial');
	const [isVerifying, setIsVerifying] = useState(false);
	const [direction, setDirection] = useState(0);

	const form = useForm<FormInputs>({
		defaultValues: {
			username: user.username,
			password: '',
			domain: '',
		},
	});

	const handleModeChange = (mode: SetupMode, direction = 1) => {
		setDirection(direction);
		setSetupMode(mode);
		form.reset(
			mode === 'username'
				? {username: user.username, password: '', domain: ''}
				: {username: '', password: '', domain: ''},
		);
	};

	const verifyDomain = async () => {
		const domain = form.getValues('domain');
		const password = form.getValues('password');

		try {
			setIsVerifying(true);
			await UserActionCreators.verifyDomain(domain!, password);
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: i18n.Messages.USERNAME_CHANGED});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'domain');
		} finally {
			setIsVerifying(false);
		}
	};

	const handleSubmit = async (data: FormInputs) => {
		try {
			await UserActionCreators.update({
				username: data.username,
				password: data.password,
			});
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: i18n.Messages.USERNAME_CHANGED});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'username');
		}
	};

	const renderInitialContent = () => (
		<motion.div
			key="initial"
			custom={direction}
			variants={slideVariants}
			initial="center"
			animate="center"
			exit="exit"
			transition={{
				x: {type: 'spring', stiffness: 300, damping: 30},
				opacity: {duration: 0.2},
			}}
			className="w-full overflow-auto"
		>
			<div className="space-y-4 p-6">
				<button
					type="button"
					onClick={() => handleModeChange('username')}
					className="group flex w-full items-center gap-4 rounded-lg border border-background-header-secondary p-4 transition-colors hover:bg-background-modifier-hover"
					aria-label="Change Username"
				>
					<div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brand-primary/10">
						<Hash weight="regular" className="h-8 w-8 text-text-tertiary" />
					</div>
					<div className="flex-1 text-left">
						<h3 className="font-medium text-lg text-text-primary">FluxerTag</h3>
						<p className="text-sm text-text-primary-muted">Choose a Username#1234 tag</p>
					</div>
					<ArrowRight
						weight="regular"
						className="h-5 w-5 text-text-primary-muted transition-colors group-hover:text-text-primary"
					/>
				</button>

				<button
					type="button"
					onClick={() => handleModeChange('domain')}
					className="group flex w-full items-center gap-4 rounded-lg border border-background-header-secondary p-4 transition-colors hover:bg-background-modifier-hover"
					aria-label="Use Custom Domain"
				>
					<div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brand-primary/10">
						<Globe weight="regular" className="h-8 w-8 text-text-tertiary" />
					</div>
					<div className="flex-1 text-left">
						<h3 className="font-medium text-lg text-text-primary">Custom Domain</h3>
						<p className="text-sm text-text-primary-muted">Use your own domain name</p>
					</div>
					<ArrowRight
						weight="regular"
						className="h-5 w-5 text-text-primary-muted transition-colors group-hover:text-text-primary"
					/>
				</button>
			</div>
		</motion.div>
	);

	const renderUsernameContent = () => (
		<motion.div
			key="username"
			custom={direction}
			variants={slideVariants}
			initial="enter"
			animate="center"
			exit="exit"
			transition={{
				x: {type: 'spring', stiffness: 300, damping: 30},
				opacity: {duration: 0.2},
			}}
			className="w-full overflow-auto"
		>
			<div className="space-y-6 p-6">
				<Input
					{...form.register('username')}
					autoComplete="username"
					autoFocus
					error={form.formState.errors.username?.message}
					label={i18n.Messages.USERNAME}
					name="username"
					placeholder={i18n.Messages.USERNAME_PLACEHOLDER}
					type="text"
				/>

				<Input
					{...form.register('password')}
					autoComplete="current-password"
					error={form.formState.errors.password?.message}
					label={i18n.Messages.PASSWORD}
					placeholder="••••••••••••••••••••••••••••••"
					type="password"
				/>
			</div>
		</motion.div>
	);

	const renderDomainContent = () => (
		<motion.div
			key="domain"
			custom={direction}
			variants={slideVariants}
			initial="enter"
			animate="center"
			exit="exit"
			transition={{
				x: {type: 'spring', stiffness: 300, damping: 30},
				opacity: {duration: 0.2},
			}}
			className="w-full overflow-auto"
		>
			<div className="space-y-6 p-6">
				<Input
					{...form.register('domain')}
					label="Domain Name"
					placeholder="yourdomain.com"
					error={form.formState.errors.domain?.message}
				/>

				{form.watch('domain') && (
					<>
						<DomainVerificationSection domain={form.watch('domain')!} userId={user.id.toString()} />
						<Input
							{...form.register('password')}
							autoComplete="current-password"
							error={form.formState.errors.password?.message}
							label={i18n.Messages.PASSWORD}
							placeholder="••••••••••••••••••••••••••••••"
							type="password"
						/>
					</>
				)}
			</div>
		</motion.div>
	);

	const renderContent = () => {
		switch (setupMode) {
			case 'initial':
				return renderInitialContent();
			case 'username':
				return renderUsernameContent();
			case 'domain':
				return renderDomainContent();
		}
	};

	return (
		<Modal.Root label={i18n.Messages.CHANGE_USERNAME_MODAL_TITLE} size="small">
			<Form form={form} onSubmit={setupMode === 'username' ? handleSubmit : noop}>
				<div className="flex h-full w-full flex-col bg-background-secondary">
					<div className="relative flex items-center gap-4 border-background-header-secondary border-b px-4 py-3">
						{setupMode !== 'initial' && (
							<Button
								variant="ghost"
								small
								fitContent
								className="absolute flex items-center"
								onClick={() => handleModeChange('initial', -1)}
								aria-label="Go back"
							>
								<ArrowLeft weight="regular" className="h-4 w-4" />
								<span className="ml-1">Back</span>
							</Button>
						)}
						<h2 className="mx-auto font-semibold text-lg text-text-primary">
							{setupMode === 'initial' ? 'Choose Identity Type' : i18n.Messages.CHANGE_USERNAME_MODAL_TITLE}
						</h2>
					</div>

					<div className="min-h-0 flex-1">
						<div className="relative flex-1 overflow-hidden">
							<AnimatePresence initial={false} custom={direction} mode="wait">
								{renderContent()}
							</AnimatePresence>
						</div>
					</div>

					<div className="flex justify-end gap-3 border-background-header-secondary border-t p-4">
						<Button onClick={ModalActionCreators.pop} variant="ghost" aria-label="Cancel">
							{i18n.Messages.CANCEL}
						</Button>

						{setupMode !== 'initial' && (
							<Button
								onClick={setupMode === 'username' ? form.handleSubmit(handleSubmit) : verifyDomain}
								submitting={form.formState.isSubmitting || isVerifying}
								variant="brand"
								disabled={setupMode === 'domain' && (!form.watch('domain') || !form.watch('password'))}
								aria-label={setupMode === 'username' ? 'Submit Username Change' : 'Verify Domain'}
							>
								{i18n.Messages.DONE}
							</Button>
						)}
					</div>
				</div>
			</Form>
		</Modal.Root>
	);
};
