import type {GuildRoleRecord} from '~/records/GuildRoleRecord';
import type {UserPartial} from '~/records/UserRecord';
import {UserRecord} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import GuildStore from '~/stores/GuildStore';
import UserStore from '~/stores/UserStore';
import * as ColorUtils from '~/utils/ColorUtils';

export type GuildMember = Readonly<{
	user: UserPartial;
	avatar: string | null;
	nickname: string | null;
	roles: ReadonlyArray<string>;
	joined_at: number;
}>;

export class GuildMemberRecord {
	readonly guildId: string;
	readonly user: UserRecord;
	readonly avatar: string | null;
	readonly nickname: string | null;
	readonly roles: ReadonlySet<string>;
	readonly joinedAt: number;

	constructor(guildId: string, guildMember: GuildMember) {
		this.guildId = guildId;
		this.user = new UserRecord(guildMember.user);
		this.avatar = guildMember.avatar;
		this.nickname = guildMember.nickname;
		this.roles = new Set(guildMember.roles);
		this.joinedAt = guildMember.joined_at;

		UserStore.cacheUsers([this.user]);
	}

	withUpdates(updates: Partial<GuildMember>): GuildMemberRecord {
		return new GuildMemberRecord(this.guildId, {
			user: updates.user ?? this.user.toJSON(),
			avatar: updates.avatar ?? this.avatar,
			nickname: updates.nickname ?? this.nickname,
			roles: updates.roles ?? Array.from(this.roles),
			joined_at: updates.joined_at ?? this.joinedAt,
		});
	}

	withRoles(roles: Iterable<string>): GuildMemberRecord {
		return new GuildMemberRecord(this.guildId, {
			...this.toJSON(),
			roles: Array.from(roles),
		});
	}

	getSortedRoles(): ReadonlyArray<GuildRoleRecord> {
		const guild = GuildStore.getGuild(this.guildId);
		if (!guild) {
			return [];
		}

		return Array.from(this.roles)
			.map((roleId) => guild.roles[roleId])
			.filter((role): role is GuildRoleRecord => role !== undefined)
			.sort((a, b) => a.position - b.position);
	}

	getHighestRole(): GuildRoleRecord | undefined {
		return this.getSortedRoles()[0];
	}

	getHighestRoleColor(): string | undefined {
		const highestRole = this.getHighestRole();
		return highestRole?.color ? ColorUtils.int2rgb(highestRole.color) : undefined;
	}

	isCurrentUser(): boolean {
		return this.user.id === AuthenticationStore.getId();
	}

	toJSON(): GuildMember {
		return {
			user: this.user.toJSON(),
			avatar: this.avatar,
			nickname: this.nickname,
			roles: Array.from(this.roles),
			joined_at: this.joinedAt,
		};
	}
}
