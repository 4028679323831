import {ArrowSquareOut, Play} from '@phosphor-icons/react';
import {AnimatePresence, motion} from 'motion/react';
import {type FC, useCallback, useEffect, useState} from 'react';
import {thumbHashToDataURL} from 'thumbhash';
import type {MessageEmbed} from '~/records/MessageRecord';
import {createCalculator} from '~/utils/DimensionUtils';
import * as ImageCacheUtils from '~/utils/ImageCacheUtils';

const YOUTUBE_CONFIG = {
	DEFAULT_WIDTH: 400,
	ANIMATION_DURATION: 0.3,
	BUTTON_DELAY: 0.1,
} as const;

const youtubeCalculator = createCalculator({
	maxWidth: YOUTUBE_CONFIG.DEFAULT_WIDTH,
	responsive: true,
});

type EmbedYouTubeProps = {
	embed: MessageEmbed;
	width?: number;
};

type ThumbnailProps = {
	posterSrc: string;
	thumbHashURL?: string;
	posterLoaded: boolean;
	title?: string;
	onPlay: (event: React.MouseEvent | React.KeyboardEvent) => void;
	onOpenInNewTab: (event: React.MouseEvent | React.KeyboardEvent) => void;
};

const PlayButton: FC<{onClick: (event: React.MouseEvent) => void}> = ({onClick}) => (
	<motion.button
		initial={{scale: 0.9, opacity: 0}}
		animate={{scale: 1, opacity: 1}}
		transition={{duration: YOUTUBE_CONFIG.ANIMATION_DURATION, delay: YOUTUBE_CONFIG.BUTTON_DELAY}}
		className="group/play"
		onClick={onClick}
	>
		<div className="flex h-14 w-14 items-center justify-center rounded-full bg-black/75 transition-all duration-200 group-hover/play:scale-105 group-active/play:scale-95">
			<Play size={28} className="text-white" />
		</div>
	</motion.button>
);

const ExternalButton: FC<{onClick: (event: React.MouseEvent) => void}> = ({onClick}) => (
	<motion.button
		initial={{scale: 0.9, opacity: 0}}
		animate={{scale: 1, opacity: 1}}
		transition={{
			duration: YOUTUBE_CONFIG.ANIMATION_DURATION,
			delay: YOUTUBE_CONFIG.BUTTON_DELAY * 2,
		}}
		onClick={onClick}
		className="group/external"
	>
		<div className="flex h-14 w-14 items-center justify-center rounded-full bg-black/75 transition-all duration-200 group-hover/external:scale-105 group-active/external:scale-95">
			<ArrowSquareOut size={24} className="text-white" />
		</div>
	</motion.button>
);

const Thumbnail: FC<ThumbnailProps> = ({posterSrc, thumbHashURL, posterLoaded, title, onPlay, onOpenInNewTab}) => (
	<div
		className="group relative h-full w-full cursor-pointer overflow-hidden rounded-md bg-background-primary transition-all duration-200 ease-out hover:shadow-black/10 hover:shadow-lg"
		onClick={onPlay}
		onKeyDown={(e) => e.key === 'Enter' && onPlay(e)}
		role="button"
		tabIndex={0}
	>
		<AnimatePresence>
			{thumbHashURL && !posterLoaded && (
				<>
					<motion.img
						key="placeholder"
						initial={{opacity: 1}}
						exit={{opacity: 0}}
						transition={{duration: YOUTUBE_CONFIG.ANIMATION_DURATION}}
						src={thumbHashURL}
						alt="Video thumbnail"
						className="absolute inset-0 h-full w-full object-cover"
					/>
					<motion.div
						key="overlay"
						initial={{opacity: 1}}
						exit={{opacity: 0}}
						transition={{duration: YOUTUBE_CONFIG.ANIMATION_DURATION}}
						className="absolute inset-0 bg-black/40 transition-opacity duration-200 group-hover:opacity-50"
					/>
				</>
			)}

			<motion.img
				key="poster"
				initial={{opacity: 0}}
				animate={{opacity: posterLoaded ? 1 : 0}}
				transition={{duration: YOUTUBE_CONFIG.ANIMATION_DURATION}}
				src={posterSrc}
				alt={title || 'Video thumbnail'}
				className="absolute inset-0 h-full w-full object-cover transition-transform duration-500 ease-out group-hover:scale-[1.02]"
			/>
		</AnimatePresence>

		<div className="absolute inset-0 flex items-center justify-center">
			<div className="flex items-center gap-3">
				<PlayButton onClick={onPlay} />
				<ExternalButton onClick={onOpenInNewTab} />
			</div>
		</div>
	</div>
);

export const EmbedYouTube: FC<EmbedYouTubeProps> = ({embed, width = YOUTUBE_CONFIG.DEFAULT_WIDTH}) => {
	const [hasInteracted, setHasInteracted] = useState(false);
	const [posterLoaded, setPosterLoaded] = useState(false);

	if (!(embed.video && embed.thumbnail && embed.thumbnail.proxy_url)) {
		return null;
	}

	const posterSrc = embed.thumbnail.proxy_url;
	const thumbHashUrl = embed.thumbnail.placeholder
		? thumbHashToDataURL(Uint8Array.from(atob(embed.thumbnail.placeholder), (c) => c.charCodeAt(0)))
		: undefined;

	const {style: containerStyle, dimensions} = youtubeCalculator.calculate(
		{
			width: embed.video.width!,
			height: embed.video.height!,
		},
		{maxWidth: width, forceScale: true},
	);

	const aspectRatio = `${dimensions.width} / ${dimensions.height}`;

	useEffect(() => {
		ImageCacheUtils.loadImage(
			posterSrc,
			() => setPosterLoaded(true),
			() => setPosterLoaded(false),
		);
	}, [posterSrc]);

	const handleInitialPlay = useCallback((event: React.MouseEvent | React.KeyboardEvent) => {
		event.stopPropagation();
		setHasInteracted(true);
	}, []);

	const handleOpenInNewTab = useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			event.stopPropagation();
			window.open(embed.url, '_blank');
		},
		[embed.url],
	);

	if (!hasInteracted) {
		return (
			<div
				className="w-full select-none rounded-md"
				style={{
					...containerStyle,
					minHeight: `${dimensions.height}px`,
					aspectRatio,
				}}
			>
				<Thumbnail
					posterSrc={posterSrc}
					thumbHashURL={thumbHashUrl}
					posterLoaded={posterLoaded}
					title={embed.title}
					onPlay={handleInitialPlay}
					onOpenInNewTab={handleOpenInNewTab}
				/>
			</div>
		);
	}

	const embedVideoUrl = new URL(embed.video.url);
	embedVideoUrl.searchParams.set('autoplay', '1');
	embedVideoUrl.searchParams.set('auto_play', '1');

	return (
		<div
			className="relative h-full w-full overflow-hidden rounded-md bg-black"
			style={{
				...containerStyle,
				minHeight: `${dimensions.height}px`,
				aspectRatio,
			}}
		>
			<iframe
				allow="autoplay; fullscreen"
				sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
				src={embedVideoUrl.toString()}
				className="absolute inset-0 h-full w-full border-none"
				title={embed.title || 'YouTube video'}
			/>
		</div>
	);
};
