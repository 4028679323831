import {Permissions} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';
import type {UnicodeEmoji} from '~/lib/UnicodeEmojis';
import UnicodeEmojis from '~/lib/UnicodeEmojis';
import type {ChannelRecord} from '~/records/ChannelRecord';
import {type GuildEmoji, GuildEmojiRecord} from '~/records/GuildEmojiRecord';
import type {GuildMember} from '~/records/GuildMemberRecord';
import type {Guild, GuildReadyData} from '~/records/GuildRecord';
import GuildListStore from '~/stores/GuildListStore';
import GuildMemberStore from '~/stores/GuildMemberStore';
import UserStore from '~/stores/UserStore';
import * as PermissionUtils from '~/utils/PermissionUtils';
import * as RegexUtils from '~/utils/RegexUtils';

type State = Readonly<{
	skinTone: string;
}>;

const initialState: State = {
	skinTone: '',
};

export type Emoji = Readonly<
	Partial<GuildEmojiRecord> &
		Partial<UnicodeEmoji> & {
			name: string;
			allNamesString: string;
			uniqueName: string;
		}
>;

type GuildEmojiContext = Readonly<{
	emojis: ReadonlyArray<GuildEmojiRecord>;
	usableEmojis: ReadonlyArray<GuildEmojiRecord>;
}>;

class EmojiDisambiguations {
	private static _lastInstance: EmojiDisambiguations | null = null;
	private readonly guildId: string | null;
	private disambiguatedEmoji: ReadonlyArray<Emoji> | null = null;
	private customEmojis: ReadonlyMap<string, Emoji> | null = null;
	private emojisByName: ReadonlyMap<string, Emoji> | null = null;
	private emojisById: ReadonlyMap<string, Emoji> | null = null;

	private constructor(guildId?: string | null) {
		this.guildId = guildId ?? null;
	}

	static getInstance(guildId?: string | null): EmojiDisambiguations {
		if (!EmojiDisambiguations._lastInstance || EmojiDisambiguations._lastInstance.guildId !== guildId) {
			EmojiDisambiguations._lastInstance = new EmojiDisambiguations(guildId);
		}
		return EmojiDisambiguations._lastInstance;
	}

	static reset(): void {
		EmojiDisambiguations._lastInstance = null;
	}

	static clear(guildId?: string | null): void {
		if (EmojiDisambiguations._lastInstance?.guildId === guildId) {
			EmojiDisambiguations._lastInstance = null;
		}
	}

	getDisambiguatedEmoji(): ReadonlyArray<Emoji> {
		this.ensureDisambiguated();
		return this.disambiguatedEmoji ?? [];
	}

	getCustomEmoji(): ReadonlyMap<string, Emoji> {
		this.ensureDisambiguated();
		return this.customEmojis ?? new Map();
	}

	getByName(disambiguatedEmojiName: string): Emoji | undefined {
		this.ensureDisambiguated();
		return this.emojisByName?.get(disambiguatedEmojiName);
	}

	getById(emojiId: string): Emoji | undefined {
		this.ensureDisambiguated();
		return this.emojisById?.get(emojiId);
	}

	nameMatchesChain(testName: (name: string) => boolean): ReadonlyArray<Emoji> {
		return this.getDisambiguatedEmoji().filter(({names, name}) => (names ? names.some(testName) : testName(name)));
	}

	private ensureDisambiguated(): void {
		if (!this.disambiguatedEmoji) {
			const result = this.buildDisambiguatedCustomEmoji();
			this.disambiguatedEmoji = result.disambiguatedEmoji;
			this.customEmojis = result.customEmojis;
			this.emojisByName = result.emojisByName;
			this.emojisById = result.emojisById;
		}
	}

	private buildDisambiguatedCustomEmoji() {
		const emojiCountByName = new Map<string, number>();
		const disambiguatedEmoji: Array<Emoji> = [];
		const customEmojis = new Map<string, Emoji>();
		const emojisByName = new Map<string, Emoji>();
		const emojisById = new Map<string, Emoji>();

		const disambiguateEmoji = (emoji: Emoji): void => {
			const uniqueName = emoji.name;
			const existingCount = emojiCountByName.get(uniqueName) ?? 0;
			emojiCountByName.set(uniqueName, existingCount + 1);

			const finalEmoji =
				existingCount > 0
					? {
							...emoji,
							name: `${uniqueName}~${existingCount}`,
							uniqueName,
							allNamesString: `:${uniqueName}~${existingCount}:`,
						}
					: emoji;

			emojisByName.set(finalEmoji.name, finalEmoji);
			if (finalEmoji.id) {
				emojisById.set(finalEmoji.id, finalEmoji);
				customEmojis.set(finalEmoji.name, finalEmoji);
			}
			disambiguatedEmoji.push(finalEmoji);
		};

		UnicodeEmojis.forEachEmoji(disambiguateEmoji);

		const processGuildEmojis = (guildId: string) => {
			const guildEmoji = emojiGuildRegistry.get(guildId);
			if (!guildEmoji) return;
			guildEmoji.usableEmojis.forEach(disambiguateEmoji);
		};

		if (this.guildId) {
			processGuildEmojis(this.guildId);
		}

		for (const guild of GuildListStore.getGuilds().filter((guild) => guild.id !== this.guildId)) {
			processGuildEmojis(guild.id);
		}

		return {
			disambiguatedEmoji: Object.freeze(disambiguatedEmoji),
			customEmojis: new Map(customEmojis),
			emojisByName: new Map(emojisByName),
			emojisById: new Map(emojisById),
		};
	}
}

class EmojiGuildRegistry {
	private guilds = new Map<string, GuildEmojiContext>();
	private customEmojisById = new Map<string, GuildEmojiRecord>();

	reset(): void {
		this.guilds.clear();
		this.customEmojisById.clear();
		EmojiDisambiguations.reset();
	}

	deleteGuild(guildId: string): void {
		this.guilds.delete(guildId);
	}

	get(guildId: string): GuildEmojiContext | undefined {
		return this.guilds.get(guildId);
	}

	rebuildRegistry(): void {
		this.customEmojisById.clear();
		for (const guild of this.guilds.values()) {
			for (const emoji of guild.usableEmojis) {
				this.customEmojisById.set(emoji.id, emoji);
			}
		}
		EmojiDisambiguations.reset();
	}

	updateGuild(guildId: string, guildEmojis?: ReadonlyArray<GuildEmoji>): void {
		this.deleteGuild(guildId);
		EmojiDisambiguations.clear(guildId);

		if (!guildEmojis) return;

		const currentUser = UserStore.getCurrentUser();
		if (!currentUser) return;

		const localUser = GuildMemberStore.getMember(guildId, currentUser.id);
		if (!localUser) return;

		const emojis = guildEmojis
			.map((emoji) => new GuildEmojiRecord(guildId, emoji))
			.sort((a, b) => b.name.localeCompare(a.name));

		this.guilds.set(guildId, {
			emojis: Object.freeze(emojis),
			usableEmojis: Object.freeze(emojis),
		});
	}

	getGuildEmojis(guildId: string): ReadonlyArray<GuildEmojiRecord> {
		return this.guilds.get(guildId)?.usableEmojis ?? [];
	}
}

const emojiGuildRegistry = new EmojiGuildRegistry();

class EmojiStore extends PersistedStore<State> {
	constructor() {
		super(initialState, 'EmojiStore', 1, ['skinTone']);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'EMOJI_SKIN_TONE':
				return this.handleSkinTone(action);
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'GUILD_CREATE':
			case 'GUILD_UPDATE':
				return this.handleGuildUpdate(action);
			case 'GUILD_EMOJIS_UPDATE':
				return this.handleGuildEmojiUpdated(action);
			case 'GUILD_DELETE':
				return this.handleGuildDelete(action);
			case 'GUILD_MEMBER_UPDATE':
				return this.handleGuildMemberUpdate(action);
			default:
				return false;
		}
	}

	getSkinTone(): string {
		return this.state.skinTone;
	}

	get categories(): ReadonlyArray<string> {
		return Object.freeze(['custom', ...UnicodeEmojis.getCategories()]);
	}

	getGuildEmoji(guildId: string): ReadonlyArray<GuildEmojiRecord> {
		return emojiGuildRegistry.getGuildEmojis(guildId);
	}

	getDisambiguatedEmojiContext(guildId?: string | null): EmojiDisambiguations {
		return EmojiDisambiguations.getInstance(guildId);
	}

	getEmojiMarkdown(emoji: Emoji): string {
		return emoji.id ? `<${emoji.animated ? 'a' : ''}:${emoji.uniqueName}:${emoji.id}>` : `:${emoji.uniqueName}:`;
	}

	filterExternal(channel: ChannelRecord, nameTest: (name: string) => boolean, count: number): ReadonlyArray<Emoji> {
		let results = EmojiDisambiguations.getInstance(channel.guildId).nameMatchesChain(nameTest);

		const currentUser = UserStore.getCurrentUser();
		const canUseExternal = PermissionUtils.can(Permissions.USE_EXTERNAL_EMOJIS, {
			userId: currentUser?.id,
			guildId: channel.guildId,
			channelId: channel.id,
		});

		if (!canUseExternal) {
			results = results.filter((emoji) => !emoji.guildId || emoji.guildId === channel.guildId);
		}

		return count > 0 ? results.slice(0, count) : results;
	}

	search(channel: ChannelRecord, query: string, count = 0): ReadonlyArray<Emoji> {
		const lowerCasedQuery = query.toLowerCase();
		const escapedQuery = RegexUtils.escapeRegex(lowerCasedQuery);

		const containsRegex = new RegExp(escapedQuery, 'i');
		const startsWithRegex = new RegExp(`^${escapedQuery}`, 'i');
		const boundaryRegex = new RegExp(`(^|_|[A-Z])${escapedQuery}s?([A-Z]|_|$)`);

		const searchResults = this.filterExternal(channel, containsRegex.test.bind(containsRegex), 0);

		if (searchResults.length === 0) return searchResults;

		const getScore = (name: string): number => {
			const nameLower = name.toLowerCase();
			return (
				1 +
				(nameLower === lowerCasedQuery ? 4 : 0) +
				(boundaryRegex.test(nameLower) || boundaryRegex.test(name) ? 2 : 0) +
				(startsWithRegex.test(name) ? 1 : 0)
			);
		};

		const sortedResults = [...searchResults].sort((a, b) => {
			const aName = a.names?.[0] ?? a.name;
			const bName = b.names?.[0] ?? b.name;
			const scoreDiff = getScore(bName) - getScore(aName);
			return scoreDiff || aName.localeCompare(bName);
		});

		return count > 0 ? sortedResults.slice(0, count) : sortedResults;
	}

	private handleSkinTone({skinTone}: {skinTone: string}): boolean {
		this.setState({skinTone});
		return true;
	}

	private handleConnectionOpen({guilds}: {guilds: ReadonlyArray<GuildReadyData>}): boolean {
		emojiGuildRegistry.reset();
		for (const guild of guilds) {
			emojiGuildRegistry.updateGuild(guild.id, guild.emojis);
		}
		emojiGuildRegistry.rebuildRegistry();
		return true;
	}

	private handleGuildUpdate({guild}: {guild: Guild | GuildReadyData}): boolean {
		emojiGuildRegistry.updateGuild(guild.id, 'emojis' in guild ? guild.emojis : undefined);
		emojiGuildRegistry.rebuildRegistry();
		return true;
	}

	private handleGuildEmojiUpdated({guildId, emojis}: {guildId: string; emojis: ReadonlyArray<GuildEmoji>}): boolean {
		emojiGuildRegistry.updateGuild(guildId, emojis);
		emojiGuildRegistry.rebuildRegistry();
		return true;
	}

	private handleGuildDelete({guildId}: {guildId: string}): boolean {
		emojiGuildRegistry.deleteGuild(guildId);
		emojiGuildRegistry.rebuildRegistry();
		return true;
	}

	private handleGuildMemberUpdate({guildId, member}: {guildId: string; member: GuildMember}): boolean {
		if (member.user.id !== UserStore.getCurrentUser()?.id) {
			return false;
		}

		const currentGuildEmojis = emojiGuildRegistry.getGuildEmojis(guildId).map((emoji) => ({
			...emoji.toJSON(),
			guild_id: guildId,
		}));

		emojiGuildRegistry.updateGuild(guildId, currentGuildEmojis);
		emojiGuildRegistry.rebuildRegistry();
		return true;
	}
}

export default new EmojiStore();
