import {Endpoints} from '~/Endpoints';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import * as HttpClient from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import {type Message, MessageRecord} from '~/records/MessageRecord';
import MessageStore from '~/stores/MessageStore';

const logger = new Logger('MessageReferenceStore');

export enum MessageReferenceState {
	LOADED = 'LOADED',
	NOT_LOADED = 'NOT_LOADED',
	DELETED = 'DELETED',
}

type State = Readonly<{
	deletedMessageIds: Set<string>;
	cachedMessages: Map<string, MessageRecord>;
}>;

class MessageReferenceStore extends Store<State> {
	constructor() {
		super({
			deletedMessageIds: new Set(),
			cachedMessages: new Map(),
		});
	}

	private getKey(channelId: string, messageId: string): string {
		return `${channelId}:${messageId}`;
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'MESSAGE_DELETE': {
				const {channelId, messageId} = action;
				const key = this.getKey(channelId, messageId);
				this.setState((state) => {
					const newDeletedIds = new Set(state.deletedMessageIds).add(key);
					const newCachedMessages = new Map(state.cachedMessages);
					newCachedMessages.delete(key);
					return {deletedMessageIds: newDeletedIds, cachedMessages: newCachedMessages};
				});
				return true;
			}

			case 'MESSAGE_DELETE_BULK': {
				const {channelId, messageIds} = action;
				this.setState((state) => {
					const newDeletedIds = new Set(state.deletedMessageIds);
					const newCachedMessages = new Map(state.cachedMessages);
					for (const messageId of messageIds) {
						const key = this.getKey(channelId, messageId);
						newDeletedIds.add(key);
						newCachedMessages.delete(key);
					}
					return {deletedMessageIds: newDeletedIds, cachedMessages: newCachedMessages};
				});
				return true;
			}

			case 'MESSAGES_FETCH_SUCCESS': {
				const {channelId, messages} = action;

				// Extract message_reference IDs that are missing from MessageStore and not already deleted or cached
				const potentiallyMissingMessageIds = messages
					.map((message) => message.message_reference?.message_id)
					.filter((id): id is string => !!id)
					.filter(
						(id) =>
							!MessageStore.getMessage(channelId, id) &&
							!this.state.deletedMessageIds.has(this.getKey(channelId, id)) &&
							!this.state.cachedMessages.has(this.getKey(channelId, id)),
					);

				if (potentiallyMissingMessageIds.length > 0) {
					// Fetch each missing message in parallel
					Promise.allSettled(
						potentiallyMissingMessageIds.map((messageId) =>
							HttpClient.get<Message>({
								url: Endpoints.CHANNEL_MESSAGE(channelId, messageId),
							})
								.then((response) => {
									const obj = response.body;
									const messageRecord = new MessageRecord(obj);
									const key = this.getKey(channelId, messageId);

									this.setState((state) => {
										const newCachedMessages = new Map(state.cachedMessages);
										newCachedMessages.set(key, messageRecord);
										return {...state, cachedMessages: newCachedMessages};
									});

									// Cleanup: If MessageStore now has the message, remove from cachedMessages
									if (MessageStore.getMessage(channelId, messageId)) {
										this.setState((state) => {
											const newCachedMessages = new Map(state.cachedMessages);
											newCachedMessages.delete(key);
											const newDeletedIds = new Set(state.deletedMessageIds);
											newDeletedIds.delete(key);
											return {cachedMessages: newCachedMessages, deletedMessageIds: newDeletedIds};
										});
									}
								})
								.catch((error) => {
									const key = this.getKey(channelId, messageId);
									if (error.status === 404) {
										this.setState((state) => {
											const newDeletedIds = new Set(state.deletedMessageIds).add(key);
											const newCachedMessages = new Map(state.cachedMessages);
											newCachedMessages.delete(key);
											return {deletedMessageIds: newDeletedIds, cachedMessages: newCachedMessages};
										});
									} else {
										logger.error(`Failed to fetch message ${messageId}`, error);
									}
								}),
						),
					);
				}

				// Cleanup cachedMessages and deletedMessageIds if MessageStore has the messages
				this.setState((state) => {
					const newCachedMessages = new Map(state.cachedMessages);
					const newDeletedIds = new Set(state.deletedMessageIds);
					for (const message of messages) {
						const messageId = message.message_reference?.message_id;
						if (!messageId) continue;
						const key = this.getKey(channelId, messageId);
						if (MessageStore.getMessage(channelId, messageId)) {
							newCachedMessages.delete(key);
							newDeletedIds.delete(key);
						}
					}
					return {cachedMessages: newCachedMessages, deletedMessageIds: newDeletedIds};
				});

				return true;
			}

			case 'CHANNEL_DELETE': {
				const {channel} = action;
				this.setState((state) => {
					const newDeletedIds = new Set(state.deletedMessageIds);
					const newCachedMessages = new Map(state.cachedMessages);

					for (const key of state.deletedMessageIds) {
						if (key.startsWith(`${channel.id}:`)) {
							newDeletedIds.delete(key);
						}
					}

					for (const key of state.cachedMessages.keys()) {
						if (key.startsWith(`${channel.id}:`)) {
							newCachedMessages.delete(key);
						}
					}

					return {deletedMessageIds: newDeletedIds, cachedMessages: newCachedMessages};
				});
				return true;
			}

			case 'CONNECTION_OPEN': {
				this.setState({deletedMessageIds: new Set(), cachedMessages: new Map()});
				return true;
			}

			default:
				return false;
		}
	}

	getMessage(channelId: string, messageId: string): MessageRecord | null {
		const key = this.getKey(channelId, messageId);
		if (this.state.deletedMessageIds.has(key)) {
			return null;
		}

		const message = MessageStore.getMessage(channelId, messageId);
		if (message) {
			return message;
		}

		return this.state.cachedMessages.get(key) || null;
	}

	getMessageReference(
		channelId: string,
		messageId: string,
	): {
		message: MessageRecord | null;
		state: MessageReferenceState;
	} {
		const key = this.getKey(channelId, messageId);

		// First check if we know it's deleted
		if (this.state.deletedMessageIds.has(key)) {
			return {
				message: null,
				state: MessageReferenceState.DELETED,
			};
		}

		// Then check if it exists in MessageStore
		const message = MessageStore.getMessage(channelId, messageId);
		if (message) {
			return {
				message,
				state: MessageReferenceState.LOADED,
			};
		}

		// Then check if it exists in cachedMessages
		const cachedMessage = this.state.cachedMessages.get(key);
		if (cachedMessage) {
			return {
				message: cachedMessage,
				state: MessageReferenceState.LOADED,
			};
		}

		// Otherwise it's not loaded
		return {
			message: null,
			state: MessageReferenceState.NOT_LOADED,
		};
	}

	useMessageWithStatus(
		channelId: string,
		messageId: string,
	): {
		message: MessageRecord | null;
		state: MessageReferenceState;
	} {
		// Subscribe to both stores to ensure reactivity
		this.useStore();
		MessageStore.useMessages(channelId);

		return this.getMessageReference(channelId, messageId);
	}
}

export default new MessageReferenceStore();
