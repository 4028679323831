import {FlagCheckered, PushPin, Sparkle, X} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import {Permissions} from '~/Constants';
import * as ChannelPinActionCreators from '~/actions/ChannelPinsActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {Message} from '~/components/channel/Message';
import {ConfirmModal} from '~/components/modals/ConfirmModal';
import styles from '~/components/popouts/ChannelPinsPopout.module.css';
import {Scroller} from '~/components/uikit/Scroller';
import {i18n} from '~/i18n';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import type {ChannelRecord} from '~/records/ChannelRecord';
import ChannelPinsStore from '~/stores/ChannelPinsStore';
import ChannelStore from '~/stores/ChannelStore';
import * as PermissionUtils from '~/utils/PermissionUtils';

export const ChannelPinsPopout = ({channel}: {channel: ChannelRecord}) => {
	const {fetched} = ChannelPinsStore.useStore();
	const pinnedMessages = ChannelPinsStore.useChannelPins(channel.id);
	const canManageMessages = PermissionUtils.can(Permissions.MANAGE_MESSAGES, {
		guildId: channel.guildId,
		channelId: channel.id,
	});

	React.useEffect(() => {
		if (!fetched) {
			ChannelPinActionCreators.fetch(channel.id);
		}
	}, [fetched, channel.id]);

	return (
		<div className="relative flex max-h-[957px] min-h-[200px] w-[480px] flex-col overflow-hidden rounded-md border border-background-header-secondary bg-background-primary">
			<div className="relative flex items-center gap-1 border-background-modifier-hover border-b p-4">
				<PushPin className="h-6 w-6 text-text-primary-muted" />
				<h1 className="font-medium text-base text-text-primary leading-[1.25]">{i18n.Messages.PINNED_MESSAGES}</h1>
			</div>

			{pinnedMessages.length > 0 ? (
				<Scroller className="no-scrollbar overflow-x-hidden overflow-y-scroll px-2 pt-2">
					{pinnedMessages.map((message) => (
						<div
							key={message.id}
							className={clsx(
								'relative mb-2 cursor-default select-none overflow-hidden rounded-md border border-background-header-secondary bg-background-chat-primary py-1',
								styles.pinnedMessage,
							)}
						>
							<Message message={message} channel={ChannelStore.getChannel(message.channelId)!} isPreview={true} />

							<div className={styles.actionButtons}>
								<button
									type="button"
									className={styles.jumpButton}
									onClick={() => ComponentDispatch.dispatch('MESSAGE_JUMP', {messageId: message.id})}
								>
									{i18n.Messages.JUMP}
								</button>

								{canManageMessages && (
									<button
										type="button"
										onClick={(event) => {
											if (event.shiftKey) {
												ChannelPinActionCreators.unpin(message.channelId, message.id);
											} else {
												ModalActionCreators.push(() => (
													<ConfirmModal
														title={i18n.Messages.UNPIN_MESSAGE_MODAL_TITLE}
														description={i18n.Messages.UNPIN_MESSAGE_MODAL_DESCRIPTION}
														message={message}
														primaryText={i18n.Messages.UNPIN_MESSAGE_PRIMARY_ACTION}
														onPrimary={() => ChannelPinActionCreators.unpin(message.channelId, message.id)}
													/>
												));
											}
										}}
									>
										<X weight="regular" className={styles.closeIcon} />
									</button>
								)}
							</div>
						</div>
					))}

					<div className="flex h-full min-h-[400px] items-center justify-center">
						<div className="flex flex-col items-center gap-1">
							<FlagCheckered className="h-20 w-20 text-text-primary-muted" />
							<div className="flex flex-col items-center gap-1 text-center">
								<h3 className="font-semibold text-text-primary text-xl">
									{i18n.Messages.PINNED_MESSAGES_THE_END_TITLE}
								</h3>
								<p className="text-sm text-text-primary-muted">{i18n.Messages.PINNED_MESSAGES_THE_END_DESCRIPTION}</p>
							</div>
						</div>
					</div>
				</Scroller>
			) : (
				<div className="flex h-full min-h-[400px] items-center justify-center">
					<div className="flex flex-col items-center gap-1">
						<Sparkle className="h-20 w-20 text-text-primary-muted" />
						<div className="flex flex-col items-center gap-1 text-center">
							<h3 className="font-semibold text-text-primary text-xl">{i18n.Messages.NO_PINNED_MESSAGES_TITLE}</h3>
							<p className="text-sm text-text-primary-muted">{i18n.Messages.NO_PINNED_MESSAGES_DESCRIPTION}</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
