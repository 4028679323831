import {MessageFlags} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import type {Channel} from '~/records/ChannelRecord';
import {type Message, type MessagePartial, MessageRecord} from '~/records/MessageRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import type {ReactionEmoji} from '~/utils/ReactionUtils';

type State = Readonly<{
	channelPins: Readonly<Record<string, ReadonlyArray<MessageRecord>>>;
	fetched: boolean;
}>;

const initialState: State = {
	channelPins: {},
	fetched: false,
};

class ChannelPinsStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CHANNEL_PINS_FETCH_SUCCESS':
				return this.handleChannelPinsFetchSuccess(action);
			case 'CHANNEL_PINS_FETCH_ERROR':
				return this.handleChannelPinsFetchError();
			case 'CHANNEL_DELETE':
				return this.handleChannelDelete(action);
			case 'MESSAGE_UPDATE':
				return this.handleMessageUpdate(action);
			case 'MESSAGE_DELETE':
				return this.handleMessageDelete(action);
			case 'MESSAGE_REACTION_ADD':
				return this.handleMessageReactionAdd(action);
			case 'MESSAGE_REACTION_REMOVE':
				return this.handleMessageReactionRemove(action);
			case 'MESSAGE_REACTION_REMOVE_ALL':
				return this.handleMessageReactionRemoveAll(action);
			case 'MESSAGE_REACTION_REMOVE_EMOJI':
				return this.handleMessageReactionRemoveEmoji(action);
			default:
				return false;
		}
	}

	useChannelPins(channelId: string): ReadonlyArray<MessageRecord> {
		const {channelPins} = this.useStore();
		return channelPins[channelId] ?? [];
	}

	private handleChannelPinsFetchSuccess({
		channelId,
		messages,
	}: {
		channelId: string;
		messages: ReadonlyArray<Message>;
	}): boolean {
		this.setState((state) => ({
			...state,
			channelPins: {
				...state.channelPins,
				[channelId]: Object.freeze(messages.map((message) => new MessageRecord(message))),
			},
			fetched: true,
		}));
		return true;
	}

	private handleChannelPinsFetchError(): boolean {
		this.setState(initialState);
		return true;
	}

	private handleChannelDelete({channel}: {channel: Channel}): boolean {
		this.setState((state) => {
			const {[channel.id]: _, ...remainingChannels} = state.channelPins;
			return {
				...state,
				channelPins: remainingChannels,
			};
		});
		return true;
	}

	private handleMessageUpdate({message}: {message: Message | MessagePartial}): boolean {
		this.setState((state) => {
			const existingMessages = state.channelPins[message.channel_id] ?? [];
			const existingIndex = existingMessages.findIndex((m) => m.id === message.id);

			if (existingIndex === -1 && !('flags' in message && (message.flags & MessageFlags.PINNED) !== 0)) {
				return state;
			}

			let updatedMessages: ReadonlyArray<MessageRecord>;
			if (existingIndex !== -1) {
				if ('flags' in message && (message.flags & MessageFlags.PINNED) === 0) {
					updatedMessages = Object.freeze([
						...existingMessages.slice(0, existingIndex),
						...existingMessages.slice(existingIndex + 1),
					]);
				} else {
					updatedMessages = Object.freeze([
						...existingMessages.slice(0, existingIndex),
						existingMessages[existingIndex].withUpdates(message),
						...existingMessages.slice(existingIndex + 1),
					]);
				}
			} else {
				updatedMessages = Object.freeze([new MessageRecord(message as Message), ...existingMessages]);
			}

			return {
				...state,
				channelPins: {
					...state.channelPins,
					[message.channel_id]: updatedMessages,
				},
			};
		});
		return true;
	}

	private handleMessageDelete({
		channelId,
		messageId,
	}: {
		channelId: string;
		messageId: string;
	}): boolean {
		this.setState((state) => {
			const existingMessages = state.channelPins[channelId] ?? [];
			const existingIndex = existingMessages.findIndex((m) => m.id === messageId);

			if (existingIndex === -1) {
				return state;
			}

			return {
				...state,
				channelPins: {
					...state.channelPins,
					[channelId]: Object.freeze([
						...existingMessages.slice(0, existingIndex),
						...existingMessages.slice(existingIndex + 1),
					]),
				},
			};
		});
		return true;
	}

	private updateMessageInChannel(
		channelId: string,
		messageId: string,
		updater: (message: MessageRecord) => MessageRecord,
	): boolean {
		this.setState((state) => {
			const existingMessages = state.channelPins[channelId] ?? [];
			const existingIndex = existingMessages.findIndex((m) => m.id === messageId);

			if (existingIndex === -1) {
				return state;
			}

			return {
				...state,
				channelPins: {
					...state.channelPins,
					[channelId]: Object.freeze([
						...existingMessages.slice(0, existingIndex),
						updater(existingMessages[existingIndex]),
						...existingMessages.slice(existingIndex + 1),
					]),
				},
			};
		});
		return true;
	}

	private handleMessageReactionAdd({
		channelId,
		messageId,
		userId,
		emoji,
	}: {
		channelId: string;
		messageId: string;
		userId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageInChannel(channelId, messageId, (message) =>
			message.withReaction(emoji, true, userId === AuthenticationStore.getId()),
		);
	}

	private handleMessageReactionRemove({
		channelId,
		messageId,
		userId,
		emoji,
	}: {
		channelId: string;
		messageId: string;
		userId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageInChannel(channelId, messageId, (message) =>
			message.withReaction(emoji, false, userId === AuthenticationStore.getId()),
		);
	}

	private handleMessageReactionRemoveAll({
		channelId,
		messageId,
	}: {
		channelId: string;
		messageId: string;
	}): boolean {
		return this.updateMessageInChannel(channelId, messageId, (message) => message.withUpdates({reactions: []}));
	}

	private handleMessageReactionRemoveEmoji({
		channelId,
		messageId,
		emoji,
	}: {
		channelId: string;
		messageId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageInChannel(channelId, messageId, (message) => message.withoutReactionEmoji(emoji));
	}
}

export default new ChannelPinsStore();
