import {useForm} from 'react-hook-form';
import {type ChannelType, ChannelTypes} from '~/Constants';
import * as ChannelActionCreators from '~/actions/ChannelActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {Form} from '~/components/form/Form';
import {Input, Radio} from '~/components/form/Input';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import * as FormUtils from '~/utils/FormUtils';
import * as RouterUtils from '~/utils/RouterUtils';

type FormInputs = {
	name: string;
	url: string | null;
	type: ChannelType;
};

export const ChannelCreateModal = ({guildId}: {guildId: string}) => {
	const form = useForm<FormInputs>();

	const onSubmit = async (data: FormInputs) => {
		try {
			const channel = await ChannelActionCreators.create(guildId, {
				name: data.name,
				url: data.url,
				type: Number(data.type) as ChannelType,
			});

			if (channel.type !== ChannelTypes.GUILD_LINK) {
				RouterUtils.transitionTo(`/channels/${guildId}/${channel.id}`);
			}

			ModalActionCreators.pop();
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'name');
		}
	};

	return (
		<Form form={form} onSubmit={onSubmit}>
			<Modal.Root label={i18n.Messages.CREATE_CHANNEL} size="small">
				<Modal.Header title={i18n.Messages.CREATE_CHANNEL} />
				<Modal.Content className={styles.content}>
					<Radio
						{...form.register('type')}
						defaultValue={ChannelTypes.GUILD_TEXT}
						label={i18n.Messages.CHANNEL_TYPE}
						name="type"
						options={[
							{label: i18n.Messages.TEXT_CHANNEL, value: ChannelTypes.GUILD_TEXT},
							{label: i18n.Messages.DOCUMENT_CHANNEL, value: ChannelTypes.GUILD_DOCUMENT},
							{label: i18n.Messages.LINK_CHANNEL, value: ChannelTypes.GUILD_LINK},
						]}
						required={true}
					/>
					<Input
						{...form.register('name')}
						autoFocus={true}
						autoComplete="off"
						error={form.formState.errors.name?.message}
						label={i18n.Messages.NAME}
						maxLength={100}
						minLength={1}
						placeholder={i18n.Messages.CHANNEL_NAME_PLACEHOLDER}
						required={true}
					/>
					{Number(form.watch('type')) === ChannelTypes.GUILD_LINK && (
						<Input
							{...form.register('url')}
							error={form.formState.errors.url?.message}
							label={i18n.Messages.URL}
							maxLength={1024}
							placeholder={i18n.Messages.URL_PLACEHOLDER}
							required={true}
							type="url"
						/>
					)}
				</Modal.Content>
				<Modal.Footer>
					<div className={styles.footer}>
						<Button onClick={ModalActionCreators.pop} variant="ghost">
							{i18n.Messages.CANCEL}
						</Button>
						<Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
							{i18n.Messages.CREATE_CHANNEL}
						</Button>
					</div>
				</Modal.Footer>
			</Modal.Root>
		</Form>
	);
};
