import {Link} from '@tanstack/react-router';
import {useForm} from 'react-hook-form';
import * as AuthenticationActionCreators from '~/actions/AuthenticationActionCreators';
import {Card, CardWrapper} from '~/components/form/Card';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import {Button} from '~/components/uikit/Button/Button';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import AuthenticationStore from '~/stores/AuthenticationStore';
import * as FormUtils from '~/utils/FormUtils';
import * as RouterUtils from '~/utils/RouterUtils';

type LoginFormInputs = {
	email: string;
	password: string;
};

export const LoginPageDefault = () => {
	const form = useForm<LoginFormInputs>();

	const onSubmit = async (data: LoginFormInputs) => {
		try {
			const response = await AuthenticationActionCreators.login(data.email, data.password);
			if (response.mfa) {
				Dispatcher.dispatch({type: 'MFA_TICKET_SET', ticket: response.ticket});
			} else {
				Dispatcher.dispatch({type: 'SESSION_START', token: response.token});
				window.location.href = '/';
			}
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'email');
		}
	};

	return (
		<CardWrapper>
			<Card title={i18n.Messages.LOGIN_TITLE} description={i18n.Messages.LOGIN_DESCRIPTION}>
				<Form className="flex w-full flex-col gap-4" form={form} onSubmit={onSubmit}>
					<Input
						{...form.register('email')}
						autoComplete="email"
						autoFocus={true}
						error={form.formState.errors.email?.message}
						label={i18n.Messages.EMAIL}
						maxLength={256}
						minLength={1}
						placeholder={i18n.Messages.EMAIL_PLACEHOLDER}
						required={true}
						type="email"
					/>

					<Input
						{...form.register('password')}
						autoComplete="current-password"
						error={form.formState.errors.password?.message}
						label={i18n.Messages.PASSWORD}
						maxLength={128}
						minLength={8}
						placeholder={'•'.repeat(32)}
						required={true}
						type="password"
						footer={
							<Link className="text-sm text-text-link hover:underline" to=".">
								{i18n.Messages.FORGOT_PASSWORD}
							</Link>
						}
					/>

					<div className="flex flex-col gap-2.5">
						<Button type="submit" submitting={form.formState.isSubmitting}>
							{i18n.Messages.LOGIN}
						</Button>
						<div className="text-sm text-text-tertiary">
							{i18n.Messages.NEED_AN_ACCOUNT}{' '}
							<Link className="text-text-link hover:underline" to="/register">
								{i18n.Messages.REGISTER}
							</Link>
						</div>
					</div>
				</Form>
			</Card>
		</CardWrapper>
	);
};

type MfaFormInputs = {
	code: string;
};

export const LoginPageMFA = () => {
	const form = useForm<MfaFormInputs>();

	const onSubmit = async (data: MfaFormInputs) => {
		try {
			const response = await AuthenticationActionCreators.loginMfaTotp(
				data.code.split(' ').join(''),
				AuthenticationStore.getMfaTicket(),
			);
			Dispatcher.dispatch({type: 'SESSION_START', token: response.token});
			RouterUtils.replaceWith('/');
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'code');
		}
	};

	return (
		<CardWrapper>
			<Card title={i18n.Messages.LOGIN_MFA_TITLE} description={i18n.Messages.LOGIN_MFA_DESCRIPTION}>
				<Form className="flex w-full flex-col gap-4" form={form} onSubmit={onSubmit}>
					<Input
						{...form.register('code')}
						autoComplete="one-time-code"
						autoFocus={true}
						error={form.formState.errors.code?.message}
						label={i18n.Messages.TOTP_CODE}
						required={true}
					/>

					<div className="flex flex-col gap-2.5">
						<Button type="submit" submitting={form.formState.isSubmitting}>
							{i18n.Messages.LOGIN}
						</Button>
						<span
							role="button"
							tabIndex={0}
							onClick={() => Dispatcher.dispatch({type: 'MFA_TICKET_CLEAR'})}
							onKeyDown={(event) => event.key === 'Enter' && Dispatcher.dispatch({type: 'MFA_TICKET_CLEAR'})}
							className="text-sm text-text-link hover:underline"
						>
							{i18n.Messages.BACK_TO_LOGIN}
						</span>
					</div>
				</Form>
			</Card>
		</CardWrapper>
	);
};

export const LoginPage = () => {
	const {loginState} = AuthenticationStore.useStore();
	switch (loginState) {
		case 'default':
			return <LoginPageDefault />;
		case 'mfa':
			return <LoginPageMFA />;
		default:
			return null;
	}
};
