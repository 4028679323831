import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import NotificationStore from '~/stores/NotificationStore';
import * as RouterUtils from '~/utils/RouterUtils';

export const hasNotification = () => typeof Notification !== 'undefined';

export const isGranted = () => hasNotification() && Notification.permission === 'granted';

export const requestPermission = () => {
	if (!hasNotification()) {
		console.warn('Notifications are not supported on this platform.');
		Dispatcher.dispatch({type: 'NOTIFICATION_PERMISSION_DENIED'});
		return;
	}

	Notification.requestPermission().then((permission) => {
		if (permission !== 'granted') {
			Dispatcher.dispatch({type: 'NOTIFICATION_PERMISSION_DENIED'});
		} else {
			Dispatcher.dispatch({type: 'NOTIFICATION_PERMISSION_GRANTED'});
			showNotification({
				title: i18n.Messages.NOTIFICATIONS_GRANTED_TITLE,
				body: i18n.Messages.NOTIFICATIONS_GRANTED_BODY,
				icon: `${import.meta.env.BASE_URL}/apple-touch-icon.png`,
			});
		}
	});
};

export const showNotification = ({
	title,
	body,
	icon,
	url,
}: {
	title: string;
	body: string;
	icon: string;
	url?: string;
}) => {
	if (!(NotificationStore.getBrowserNotificationsEnabled() && hasNotification())) {
		return;
	}
	const notification = new Notification(title, {body, icon});
	notification.addEventListener('click', (event) => {
		event.preventDefault();
		window.focus();
		if (url) {
			RouterUtils.transitionTo(url);
		}
		notification.close();
	});
};
