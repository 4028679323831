import {FluxerLogo} from '~/components/icons/FluxerLogo';

export const Card = ({
	title,
	description,
	children,
}: {title: string; description: string; children: React.ReactNode}) => (
	<div className="flex w-[512px] flex-col items-center gap-4 rounded-md border border-background-header-secondary bg-background-tertiary p-4">
		<FluxerLogo className="h-14" />

		<div className="flex flex-col items-center gap-1.5 text-center">
			<h1 className="font-medium text-2xl text-text-primary">{title}</h1>
			<p className="text-text-tertiary">{description}</p>
		</div>

		{children}
	</div>
);

export const CardWrapper = ({children}: {children: React.ReactNode}) => (
	<div className="flex h-[100svh] items-center justify-center px-4 py-8">{children}</div>
);
