import clsx from 'clsx';
import React from 'react';
import {type StatusType, StatusTypeToDescription, StatusTypeToLabel, StatusTypes} from '~/Constants';
import * as PopoutActionCreators from '~/actions/PopoutActionCreators';
import * as UserSettingsActionCreators from '~/actions/UserSettingsActionCreators';
import styles from '~/components/popouts/UserAreaPopout.module.css';
import UserSettingsStore from '~/stores/UserSettingsStore';

const UserAreaPopoutItem = ({status}: {status: StatusType}) => {
	const settings = UserSettingsStore.useStore();
	const renderableStatus = status === StatusTypes.INVISIBLE ? StatusTypes.OFFLINE : status;

	const handleSelect = React.useCallback(() => {
		if (settings.status !== status) {
			UserSettingsActionCreators.update({status});
		}
		PopoutActionCreators.close();
	}, [settings.status, status]);

	return (
		<div
			className={clsx(
				'flex cursor-pointer gap-2 rounded-md p-2 leading-4 hover:bg-brand-primary hover:text-brand-primary-fill',
				styles.statusPopoutItem,
			)}
			onClick={handleSelect}
			onKeyDown={(event) => event.key === 'Enter' && handleSelect()}
			role="button"
			tabIndex={0}
		>
			<svg width={12} height={12} className="mt-[3px] block h-3 w-3 contain-paint" viewBox="0 0 12 12">
				<foreignObject
					x={0}
					y={0}
					width={12}
					height={12}
					overflow="visible"
					mask={`url(#svg-mask-status-${renderableStatus})`}
				>
					<div className={clsx('h-full w-full', styles.statusIcon, styles[`statusIcon-${renderableStatus}`])} />
				</foreignObject>
			</svg>

			<div className="flex flex-col gap-1 text-sm">
				<span className="font-medium">{StatusTypeToLabel[status]}</span>
				<span className="text-text-tertiary">{StatusTypeToDescription[status]}</span>
			</div>
		</div>
	);
};

export const UserAreaPopout = () => (
	<div className="flex flex-col gap-0.5 rounded-md border border-background-header-secondary bg-background-primary px-[6px] py-[8px]">
		{[StatusTypes.ONLINE, StatusTypes.IDLE, StatusTypes.DND, StatusTypes.INVISIBLE].map((status) => (
			<UserAreaPopoutItem key={status} status={status} />
		))}
	</div>
);
