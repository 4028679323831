import type {Action} from '~/flux/ActionTypes';
import Dispatcher from '~/flux/Dispatcher';
import {Store} from '~/flux/Store';

const IDLE_DURATION = 1000 * (import.meta.env.MODE === 'development' ? 10 : 60 * 10);
const IDLE_CHECK_INTERVAL = Math.floor(IDLE_DURATION * 0.25);

type State = Readonly<{
	idle: boolean;
	idleSince: number;
}>;

const initialState: State = {
	idle: false,
	idleSince: Date.now(),
};

class IdleStore extends Store<State> {
	constructor() {
		super(initialState);
		window.setInterval(() => this.checkIdle(), IDLE_CHECK_INTERVAL);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'IDLE':
				return this.handleIdle(action);
			case 'WINDOW_FOCUS':
				return this.handleWindowFocus();
			default:
				return false;
		}
	}

	isIdle(): boolean {
		return this.state.idle;
	}

	getIdleSince(): number {
		return this.state.idle ? this.state.idleSince : 0;
	}

	private handleIdle({idle, idleSince}: {idle: boolean; idleSince?: number}): boolean {
		this.setState((prevState) => ({
			...prevState,
			idle,
			...(idleSince && {idleSince}),
		}));
		return true;
	}

	private handleWindowFocus(): boolean {
		const now = Date.now();
		this.setState((prevState) => ({
			...prevState,
			idleSince: now,
		}));
		this.checkIdleState(now);
		return true;
	}

	private checkIdle(): void {
		this.checkIdleState(Date.now());
	}

	private checkIdleState(currentTime: number): void {
		const timeSinceLastActivity = currentTime - this.state.idleSince;
		const shouldBeIdle = timeSinceLastActivity > IDLE_DURATION;

		if (shouldBeIdle !== this.state.idle) {
			Dispatcher.dispatch({
				type: 'IDLE',
				idle: shouldBeIdle,
				...(shouldBeIdle && {idleSince: this.state.idleSince}),
			});
		}
	}
}

export default new IdleStore();
