import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';

export const checkForUpdates = async () => {
	const {body} = await HttpClient.get<{rev: string}>({
		url: `${location.protocol}//${location.host}/version.json`,
		query: {
			_: (Date.now() / 1000 / 60 / 5) | 0,
		},
	});
	if (body.rev !== import.meta.env.PUBLIC_REV) {
		Dispatcher.dispatch({type: 'UPDATE_READY'});
	}
};
