import {type RouterHistory, createBrowserHistory} from '@tanstack/react-router';
import {Logger} from '~/lib/Logger';

const logger = new Logger('RouterUtils');

let history: RouterHistory;
if (typeof window !== 'undefined') {
	history = createBrowserHistory();
}

export const transitionTo = (path: string) => {
	logger.info('transitionTo', path);
	history.push(path);
};

export const replaceWith = (path: string) => {
	logger.info('replaceWith', path);
	history.replace(path);
};

export const getHistory = () => history;
