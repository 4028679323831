import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';
import type {Channel} from '~/records/ChannelRecord';

type State = Readonly<{
	drafts: Readonly<Record<string, string>>;
}>;

const initialState: State = {
	drafts: {},
};

class DraftStore extends PersistedStore<State> {
	constructor() {
		super(initialState, 'DraftStore', 1);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'DRAFT_CREATE':
				return this.handleDraftCreate(action);
			case 'DRAFT_DELETE':
				return this.handleDraftDelete(action);
			case 'CHANNEL_DELETE':
				return this.handleChannelDelete(action);
			default:
				return false;
		}
	}

	getDraft(channelId: string): string {
		return this.state.drafts[channelId] ?? '';
	}

	private handleDraftCreate({
		channelId,
		content,
	}: {
		channelId: string;
		content: string;
	}): boolean {
		if (!content || content === this.state.drafts[channelId]) {
			return false;
		}

		this.setState((prevState) => ({
			drafts: {
				...prevState.drafts,
				[channelId]: content,
			},
		}));

		return true;
	}

	private handleDraftDelete({
		channelId,
	}: {
		channelId: string;
	}): boolean {
		if (!this.state.drafts[channelId]) {
			return false;
		}

		this.setState((prevState) => {
			const {[channelId]: _, ...remainingDrafts} = prevState.drafts;
			return {drafts: remainingDrafts};
		});

		return true;
	}

	private handleChannelDelete({
		channel,
	}: {
		channel: Channel;
	}): boolean {
		return this.handleDraftDelete({channelId: channel.id});
	}

	cleanupEmptyDrafts(): void {
		this.setState((prevState) => ({
			drafts: Object.fromEntries(Object.entries(prevState.drafts).filter(([_, content]) => content.trim().length > 0)),
		}));
	}

	getAllDrafts(): ReadonlyArray<[string, string]> {
		return Object.entries(this.state.drafts);
	}

	hasDraft(channelId: string): boolean {
		return channelId in this.state.drafts;
	}

	getDraftCount(): number {
		return Object.keys(this.state.drafts).length;
	}
}

export default new DraftStore();
