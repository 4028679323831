import {useForm} from 'react-hook-form';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import type {GuildRecord} from '~/records/GuildRecord';
import * as FormUtils from '~/utils/FormUtils';

type FormInputs = {
	code: string;
};

export const GuildVanityURLModal = ({guild}: {guild: GuildRecord}) => {
	const form = useForm<FormInputs>({defaultValues: {code: guild.vanityURLCode ?? ''}});

	const onSubmit = async (data: FormInputs) => {
		try {
			await GuildActionCreators.updateVanityURL(guild.id, data.code);
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: i18n.Messages.GUILD_VANITY_URL_UPDATED});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'code');
		}
	};

	return (
		<Form form={form} onSubmit={onSubmit}>
			<Modal.Root label={i18n.Messages.GUILD_VANITY_URL_MODAL_TITLE} size="small">
				<Modal.Header title={i18n.Messages.GUILD_VANITY_URL_MODAL_TITLE} />
				<Modal.Content className={styles.content}>
					{i18n.Messages.GUILD_VANITY_URL_MODAL_DESCRIPTION}
					<Input
						{...form.register('code')}
						autoFocus={true}
						error={form.formState.errors.code?.message}
						label={i18n.Messages.VANITY_URL_CODE}
						minLength={0}
						maxLength={32}
						required={false}
						type="text"
					/>
				</Modal.Content>
				<Modal.Footer>
					<div className={styles.footer}>
						<Button onClick={ModalActionCreators.pop} variant="ghost">
							{i18n.Messages.CANCEL}
						</Button>
						<Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
							{i18n.Messages.SAVE}
						</Button>
					</div>
				</Modal.Footer>
			</Modal.Root>
		</Form>
	);
};
