import {File, Hash, type IconProps, Link} from '@phosphor-icons/react';
import {type ChannelType, ChannelTypes} from '~/Constants';
import {i18n} from '~/i18n';

export const getIcon = (type: ChannelType, props: IconProps = {}) => {
	switch (type) {
		case ChannelTypes.GUILD_DOCUMENT:
			return <File weight="fill" {...props} />;
		case ChannelTypes.GUILD_LINK:
			return <Link weight="regular" {...props} />;
		default:
			return <Hash weight="regular" {...props} />;
	}
};

export const getName = (type: ChannelType) => {
	switch (type) {
		case ChannelTypes.GUILD_TEXT:
			return i18n.Messages.TEXT_CHANNEL;
		case ChannelTypes.GUILD_DOCUMENT:
			return i18n.Messages.DOCUMENT_CHANNEL;
		case ChannelTypes.GUILD_LINK:
			return i18n.Messages.LINK_CHANNEL;
		default:
			return i18n.Messages.TEXT_CHANNEL;
	}
};
