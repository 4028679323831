class CustomStorage implements Storage {
	private store: Record<string, string> = {};

	get length(): number {
		return Object.keys(this.store).length;
	}

	clear(): void {
		this.store = {};
	}

	getItem(key: string): string | null {
		return this.store[key] ?? null;
	}

	key(index: number): string | null {
		const numberIndex = Number(index);
		if (Number.isNaN(numberIndex) || numberIndex < 0) {
			return null;
		}

		const keys = Object.keys(this.store);
		return keys[numberIndex] || null;
	}

	removeItem(key: string): void {
		delete this.store[key];
	}

	setItem(key: string, value: string): void {
		if (key === null || key === undefined) {
			throw new TypeError("Failed to execute 'setItem' on 'Storage': parameter 1 is null or undefined");
		}

		const stringKey = String(key);
		const stringValue = String(value);

		try {
			this.store[stringKey] = stringValue;
		} catch (_) {
			throw new Error("Failed to execute 'setItem' on 'Storage': QuotaExceededError");
		}
	}

	[key: string]: any;

	keys(): Array<string> {
		return Object.keys(this.store);
	}

	toJSON(): Record<string, string> {
		return {...this.store};
	}
}

function getAppStorage(): Storage {
	if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
		try {
			localStorage.setItem('__storage_test__', '');
			localStorage.removeItem('__storage_test__');
			return localStorage;
		} catch (_) {
			return new CustomStorage();
		}
	}
	return new CustomStorage();
}

const AppStorage = getAppStorage();

if (AppStorage instanceof CustomStorage) {
	Object.defineProperty(AppStorage, 'toString', {
		value: () => '[object Storage]',
	});
}

export default AppStorage;

export {CustomStorage};
