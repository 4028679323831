import invariant from 'tiny-invariant';
import {Endpoints} from '~/Endpoints';
import * as AttachmentActionCreators from '~/actions/AttachmentActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {MessageDeleteFailedModal} from '~/components/alerts/MessageDeleteFailedModal';
import {MessageDeleteTooQuickModal} from '~/components/alerts/MessageDeleteTooQuickModal';
import {MessageEditFailedModal} from '~/components/alerts/MessageEditFailedModal';
import {MessageEditTooQuickModal} from '~/components/alerts/MessageEditTooQuickModal';
import {MessageSendFailedModal} from '~/components/alerts/MessageSendFailedModal';
import {MessageSendTooQuickModal} from '~/components/alerts/MessageSendTooQuickModal';
import Dispatcher from '~/flux/Dispatcher';
import type {HttpResponse} from '~/lib/HttpClient';
import * as HttpClient from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {Message} from '~/records/MessageRecord';
import ChannelStateStore from '~/stores/ChannelStateStore';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import type {UploadAttachment} from '~/stores/UploadAttachmentStore';

const logger = new Logger('MessageActionCreators');
const pendingDeletePromises = new Map();

type AllowedMentions = {
	replied_user: boolean;
};

type MessageReference = {
	message_id: string;
};

const DEFAULT_ALLOWED_MENTIONS: AllowedMentions = {
	replied_user: true,
};

const DEFAULT_FLAGS = 0;

type SendMessageParams = {
	content: string;
	nonce: string;
	uploadAttachments?: ReadonlyArray<UploadAttachment>;
	allowedMentions?: AllowedMentions;
	messageReference?: MessageReference;
	flags?: number;
};

type MessageQueueItem = {
	channelId: string;
	params: SendMessageParams;
	resolve: (value: Message | PromiseLike<Message>) => void;
	reject: (reason?: any) => void;
};

const messageQueue: Array<MessageQueueItem> = [];
let inFlightMessages = 0;

const MAX_INFLIGHT_MESSAGES = 3;

const processQueue = async () => {
	if (inFlightMessages >= MAX_INFLIGHT_MESSAGES) {
		ModalActionCreators.push(MessageSendTooQuickModal);
		return;
	}

	if (messageQueue.length === 0) {
		return;
	}

	const {channelId, params, resolve, reject} = messageQueue.shift()!;
	inFlightMessages++;

	try {
		const message = await sendMessage(channelId, params);
		resolve(message);
	} catch (error) {
		reject(error);
	} finally {
		inFlightMessages--;
		processQueue();
	}
};

const sendMessage = async (channelId: string, params: SendMessageParams): Promise<Message> => {
	if (DeveloperOptionsStore.getSlowMessageSend()) {
		await new Promise((resolve) => setTimeout(resolve, 3000));
	}

	AttachmentActionCreators.replace({channelId, attachments: []});

	try {
		const requestBody: Record<string, any> = {
			content: params.content,
			nonce: params.nonce,
		};

		if (params.uploadAttachments?.length) {
			requestBody.attachments = params.uploadAttachments.map((attachment) => {
				invariant(attachment.uploadFilename, 'Attachment uploadFilename is required');
				return {
					id: attachment.id,
					description: attachment.description,
					filename: attachment.filename,
					title: attachment.filename,
					upload_filename: attachment.uploadFilename,
				};
			});
		}

		if (params.allowedMentions && JSON.stringify(params.allowedMentions) !== JSON.stringify(DEFAULT_ALLOWED_MENTIONS)) {
			requestBody.allowed_mentions = params.allowedMentions;
		}

		if (params.messageReference) {
			requestBody.message_reference = params.messageReference;
		}

		if (params.flags && params.flags !== DEFAULT_FLAGS) {
			requestBody.flags = params.flags;
		}

		const {body} = await HttpClient.post<Message>({
			url: Endpoints.CHANNEL_MESSAGES(channelId),
			body: requestBody,
		});

		return body;
	} catch (error) {
		logger.error(`Failed to send message to ${channelId}`, error);

		Dispatcher.dispatch({
			type: 'MESSAGE_SEND_ERROR',
			channelId,
			nonce: params.nonce,
		});

		if (params.uploadAttachments?.length) {
			AttachmentActionCreators.replace({channelId, attachments: Array.from(params.uploadAttachments)});
		}

		if ((error as HttpResponse).status === 429) {
			ModalActionCreators.push(MessageSendTooQuickModal);
		} else {
			ModalActionCreators.push(MessageSendFailedModal);
		}

		throw error;
	}
};

export const send = async (channelId: string, params: SendMessageParams): Promise<Message> =>
	new Promise((resolve, reject) => {
		messageQueue.push({channelId, params, resolve, reject});
		processQueue();
	});

export const edit = async (channelId: string, messageId: string, params: Partial<Message>): Promise<Message> => {
	try {
		const {body} = await HttpClient.patch<Message>({
			url: Endpoints.CHANNEL_MESSAGE(channelId, messageId),
			body: params,
		});
		return body;
	} catch (error) {
		if ((error as HttpResponse).status === 429) {
			ModalActionCreators.push(MessageEditTooQuickModal);
		} else {
			ModalActionCreators.push(MessageEditFailedModal);
		}
		throw error;
	}
};

export const remove = async (channelId: string, messageId: string): Promise<void> => {
	const pendingPromise = pendingDeletePromises.get(messageId);
	if (pendingPromise) {
		return pendingPromise;
	}

	const deletePromise = (async () => {
		try {
			await HttpClient.del({url: Endpoints.CHANNEL_MESSAGE(channelId, messageId)});
		} catch (error) {
			if ((error as HttpResponse).status === 429) {
				ModalActionCreators.push(MessageDeleteTooQuickModal);
			} else if ((error as HttpResponse).status === 404) {
			} else {
				ModalActionCreators.push(MessageDeleteFailedModal);
			}
			throw error;
		} finally {
			pendingDeletePromises.delete(messageId);
		}
	})();

	pendingDeletePromises.set(messageId, deletePromise);
	return deletePromise;
};

export const fetch = async (
	channelId: string,
	params: {limit?: number; around?: string; before?: string; after?: string},
): Promise<Array<Message>> => {
	if (DeveloperOptionsStore.getSlowMessageLoad()) {
		await new Promise((resolve) => setTimeout(resolve, 3000));
	}

	try {
		ChannelStateStore.setLoadingState(channelId, true);

		const timeStart = Date.now();
		const {body: messages} = await HttpClient.get<Array<Message>>({
			url: Endpoints.CHANNEL_MESSAGES(channelId),
			query: params,
		});

		const isBefore = params.before != null;
		const isAfter = params.after != null;
		const isReplacement = params.before == null && params.after == null;
		const hasMoreBefore = params.around != null || (messages.length === params.limit && (isBefore || isReplacement));
		const hasMoreAfter = params.around != null || (isAfter && messages.length === params.limit);

		logger.info(`Fetched ${messages.length} messages for ${channelId}, took ${Date.now() - timeStart}ms`);

		Dispatcher.dispatch({
			type: 'MESSAGES_FETCH_SUCCESS',
			channelId,
			messages,
		});

		ChannelStateStore.updateState(channelId, {
			isLoading: false,
			hasMoreMessages: hasMoreBefore || hasMoreAfter,
			failedToLoadMessages: false,
			hasLoadedFirstPage: true,
		});

		return messages;
	} catch (error) {
		logger.error(`Failed to fetch messages for ${channelId}`, error);

		ChannelStateStore.updateState(channelId, {
			isLoading: false,
			failedToLoadMessages: true,
			hasLoadedFirstPage: true,
		});

		throw error;
	}
};
