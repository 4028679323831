import {CircleWavyWarning, Clipboard, Info, LightbulbFilament, Warning, WarningCircle} from '@phosphor-icons/react';
import clsx from 'clsx';
import highlight from 'highlight.js';
import katex from 'katex';
import {DateTime} from 'luxon';
import React from 'react';
import {GUILD_TEXT_CHANNEL_TYPES} from '~/Constants';
import * as InviteActionCreators from '~/actions/InviteActionCreators';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import {PreloadableUserPopout} from '~/components/channel/PreloadableUserPopout';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {i18n} from '~/i18n';
import {
	type AlertNode,
	AlertType,
	type BlockquoteNode,
	type CodeBlockNode,
	EmojiKind,
	type EmojiNode,
	type FormattingNode,
	GuildNavKind,
	type HeadingNode,
	type InlineCodeNode,
	type LinkNode,
	type ListNode,
	type MathNode,
	MentionKind,
	type MentionNode,
	type Node,
	NodeType,
	Parser,
	ParserFlags,
	type SequenceNode,
	type SubtextNode,
	TableAlignment,
	type TableNode,
	type TextNode,
	type TimestampNode,
	TimestampStyle,
} from '~/lib/Markdown';
import {EMOJI_NAME_RE} from '~/lib/UnicodeEmojis';
import ChannelStore from '~/stores/ChannelStore';
import EmojiStore from '~/stores/EmojiStore';
import GuildStore from '~/stores/GuildStore';
import UserStore from '~/stores/UserStore';
import markupStyles from '~/styles/Markup.module.css';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as ChannelUtils from '~/utils/ChannelUtils';
import * as ColorUtils from '~/utils/ColorUtils';
import * as EmojiUtils from '~/utils/EmojiUtils';
import * as InviteUtils from '~/utils/InviteUtils';
import * as NicknameUtils from '~/utils/NicknameUtils';
import * as RouterUtils from '~/utils/RouterUtils';

type ParseOptions = {
	channelId?: string;
	messageId?: string;
	guildId?: string;
	disableAnimatedEmoji?: boolean;
	type?: 'inline' | 'bio';
	shouldJumbo?: boolean;
};

const getUserInfo = (userId: string, channelId: string) => {
	if (!userId) {
		return {userId: null, name: null};
	}

	const user = UserStore.getUser(userId);
	if (!user) {
		return {userId, name: userId};
	}

	let name = user.displayName;
	const channel = ChannelStore.getChannel(channelId);
	if (channel) {
		name = NicknameUtils.getNickname(user, channel.guildId) || name;
	}

	return {userId: user.id, name};
};

const formatTime = (date: DateTime, style: 'short' | 'long'): string => {
	if (style === 'short') {
		return date.toLocaleString(DateTime.TIME_SIMPLE);
	}
	return date.millisecond ? date.toFormat('h:mm:ss.SSS a') : date.toFormat('h:mm:ss a');
};

const formatDate = (date: DateTime, style: 'short' | 'long'): string => {
	if (style === 'short') {
		return date.toLocaleString(DateTime.DATE_SHORT);
	}
	return date.toLocaleString(DateTime.DATE_FULL);
};

const isToday = (date: DateTime): boolean => {
	const today = DateTime.now();
	return date.hasSame(today, 'day');
};

const isYesterday = (date: DateTime): boolean => {
	const yesterday = DateTime.now().minus({days: 1});
	return date.hasSame(yesterday, 'day');
};

const formatRelativeTime = (timestamp: number): string => {
	const date = DateTime.fromSeconds(timestamp);
	const now = DateTime.now();

	if (isToday(date)) {
		return i18n.format(i18n.Messages.TIME_TODAY, {
			time: formatTime(date, 'short'),
		});
	}

	if (isYesterday(date)) {
		return i18n.format(i18n.Messages.TIME_YESTERDAY, {
			time: formatTime(date, 'short'),
		});
	}

	const diff = date.diff(now).shiftTo('years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds');
	const {years, months, weeks, days, hours, minutes, seconds} = diff.toObject();

	let key: string;
	let value: number;

	if (years && Math.abs(years) > 0) {
		key = date > now ? i18n.Messages.TIME_RELATIVE_YEARS_FROM_NOW : i18n.Messages.TIME_RELATIVE_YEARS_AGO;
		value = Math.abs(years);
	} else if (months && Math.abs(months) > 0) {
		key = date > now ? i18n.Messages.TIME_RELATIVE_MONTHS_FROM_NOW : i18n.Messages.TIME_RELATIVE_MONTHS_AGO;
		value = Math.abs(months);
	} else if (weeks && Math.abs(weeks) > 0) {
		key = date > now ? i18n.Messages.TIME_RELATIVE_WEEKS_FROM_NOW : i18n.Messages.TIME_RELATIVE_WEEKS_AGO;
		value = Math.abs(weeks);
	} else if (days && Math.abs(days) > 0) {
		key = date > now ? i18n.Messages.TIME_RELATIVE_DAYS_FROM_NOW : i18n.Messages.TIME_RELATIVE_DAYS_AGO;
		value = Math.abs(days);
	} else if (hours && Math.abs(hours) > 0) {
		key = date > now ? i18n.Messages.TIME_RELATIVE_HOURS_FROM_NOW : i18n.Messages.TIME_RELATIVE_HOURS_AGO;
		value = Math.abs(hours);
	} else if (minutes && Math.abs(minutes) > 0) {
		key = date > now ? i18n.Messages.TIME_RELATIVE_MINUTES_FROM_NOW : i18n.Messages.TIME_RELATIVE_MINUTES_AGO;
		value = Math.abs(minutes);
	} else {
		key = date > now ? i18n.Messages.TIME_RELATIVE_SECONDS_FROM_NOW : i18n.Messages.TIME_RELATIVE_SECONDS_AGO;
		value = Math.abs(seconds ?? 1);
	}

	return i18n.format(key, {time: Math.round(value)});
};

const formatTimestamp = (timestamp: number, style: TimestampStyle, milliseconds?: number): string => {
	const totalMillis = timestamp * 1000 + (milliseconds || 0);
	const date = DateTime.fromMillis(totalMillis);

	switch (style) {
		case TimestampStyle.ShortTime:
			return formatTime(date, 'short');

		case TimestampStyle.LongTime:
			return formatTime(date, 'long');

		case TimestampStyle.ShortDate:
			return formatDate(date, 'short');

		case TimestampStyle.LongDate:
			return formatDate(date, 'long');

		case TimestampStyle.ShortDateTime:
			return date.toLocaleString(DateTime.DATETIME_SHORT);

		case TimestampStyle.LongDateTime:
			return date.millisecond
				? date.toFormat("cccc, LLLL d, yyyy 'at' h:mm:ss.SSS a")
				: date.toFormat("cccc, LLLL d, yyyy 'at' h:mm:ss a");

		case TimestampStyle.RelativeTime:
			return formatRelativeTime(timestamp);

		default:
			return date.toLocaleString(DateTime.DATETIME_SHORT);
	}
};

const shouldJumboEmojis = (nodes: Array<Node>): boolean => {
	const emojiCount = nodes.filter((node) => {
		return node.type === NodeType.Emoji || (node.type === NodeType.Text && EMOJI_NAME_RE.test(node.content));
	}).length;

	return (
		emojiCount > 0 &&
		emojiCount <= 6 &&
		nodes.every((node) => {
			return (
				node.type === NodeType.Emoji ||
				(node.type === NodeType.Text && (EMOJI_NAME_RE.test(node.content) || /^\s+$/.test(node.content)))
			);
		})
	);
};

type SpoilerNodeWithBlock = FormattingNode & {
	type: NodeType.Spoiler;
	isBlock: boolean;
};

const Spoiler = ({node, renderChildren}: RendererProps): React.ReactElement => {
	const spoilerNode = node as SpoilerNodeWithBlock;
	const [revealed, setRevealed] = React.useState(false);

	const handleClick = React.useCallback(() => {
		if (!revealed) {
			setRevealed(true);
		}
	}, [revealed]);

	const handleKeyDown = React.useCallback(
		(e: React.KeyboardEvent) => {
			if (!revealed && (e.key === 'Enter' || e.key === ' ')) {
				e.preventDefault();
				setRevealed(true);
			}
		},
		[revealed],
	);

	const isBlock = spoilerNode.isBlock;
	const WrapperTag = isBlock ? 'div' : 'span';
	const SpoilerTag = isBlock ? 'div' : 'span';

	return (
		<WrapperTag
			className={clsx(markupStyles.spoilerWrapper, {
				[markupStyles.blockSpoilerWrapper]: isBlock,
			})}
		>
			<SpoilerTag
				className={clsx(markupStyles.spoiler, {
					[markupStyles.blockSpoiler]: isBlock,
				})}
				data-revealed={revealed}
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				role="button"
				tabIndex={revealed ? -1 : 0}
				aria-label={revealed ? 'Revealed spoiler' : 'Click to reveal spoiler'}
				aria-pressed={revealed}
			>
				<span className={markupStyles.spoilerContent}>{renderChildren(spoilerNode.children)}</span>
			</SpoilerTag>
		</WrapperTag>
	);
};

type RendererProps = {
	node: Node;
	key: string;
	renderChildren: (nodes: Array<Node>) => React.ReactNode;
} & Pick<ParseOptions, 'type' | 'channelId' | 'messageId' | 'guildId' | 'disableAnimatedEmoji' | 'shouldJumbo'>;

const renderers: Record<NodeType, (props: RendererProps) => React.ReactElement | null> = {
	[NodeType.Text]: ({node, key, type}: RendererProps): React.ReactElement => {
		const textNode = node as TextNode;
		const content = type === 'inline' ? textNode.content.replace(/\n/g, ' ') : textNode.content;
		return <span key={key}>{content}</span>;
	},

	[NodeType.Strong]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		const strongNode = node as FormattingNode;
		return <strong key={key}>{renderChildren(strongNode.children)}</strong>;
	},

	[NodeType.Emphasis]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		const emphasisNode = node as FormattingNode;
		return <em key={key}>{renderChildren(emphasisNode.children)}</em>;
	},

	[NodeType.Underline]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		const underlineNode = node as FormattingNode;
		return <u key={key}>{renderChildren(underlineNode.children)}</u>;
	},

	[NodeType.Strikethrough]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		const strikethroughNode = node as FormattingNode;
		return <s key={key}>{renderChildren(strikethroughNode.children)}</s>;
	},

	[NodeType.Spoiler]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		return <Spoiler key={key} node={node} renderChildren={renderChildren} />;
	},

	[NodeType.Timestamp]: ({node, key}: RendererProps): React.ReactElement => {
		const timestampNode = node as TimestampNode;
		const totalMillis = timestampNode.timestamp * 1000 + (timestampNode.milliseconds || 0);
		const date = DateTime.fromMillis(totalMillis);

		const displayTime = formatTimestamp(timestampNode.timestamp, timestampNode.style, timestampNode.milliseconds);

		const tooltipFormat = timestampNode.milliseconds
			? "cccc, LLLL d, yyyy 'at' h:mm:ss.SSS a ZZZZ"
			: "cccc, LLLL d, yyyy 'at' h:mm:ss a ZZZZ";

		const fullDateTime = date.toFormat(tooltipFormat);

		return (
			<Tooltip key={key} text={fullDateTime} position="top" delay={200} maxWidth="xl">
				<time className={markupStyles.timestamp} dateTime={date.toISO() ?? ''}>
					{displayTime}
				</time>
			</Tooltip>
		);
	},

	[NodeType.Blockquote]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		const blockquoteNode = node as BlockquoteNode;
		return (
			<div key={key} className={markupStyles.blockquoteContainer}>
				<div className={markupStyles.blockquoteDivider} />
				<blockquote className={markupStyles.blockquoteContent}>{renderChildren(blockquoteNode.children)}</blockquote>
			</div>
		);
	},

	[NodeType.CodeBlock]: ({node, key}: RendererProps): React.ReactElement => {
		const codeBlockNode = node as CodeBlockNode;
		let codeContent: React.ReactNode;
		if (codeBlockNode.language && highlight.getLanguage(codeBlockNode.language)) {
			const highlighted = highlight.highlight(codeBlockNode.content, {
				language: codeBlockNode.language,
				ignoreIllegals: true,
			});
			codeContent = (
				<code
					className={clsx(markupStyles.hljs, codeBlockNode.language)}
					// biome-ignore lint/security/noDangerouslySetInnerHtml: Highlight.js is a trusted source
					dangerouslySetInnerHTML={{__html: highlighted.value}}
				/>
			);
		} else {
			codeContent = <code className={markupStyles.hljs}>{codeBlockNode.content}</code>;
		}

		return (
			<pre key={key}>
				<div className={markupStyles.codeContainer}>
					<div className={markupStyles.codeActions}>
						<button type="button" onClick={() => TextCopyActionCreators.copy(codeBlockNode.content)}>
							<Clipboard className="h-4 w-4" />
						</button>
					</div>
					{codeContent}
				</div>
			</pre>
		);
	},

	[NodeType.InlineCode]: ({node, key}: RendererProps): React.ReactElement => {
		const inlineCodeNode = node as InlineCodeNode;
		return (
			<code key={key} className={markupStyles.inline}>
				{inlineCodeNode.content.replace(/\s+/g, ' ')}
			</code>
		);
	},

	[NodeType.Link]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		const linkNode = node as LinkNode;
		const code = InviteUtils.findInvite(linkNode.url);
		let onClick: ((e: React.MouseEvent) => void) | undefined;

		if (code) {
			onClick = (e) => {
				e.preventDefault();
				InviteActionCreators.acceptAndTransitionToChannel(code);
			};
		} else {
			try {
				const parsed = new URL(linkNode.url);
				if (parsed.host === location.host) {
					onClick = (e) => {
						e.preventDefault();
						RouterUtils.transitionTo(parsed.pathname);
					};
				}
			} catch {}
		}

		const content = linkNode.text ? renderChildren([linkNode.text]) : linkNode.url;
		return (
			<a
				key={key}
				href={linkNode.url}
				target="_blank"
				rel="noopener noreferrer"
				onClick={onClick}
				className={markupStyles.link}
			>
				{content}
			</a>
		);
	},

	[NodeType.Mention]: ({node, key, channelId}: RendererProps): React.ReactElement => {
		const mentionNode = node as MentionNode;

		switch (mentionNode.kind.kind) {
			case MentionKind.User: {
				const {userId, name} = getUserInfo(mentionNode.kind.id, channelId || '');
				if (!userId) {
					return (
						<span key={key} className={markupStyles.mention}>
							@{name || mentionNode.kind.id}
						</span>
					);
				}

				const user = UserStore.getUser(userId);
				if (!user) {
					return (
						<span key={key} className={markupStyles.mention}>
							@{name || mentionNode.kind.id}
						</span>
					);
				}

				const channel = ChannelStore.getChannel(channelId || '');
				const guildId = channel?.guildId || '';

				return (
					<span key={key} className="inline-flex items-center">
						<PreloadableUserPopout user={user} isWebhook={false} guildId={guildId} position="right-start">
							<span className={clsx(markupStyles.mention, markupStyles.interactive)}>
								<span className="-mt-[.2em] inline-flex items-center align-middle">@{name || user.displayName}</span>
							</span>
						</PreloadableUserPopout>
					</span>
				);
			}

			case MentionKind.Role: {
				const guild = GuildStore.getGuild(mentionNode.kind.id);
				const role = guild?.roles[mentionNode.kind.id];
				if (!role) {
					return (
						<span key={key} className={markupStyles.mention}>
							{i18n.Messages.UNKNOWN_ROLE_PLACEHOLDER}
						</span>
					);
				}

				return (
					<span
						key={key}
						className={markupStyles.mention}
						style={{
							color: ColorUtils.int2rgb(role.color),
							backgroundColor: ColorUtils.int2rgba(role.color, 0.1),
						}}
					>
						@{role.name}
					</span>
				);
			}

			case MentionKind.Channel: {
				const channel = ChannelStore.getChannel(mentionNode.kind.id);
				if (!channel || !GUILD_TEXT_CHANNEL_TYPES.has(channel.type as any)) {
					return (
						<span key={key} className={markupStyles.mention}>
							{i18n.Messages.UNKNOWN_CHANNEL_PLACEHOLDER}
						</span>
					);
				}

				return (
					<span
						key={key}
						className={markupStyles.mention}
						onClick={() => RouterUtils.transitionTo(`/channels/${channel.guildId}/${channel.id}`)}
						onKeyDown={(e) => {
							if (e.key === 'Enter' || e.key === ' ') {
								RouterUtils.transitionTo(`/channels/${channel.guildId}/${channel.id}`);
							}
						}}
						role="button"
						tabIndex={0}
					>
						<div className="-mt-[.2em] inline-flex items-center align-middle">
							{ChannelUtils.getIcon(channel.type, {className: 'mr-px h-4 w-4'})}
							{channel.name}
						</div>
					</span>
				);
			}

			case MentionKind.Everyone: {
				return (
					<span key={key} className={markupStyles.mention}>
						@everyone
					</span>
				);
			}

			case MentionKind.Here: {
				return (
					<span key={key} className={markupStyles.mention}>
						@here
					</span>
				);
			}

			case MentionKind.Command: {
				const {name, subcommandGroup, subcommand, id} = mentionNode.kind;
				return (
					<span key={key} className={markupStyles.mention}>
						{`</${name}${subcommandGroup ? ` ${subcommandGroup}` : ''}${subcommand ? ` ${subcommand}` : ''}:${id}>`}
					</span>
				);
			}

			case MentionKind.GuildNavigation: {
				const {navigationType} = mentionNode.kind;
				switch (navigationType) {
					case GuildNavKind.Customize:
						return (
							<span key={key} className={markupStyles.mention}>
								<span className={markupStyles.mention}>{'<id:customize>'}</span>
							</span>
						);
					case GuildNavKind.Browse:
					case GuildNavKind.Guide:
						return (
							<span key={key} className={markupStyles.mention}>
								{`<id:${navigationType}>`}
							</span>
						);
					case GuildNavKind.LinkedRoles: {
						// GuildNavKind.LinkedRoles may have an optional 'id'
						const linkedRolesId = (mentionNode.kind as {navigationType: GuildNavKind.LinkedRoles; id?: string}).id;
						if (linkedRolesId) {
							return (
								<span key={key} className={markupStyles.mention}>
									{`<id:linked-roles:${linkedRolesId}>`}
								</span>
							);
						}
						return (
							<span key={key} className={markupStyles.mention}>
								{'<id:linked-roles>'}
							</span>
						);
					}
					default:
						return (
							<span key={key} className={markupStyles.mention}>
								{`<id:${navigationType}>`}
							</span>
						);
				}
			}

			default:
				return (
					<span key={key} className={markupStyles.mention}>
						{'<unknown-mention>'}
					</span>
				);
		}
	},

	[NodeType.Emoji]: ({
		node,
		key,
		messageId,
		guildId,
		disableAnimatedEmoji,
		shouldJumbo,
	}: RendererProps): React.ReactElement => {
		const emojiNode = node as EmojiNode;
		const {kind} = emojiNode;

		const className = clsx(markupStyles.emoji, shouldJumbo && markupStyles.jumboable);
		const tooltipDelay = 750;
		const emojiName = `:${kind.name}:`;

		if (kind.kind === EmojiKind.Standard) {
			const emojiUrl = EmojiUtils.getTwemojiURL(kind.codepoints);
			return (
				<Tooltip key={key} text={emojiName} delay={tooltipDelay}>
					<img draggable={false} className={className} alt={emojiName} src={emojiUrl} />
				</Tooltip>
			);
		}

		const {id, animated} = kind;
		const shouldAnimate = !disableAnimatedEmoji && animated;

		const channel = ChannelStore.getChannel(guildId || '');
		const disambiguatedEmoji = EmojiStore.getDisambiguatedEmojiContext(channel?.guildId).getById(id);
		const finalEmojiName = `:${disambiguatedEmoji?.name || kind.name}:`;
		const baseUrl = AvatarUtils.getEmojiURL({id, animated: shouldAnimate});
		const size = shouldJumbo ? 240 : 96;

		return (
			<Tooltip key={key} text={finalEmojiName} delay={tooltipDelay} position="top">
				<img
					draggable={false}
					className={className}
					alt={finalEmojiName}
					src={`${baseUrl}?size=${size}&quality=lossless`}
					data-message-id={messageId}
					data-emoji-id={id}
					data-animated={shouldAnimate}
				/>
			</Tooltip>
		);
	},

	[NodeType.List]: ({node, key, renderChildren, type}: RendererProps): React.ReactElement => {
		const listNode = node as ListNode;
		const Tag = listNode.ordered ? 'ol' : 'ul';
		const isInlineReply = type === 'inline';

		return (
			<Tag key={key} className={clsx(isInlineReply && markupStyles.inlineFormat)}>
				{listNode.items.map((item, i) => (
					// biome-ignore lint/suspicious/noArrayIndexKey: Using index as key is acceptable here since list items are static.
					<li key={`${key}-item-${i}`} className={clsx(isInlineReply && markupStyles.inlineFormat)}>
						{renderChildren(item.children)}
					</li>
				))}
			</Tag>
		);
	},

	[NodeType.Heading]: ({node, key, renderChildren, type}: RendererProps): React.ReactElement => {
		const headingNode = node as HeadingNode;
		const Tag = `h${headingNode.level}` as keyof React.JSX.IntrinsicElements;
		const isInlineReply = type === 'inline';

		return (
			<Tag key={key} className={clsx(isInlineReply && markupStyles.inlineFormat)}>
				{renderChildren(headingNode.children)}
			</Tag>
		);
	},

	[NodeType.Subtext]: ({node, key, renderChildren, type}: RendererProps): React.ReactElement => {
		const subtextNode = node as SubtextNode;
		const isInlineReply = type === 'inline';

		return (
			<small key={key} className={clsx(isInlineReply && markupStyles.inlineFormat)}>
				{renderChildren(subtextNode.children)}
			</small>
		);
	},

	[NodeType.TripleAsterisk]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		return (
			<strong key={key}>
				<em>{renderChildren((node as FormattingNode).children)}</em>
			</strong>
		);
	},

	[NodeType.TripleUnderscore]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		return (
			<strong key={key}>
				<em>{renderChildren((node as FormattingNode).children)}</em>
			</strong>
		);
	},

	[NodeType.Sequence]: ({node, key, renderChildren}: RendererProps): React.ReactElement | null => {
		const sequenceNode = node as SequenceNode;
		return <React.Fragment key={key}>{renderChildren(sequenceNode.children)}</React.Fragment>;
	},

	[NodeType.Table]: ({node, key, renderChildren}: RendererProps): React.ReactElement | null => {
		const tableNode = node as TableNode;
		return (
			<div key={key} className={markupStyles.tableContainer}>
				<table className={markupStyles.table}>
					<thead>
						<tr>
							{tableNode.header.cells.map((cell, i) => (
								<th
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={`header-${i}`}
									className={clsx(markupStyles.tableCell, markupStyles.tableHeader, {
										[markupStyles.alignLeft]: tableNode.alignments[i] === TableAlignment.Left,
										[markupStyles.alignCenter]: tableNode.alignments[i] === TableAlignment.Center,
										[markupStyles.alignRight]: tableNode.alignments[i] === TableAlignment.Right,
									})}
								>
									{renderChildren(cell.children)}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{tableNode.rows.map((row, rowIndex) => (
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							<tr key={`row-${rowIndex}`}>
								{row.cells.map((cell, cellIndex) => (
									<td
										// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
										key={`cell-${rowIndex}-${cellIndex}`}
										className={clsx(markupStyles.tableCell, {
											[markupStyles.alignLeft]: tableNode.alignments[cellIndex] === TableAlignment.Left,
											[markupStyles.alignCenter]: tableNode.alignments[cellIndex] === TableAlignment.Center,
											[markupStyles.alignRight]: tableNode.alignments[cellIndex] === TableAlignment.Right,
										})}
									>
										{renderChildren(cell.children)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	},

	[NodeType.TableRow]: (): null => null, // Handled by Table renderer
	[NodeType.TableCell]: (): null => null, // Handled by Table renderer

	[NodeType.Alert]: ({node, key, renderChildren}: RendererProps): React.ReactElement => {
		const alert = node as AlertNode;
		const alertConfigs: Record<AlertType, {Icon: React.ComponentType<{className?: string}>; className: string}> = {
			[AlertType.Note]: {Icon: Info, className: markupStyles.alertNote},
			[AlertType.Tip]: {Icon: LightbulbFilament, className: markupStyles.alertTip},
			[AlertType.Important]: {Icon: Warning, className: markupStyles.alertImportant},
			[AlertType.Warning]: {Icon: CircleWavyWarning, className: markupStyles.alertWarning},
			[AlertType.Caution]: {Icon: WarningCircle, className: markupStyles.alertCaution},
		};
		const {Icon, className} = alertConfigs[alert.alertType] || alertConfigs[AlertType.Note];
		return (
			<div key={key} className={clsx(markupStyles.alert, className)}>
				<Icon className={markupStyles.alertIcon} />
				<div className={markupStyles.alertContent}>{renderChildren(alert.children)}</div>
			</div>
		);
	},

	[NodeType.Math]: ({node, key}: RendererProps): React.ReactElement => {
		const mathNode = node as MathNode;
		try {
			const html = katex.renderToString(mathNode.content, {
				displayMode: mathNode.isBlock,
				throwOnError: false,
				errorColor: 'var(--text-danger)',
				trust: false,
				strict: false,
				output: 'html',
			});
			return (
				<span
					key={key}
					className={clsx(markupStyles.math, mathNode.isBlock && markupStyles.mathBlock)}
					// biome-ignore lint/security/noDangerouslySetInnerHtml: KaTeX output is safe
					dangerouslySetInnerHTML={{__html: html}}
				/>
			);
		} catch (error) {
			console.error('KaTeX rendering error:', error);
			return (
				<span
					key={key}
					className={markupStyles.mathError}
					title={error instanceof Error ? error.message : 'Math rendering error'}
				>
					{mathNode.content}
				</span>
			);
		}
	},
};

const renderNode = (node: Node, key: string, context: ParseOptions = {}): React.ReactNode => {
	const renderer = renderers[node.type];
	if (!renderer) return null;

	const renderChildren = (children: Array<Node>) =>
		children.map((child, i) => renderNode(child, `${key}-${i}`, context));

	return renderer({
		node,
		key,
		renderChildren,
		...context,
	});
};

const combineFlags = (...flags: Array<ParserFlags>) => flags.reduce((acc, flag) => acc | flag, 0);

export const DEFAULT_FLAGS = combineFlags(
	ParserFlags.ALLOW_SPOILERS,
	ParserFlags.ALLOW_HEADINGS,
	ParserFlags.ALLOW_LISTS,
	ParserFlags.ALLOW_CODE_BLOCKS,
	ParserFlags.ALLOW_MASKED_LINKS,
	ParserFlags.ALLOW_COMMAND_MENTIONS,
	ParserFlags.ALLOW_GUILD_NAVIGATIONS,
	ParserFlags.ALLOW_USER_MENTIONS,
	ParserFlags.ALLOW_ROLE_MENTIONS,
	ParserFlags.ALLOW_CHANNEL_MENTIONS,
	ParserFlags.ALLOW_EVERYONE_MENTIONS,
	ParserFlags.ALLOW_BLOCKQUOTES,
	ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES,
	ParserFlags.ALLOW_SUBTEXT,
	ParserFlags.ALLOW_TABLES,
	ParserFlags.ALLOW_ALERTS,
);

export const INLINE_FORMAT_FLAGS =
	DEFAULT_FLAGS &
	~combineFlags(
		ParserFlags.ALLOW_BLOCKQUOTES,
		ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES,
		ParserFlags.ALLOW_SUBTEXT,
		ParserFlags.ALLOW_TABLES,
	);

export const BIO_FLAGS =
	DEFAULT_FLAGS &
	~combineFlags(
		ParserFlags.ALLOW_HEADINGS,
		ParserFlags.ALLOW_CODE_BLOCKS,
		ParserFlags.ALLOW_ROLE_MENTIONS,
		ParserFlags.ALLOW_EVERYONE_MENTIONS,
		ParserFlags.ALLOW_SUBTEXT,
		ParserFlags.ALLOW_TABLES,
	);

type ParseConfig = {
	flags: number;
	wrapper: (content: React.ReactNode) => React.ReactNode;
};

const PARSE_CONFIGS: Record<string, ParseConfig> = {
	default: {
		flags: DEFAULT_FLAGS,
		wrapper: (content: React.ReactNode) => content,
	},
	inline: {
		flags: INLINE_FORMAT_FLAGS,
		wrapper: (content: React.ReactNode) => <div className={markupStyles.inlineFormat}>{content}</div>,
	},
	bio: {
		flags: BIO_FLAGS,
		wrapper: (content: React.ReactNode) => content,
	},
};

const parseContent = (content: string, options?: ParseOptions): React.ReactNode => {
	try {
		const parseType = options?.type || 'default';
		const config = PARSE_CONFIGS[parseType];
		const parser = new Parser(content, config.flags);
		const nodes = parser.parse();
		const shouldJumbo = parseType === 'default' ? shouldJumboEmojis(nodes) : false;
		const context = {...options, shouldJumbo};
		const parsedContent = nodes.map((node, i) => renderNode(node, `${parseType}-${i}`, context));
		return config.wrapper(parsedContent);
	} catch (error) {
		console.error(`Error parsing ${options?.type || 'content'}:`, error);
		return content;
	}
};

class MarkdownErrorBoundary extends React.Component<
	{children: React.ReactNode},
	{hasError: boolean; error: Error | null}
> {
	constructor(props: {children: React.ReactNode}) {
		super(props);
		this.state = {hasError: false, error: null};
	}

	static getDerivedStateFromError(error: Error) {
		return {hasError: true, error};
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		console.error('Error rendering markdown:', error, info);
	}

	render() {
		if (this.state.hasError) {
			return <span className={markupStyles.error}>Error rendering content</span>;
		}

		return this.props.children;
	}
}

export const safeParse = (content: string, options?: ParseOptions): React.ReactElement => {
	const parsed = parseContent(content, options);
	return <MarkdownErrorBoundary>{typeof parsed === 'string' ? <span>{parsed}</span> : parsed}</MarkdownErrorBoundary>;
};

declare module 'react' {
	interface CSSProperties {
		'--totalCharacters'?: number;
	}
}
