import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';

type State = Readonly<{
	isMembersOpen: boolean;
}>;

const getInitialState = (): State => ({
	isMembersOpen: window.innerWidth >= 1024,
});

const STORE_KEY = 'MemberListStore' as const;
const STORE_VERSION = 1 as const;

class MemberListStore extends PersistedStore<State> {
	constructor() {
		super(getInitialState(), STORE_KEY, STORE_VERSION);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'MEMBERS_TOGGLE':
				return this.handleMembersToggle();
			default:
				return false;
		}
	}

	isMembersOpen(): boolean {
		return this.state.isMembersOpen;
	}

	private handleMembersToggle(): boolean {
		this.setState((prevState) => ({
			isMembersOpen: !prevState.isMembersOpen,
		}));
		return true;
	}
}

export default new MemberListStore();
