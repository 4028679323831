import {Check} from '@phosphor-icons/react';
import React from 'react';
import {useForm} from 'react-hook-form';
import {DEFAULT_ROLE_COLOR, GuildRoleFlags, ROLE_COLORS} from '~/Constants';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import {Switch} from '~/components/form/Switch';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {PermissionSelector} from '~/components/uikit/PermissionSelector';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import {i18n} from '~/i18n';
import type {GuildRoleRecord} from '~/records/GuildRoleRecord';

type FormInputs = {
	name: string;
	color: number;
	permissions: bigint;
	flags: number;
};

export const GuildRoleEditModal = ({role}: {role: GuildRoleRecord}) => {
	const form = useForm<FormInputs>({
		defaultValues: {
			name: role.name,
			color: role.color,
			permissions: role.permissions,
			flags: role.flags,
		},
	});

	const [customColor, setCustomColor] = React.useState<string>(`#${role.color.toString(16).padStart(6, '0')}`);

	const handleColorChange = (color: string) => {
		setCustomColor(color);
		form.setValue('color', Number.parseInt(color.slice(1), 16));
	};

	const onSubmit = async (data: FormInputs) => {
		GuildActionCreators.updateRole(role.guildId, role.id, {
			name: data.name,
			color: data.color,
			permissions: data.permissions.toString(),
			flags: data.flags,
		});
		ModalActionCreators.pop();
		ToastActionCreators.createToast({type: 'success', children: i18n.Messages.ROLE_UPDATED});
	};

	const title = i18n.format(i18n.Messages.EDIT_ROLE_MODAL_TITLE, {roleName: role.name});
	return (
		<Form form={form} onSubmit={onSubmit}>
			<Modal.Root
				label={title}
				size="small"
				className="flex h-[880px] w-[500px] flex-none flex-col overflow-hidden rounded-md border border-background-header-secondary bg-background-secondary"
			>
				<Modal.Header title={title} />
				<ScrollArea className="flex flex-col px-4">
					{!role.isEveryone && (
						<div>
							<Input
								{...form.register('name')}
								autoFocus={true}
								label={i18n.Messages.NAME}
								minLength={1}
								maxLength={100}
								placeholder=""
								required={true}
								type="text"
							/>

							<div className="mt-6 mb-4 h-px bg-background-header-secondary" />
						</div>
					)}

					{!role.isEveryone && (
						<div>
							<h3 className="font-semibold text-lg">{i18n.Messages.COLOR}</h3>
							<div className="mt-2 flex flex-wrap gap-2">
								{ROLE_COLORS.map((color) => (
									<button
										type="button"
										key={color}
										className="flex h-7 w-7 cursor-pointer items-center justify-center rounded"
										style={{backgroundColor: `#${color.toString(16).padStart(6, '0')}`}}
										onClick={() => handleColorChange(`#${color.toString(16).padStart(6, '0')}`)}
									>
										{form.watch('color') === color && <Check weight="bold" className="h-3 w-3 text-white" />}
									</button>
								))}
								<button
									type="button"
									className="flex h-7 w-7 cursor-pointer items-center justify-center rounded"
									style={{backgroundColor: `#${DEFAULT_ROLE_COLOR.toString(16).padStart(6, '0')}`}}
									onClick={() => handleColorChange(`#${DEFAULT_ROLE_COLOR.toString(16).padStart(6, '0')}`)}
								>
									{form.watch('color') === DEFAULT_ROLE_COLOR && <Check weight="bold" className="h-3 w-3 text-white" />}
								</button>
							</div>
							<div className="mt-4">
								<Input
									label={i18n.Messages.CUSTOM_COLOR}
									value={customColor}
									onChange={(e) => handleColorChange(e.target.value)}
									type="text"
									maxLength={7}
									placeholder="#000000"
								/>
							</div>

							<div className="mt-6 mb-4 h-px bg-background-header-secondary" />
						</div>
					)}

					{!role.isEveryone && (
						<div>
							<h3 className="font-semibold text-lg">{i18n.Messages.FLAGS}</h3>
							<div className="mt-2 flex flex-col gap-3">
								<Switch
									label={i18n.Messages.HOISTED}
									description=""
									value={(form.watch('flags') & GuildRoleFlags.HOISTED) !== 0}
									onChange={() => {
										form.setValue('flags', form.watch('flags') ^ GuildRoleFlags.HOISTED);
									}}
								/>
								<Switch
									label={i18n.Messages.MENTIONABLE}
									description=""
									value={(form.watch('flags') & GuildRoleFlags.MENTIONABLE) !== 0}
									onChange={() => {
										form.setValue('flags', form.watch('flags') ^ GuildRoleFlags.MENTIONABLE);
									}}
								/>
							</div>

							<div className="mt-6 mb-4 h-px bg-background-header-secondary" />
						</div>
					)}

					<div>
						<h3 className="mb-2 font-medium text-lg">{i18n.Messages.PERMISSIONS}</h3>
						<PermissionSelector
							initialPermissions={form.watch('permissions')}
							onChange={(permissions) => form.setValue('permissions', permissions)}
						/>

						<div className="mb-6" />
					</div>
				</ScrollArea>

				<Modal.Footer>
					<div className={styles.footer}>
						<Button onClick={ModalActionCreators.pop} variant="ghost">
							{i18n.Messages.CANCEL}
						</Button>
						<Button type="submit" variant="brand">
							{i18n.Messages.SAVE}
						</Button>
					</div>
				</Modal.Footer>
			</Modal.Root>
		</Form>
	);
};
