import * as TypingActionCreators from '~/actions/TypingActionCreators';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import type {Message} from '~/records/MessageRecord';

type TypingUser = Readonly<{
	timeout: NodeJS.Timeout;
	expiresAt: number;
}>;

type TypingUsers = Readonly<Record<string, TypingUser>>;
type TypingChannels = Readonly<Record<string, TypingUsers>>;

type State = Readonly<{
	typingUsersByChannel: TypingChannels;
}>;

const TYPING_TIMEOUT = 10_000;

const initialState: State = {
	typingUsersByChannel: {},
};

class TypingStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen();
			case 'TYPING_START':
				return this.handleTypingStart(action);
			case 'TYPING_STOP':
				return this.handleTypingStop(action);
			case 'MESSAGE_CREATE':
				return this.handleMessageCreate(action);
			default:
				return false;
		}
	}

	getTypingUsers(channelId: string): ReadonlyArray<string> {
		return Object.keys(this.state.typingUsersByChannel[channelId] ?? {});
	}

	isTyping(channelId: string, userId: string): boolean {
		return this.state.typingUsersByChannel[channelId]?.[userId] !== undefined;
	}

	getCount(channelId: string): number {
		return Object.keys(this.state.typingUsersByChannel[channelId] ?? {}).length;
	}

	useTypingUsers(channelId: string): ReadonlyArray<string> {
		const {typingUsersByChannel} = this.useStore();
		return Object.keys(typingUsersByChannel[channelId] ?? {});
	}

	useIsTyping(channelId: string, userId: string): boolean {
		const {typingUsersByChannel} = this.useStore();
		return typingUsersByChannel[channelId]?.[userId] !== undefined;
	}

	private handleConnectionOpen(): boolean {
		this.clearAllTimeouts();
		this.setState(initialState);
		return true;
	}

	private handleTypingStart({channelId, userId}: {channelId: string; userId: string}): boolean {
		this.setState((prevState) => {
			const existingTimeout = prevState.typingUsersByChannel[channelId]?.[userId]?.timeout;
			if (existingTimeout) {
				clearTimeout(existingTimeout);
			}

			const newTimeout = this.scheduleClear(channelId, userId);

			return {
				typingUsersByChannel: {
					...prevState.typingUsersByChannel,
					[channelId]: {
						...(prevState.typingUsersByChannel[channelId] ?? {}),
						[userId]: {
							timeout: newTimeout,
							expiresAt: Date.now() + TYPING_TIMEOUT,
						},
					},
				},
			};
		});
		return true;
	}

	private handleTypingStop({channelId, userId}: {channelId: string; userId: string}): boolean {
		this.setState((prevState) => {
			const channelUsers = prevState.typingUsersByChannel[channelId];
			if (!channelUsers?.[userId]) {
				return prevState;
			}

			clearTimeout(channelUsers[userId].timeout);

			const {[userId]: _, ...remainingUsers} = channelUsers;

			if (Object.keys(remainingUsers).length === 0) {
				const {[channelId]: __, ...remainingChannels} = prevState.typingUsersByChannel;
				return {
					typingUsersByChannel: remainingChannels,
				};
			}

			return {
				typingUsersByChannel: {
					...prevState.typingUsersByChannel,
					[channelId]: remainingUsers,
				},
			};
		});
		return true;
	}

	private handleMessageCreate({message}: {message: Message}): boolean {
		return this.handleTypingStop({
			channelId: message.channel_id,
			userId: message.author.id,
		});
	}

	private scheduleClear(channelId: string, userId: string): NodeJS.Timeout {
		return setTimeout(() => TypingActionCreators.stopTyping(channelId, userId), TYPING_TIMEOUT);
	}

	private clearAllTimeouts(): void {
		for (const channelUsers of Object.values(this.state.typingUsersByChannel)) {
			for (const user of Object.values(channelUsers)) {
				clearTimeout(user.timeout);
			}
		}
	}
}

export default new TypingStore();
