import clsx from 'clsx';
import {AnimatePresence, motion} from 'motion/react';
import React from 'react';
import type {TooltipItemProps, TooltipPosition, TooltipType} from '~/components/uikit/Tooltip';
import styles from '~/components/uikit/Tooltip/Tooltip.module.css';
import TooltipStore from '~/stores/TooltipStore';

const MAX_WIDTH_MAP = {
	default: 190,
	xl: 350,
	none: 'none',
};

const TooltipPositionToStyle: Record<TooltipPosition, string> = {
	top: styles.tooltipTop,
	left: styles.tooltipLeft,
	right: styles.tooltipRight,
	bottom: styles.tooltipBottom,
};

const TooltipTypeToStyle: Record<TooltipType, string> = {
	normal: styles.tooltipPrimary,
	error: styles.tooltipRed,
};

const getPointerStyle = (
	position: TooltipPosition,
	alignment: string,
	nudge: number,
	tooltipWidth: number,
	targetWidth: number,
	tooltipLeft: number,
	targetLeft: number,
) => {
	const isHorizontal = position === 'left' || position === 'right';
	const alignProperty = isHorizontal ? 'top' : 'left';
	const offset = nudge;

	if (isHorizontal) {
		// For left/right tooltips
		if (alignment === 'top') {
			return {[alignProperty]: `${16 + offset}px`};
		}
		if (alignment === 'bottom') {
			return {[alignProperty]: `calc(100% - ${20 - offset}px)`};
		}
		return {[alignProperty]: `calc(50% + ${offset}px)`};
	}
	// For top/bottom tooltips
	// Calculate where the arrow should point relative to the tooltip's left edge
	const targetCenter = targetLeft + targetWidth / 2;
	const arrowPosition = targetCenter - tooltipLeft;

	// Constrain arrow position to stay within tooltip bounds (with some padding)
	const minArrowPosition = 12; // Minimum distance from tooltip edge
	const maxArrowPosition = tooltipWidth - 12; // Maximum distance from tooltip edge

	const constrainedPosition = Math.max(minArrowPosition, Math.min(maxArrowPosition, arrowPosition));

	return {[alignProperty]: `${constrainedPosition}px`};
};

const TooltipItem = ({
	text,
	type,
	position,
	align,
	nudge = 0,
	x,
	y,
	targetWidth,
	targetHeight,
	padding,
	maxWidth,
}: TooltipItemProps) => {
	const [offsetX, setOffsetX] = React.useState<number | null>(null);
	const [offsetY, setOffsetY] = React.useState<number | null>(null);
	const tooltipRef = React.useRef<HTMLDivElement | null>(null);

	const updateOffsets = React.useCallback(() => {
		const domNode = tooltipRef.current;
		if (!domNode) return;

		let newOffsetX: number;
		let newOffsetY = -(domNode.offsetHeight / 2);
		const tooltipWidth = domNode.offsetWidth;
		const tooltipHeight = domNode.offsetHeight;

		// Calculate initial position
		switch (position) {
			case 'left': {
				newOffsetX = -tooltipWidth - padding;
				newOffsetY += targetHeight / 2;
				break;
			}
			case 'right': {
				newOffsetX = targetWidth + padding;
				newOffsetY += targetHeight / 2;
				break;
			}
			case 'bottom': {
				newOffsetX = -(tooltipWidth / 2) + targetWidth / 2;
				newOffsetY = targetHeight + padding;
				break;
			}
			default: {
				// top
				newOffsetX = -(tooltipWidth / 2) + targetWidth / 2;
				newOffsetY = -tooltipHeight - padding;
			}
		}

		// Adjust for viewport boundaries
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;

		if (x + newOffsetX < padding) {
			newOffsetX = padding - x;
		} else if (x + newOffsetX + tooltipWidth > viewportWidth - padding) {
			newOffsetX = viewportWidth - padding - x - tooltipWidth;
		}

		if (y + newOffsetY < padding) {
			newOffsetY = padding - y;
		} else if (y + newOffsetY + tooltipHeight > viewportHeight - padding) {
			newOffsetY = viewportHeight - padding - y - tooltipHeight;
		}

		setOffsetX(newOffsetX);
		setOffsetY(newOffsetY);
	}, [position, x, y, targetWidth, targetHeight, padding]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	React.useLayoutEffect(() => {
		updateOffsets();
		window.addEventListener('resize', updateOffsets);
		return () => window.removeEventListener('resize', updateOffsets);
	}, [updateOffsets, text]);

	const content = typeof text === 'function' ? text() : text;
	if (!content || (Array.isArray(content) && content.length === 0)) {
		return null;
	}

	const tooltipLeft = offsetX != null ? x + offsetX : 0;
	const tooltipWidth = tooltipRef.current?.offsetWidth || 0;

	const style = {
		position: 'absolute' as const,
		left: tooltipLeft,
		top: offsetY != null ? y + offsetY : undefined,
	};

	const pointerStyle = getPointerStyle(position, align, nudge, tooltipWidth, targetWidth, tooltipLeft, x);

	return (
		<motion.div
			ref={tooltipRef}
			style={style}
			initial={{opacity: 0, scale: 0.98}}
			animate={{opacity: 1, scale: 1}}
			exit={{opacity: 0, scale: 0.98}}
			transition={{
				opacity: {duration: 0.1},
				scale: {type: 'spring', damping: 25, stiffness: 500},
			}}
		>
			<div
				className={clsx(styles.tooltip, TooltipPositionToStyle[position], TooltipTypeToStyle[type])}
				style={{maxWidth: MAX_WIDTH_MAP[maxWidth]}}
			>
				<div className={clsx(styles.tooltipPointer, styles.tooltipPointerBg)} style={pointerStyle} />
				<div className={clsx(styles.tooltipPointer)} style={pointerStyle} />
				<div className={clsx(styles.tooltipContent)}>{content}</div>
			</div>
		</motion.div>
	);
};

export const Tooltips = () => {
	const {tooltips} = TooltipStore.useStore();
	// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
	const tooltipElements = Object.values(tooltips).map((props, index) => <TooltipItem key={index} {...props} />);
	return (
		<AnimatePresence>
			<div className={styles.tooltips}>{tooltipElements}</div>
		</AnimatePresence>
	);
};
