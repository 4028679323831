import React from 'react';
import {Permissions} from '~/Constants';
import {Switch} from '~/components/form/Switch';
import {i18n} from '~/i18n';

const PERMISSIONS = [
	{
		key: Permissions.CREATE_INVITE,
		name: i18n.Messages.PERMISSION_CREATE_INVITE_TITLE,
		description: i18n.Messages.PERMISSION_CREATE_INVITE_DESCRIPTION,
	},
	{
		key: Permissions.KICK_MEMBERS,
		name: i18n.Messages.PERMISSION_KICK_MEMBERS_TITLE,
		description: i18n.Messages.PERMISSION_KICK_MEMBERS_DESCRIPTION,
	},
	{
		key: Permissions.BAN_MEMBERS,
		name: i18n.Messages.PERMISSION_BAN_MEMBERS_TITLE,
		description: i18n.Messages.PERMISSION_BAN_MEMBERS_DESCRIPTION,
	},
	{
		key: Permissions.ADMINISTRATOR,
		name: i18n.Messages.PERMISSION_ADMINISTRATOR_TITLE,
		description: i18n.Messages.PERMISSION_ADMINISTRATOR_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_CHANNELS,
		name: i18n.Messages.PERMISSION_MANAGE_CHANNELS_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_CHANNELS_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_GUILD,
		name: i18n.Messages.PERMISSION_MANAGE_GUILD_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_GUILD_DESCRIPTION,
	},
	{
		key: Permissions.ADD_REACTIONS,
		name: i18n.Messages.PERMISSION_ADD_REACTIONS_TITLE,
		description: i18n.Messages.PERMISSION_ADD_REACTIONS_DESCRIPTION,
	},
	{
		key: Permissions.VIEW_AUDIT_LOG,
		name: i18n.Messages.PERMISSION_VIEW_AUDIT_LOG_TITLE,
		description: i18n.Messages.PERMISSION_VIEW_AUDIT_LOG_DESCRIPTION,
	},
	{
		key: Permissions.PRIORITY_SPEAKER,
		name: i18n.Messages.PERMISSION_PRIORITY_SPEAKER_TITLE,
		description: i18n.Messages.PERMISSION_PRIORITY_SPEAKER_DESCRIPTION,
	},
	{
		key: Permissions.STREAM,
		name: i18n.Messages.PERMISSION_STREAM_TITLE,
		description: i18n.Messages.PERMISSION_STREAM_DESCRIPTION,
	},
	{
		key: Permissions.VIEW_CHANNEL,
		name: i18n.Messages.PERMISSION_VIEW_CHANNEL_TITLE,
		description: i18n.Messages.PERMISSION_VIEW_CHANNEL_DESCRIPTION,
	},
	{
		key: Permissions.SEND_MESSAGES,
		name: i18n.Messages.PERMISSION_SEND_MESSAGES_TITLE,
		description: i18n.Messages.PERMISSION_SEND_MESSAGES_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_MESSAGES,
		name: i18n.Messages.PERMISSION_MANAGE_MESSAGES_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_MESSAGES_DESCRIPTION,
	},
	{
		key: Permissions.EMBED_LINKS,
		name: i18n.Messages.PERMISSION_EMBED_LINKS_TITLE,
		description: i18n.Messages.PERMISSION_EMBED_LINKS_DESCRIPTION,
	},
	{
		key: Permissions.ATTACH_FILES,
		name: i18n.Messages.PERMISSION_ATTACH_FILES_TITLE,
		description: i18n.Messages.PERMISSION_ATTACH_FILES_DESCRIPTION,
	},
	{
		key: Permissions.READ_MESSAGE_HISTORY,
		name: i18n.Messages.PERMISSION_READ_MESSAGE_HISTORY_TITLE,
		description: i18n.Messages.PERMISSION_READ_MESSAGE_HISTORY_DESCRIPTION,
	},
	{
		key: Permissions.MENTION_EVERYONE,
		name: i18n.Messages.PERMISSION_MENTION_EVERYONE_TITLE,
		description: i18n.Messages.PERMISSION_MENTION_EVERYONE_DESCRIPTION,
	},
	{
		key: Permissions.USE_EXTERNAL_EMOJIS,
		name: i18n.Messages.PERMISSION_USE_EXTERNAL_EMOJIS_TITLE,
		description: i18n.Messages.PERMISSION_USE_EXTERNAL_EMOJIS_DESCRIPTION,
	},
	{
		key: Permissions.CONNECT,
		name: i18n.Messages.PERMISSION_CONNECT_TITLE,
		description: i18n.Messages.PERMISSION_CONNECT_DESCRIPTION,
	},
	{
		key: Permissions.SPEAK,
		name: i18n.Messages.PERMISSION_SPEAK_TITLE,
		description: i18n.Messages.PERMISSION_SPEAK_DESCRIPTION,
	},
	{
		key: Permissions.MUTE_MEMBERS,
		name: i18n.Messages.PERMISSION_MUTE_MEMBERS_TITLE,
		description: i18n.Messages.PERMISSION_MUTE_MEMBERS_DESCRIPTION,
	},
	{
		key: Permissions.DEAFEN_MEMBERS,
		name: i18n.Messages.PERMISSION_DEAFEN_MEMBERS_TITLE,
		description: i18n.Messages.PERMISSION_DEAFEN_MEMBERS_DESCRIPTION,
	},
	{
		key: Permissions.MOVE_MEMBERS,
		name: i18n.Messages.PERMISSION_MOVE_MEMBERS_TITLE,
		description: i18n.Messages.PERMISSION_MOVE_MEMBERS_DESCRIPTION,
	},
	{
		key: Permissions.USE_VAD,
		name: i18n.Messages.PERMISSION_USE_VAD_TITLE,
		description: i18n.Messages.PERMISSION_USE_VAD_DESCRIPTION,
	},
	{
		key: Permissions.CHANGE_NICKNAME,
		name: i18n.Messages.PERMISSION_CHANGE_NICKNAME_TITLE,
		description: i18n.Messages.PERMISSION_CHANGE_NICKNAME_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_NICKNAMES,
		name: i18n.Messages.PERMISSION_MANAGE_NICKNAMES_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_NICKNAMES_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_ROLES,
		name: i18n.Messages.PERMISSION_MANAGE_ROLES_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_ROLES_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_WEBHOOKS,
		name: i18n.Messages.PERMISSION_MANAGE_WEBHOOKS_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_WEBHOOKS_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_GUILD_EXPRESSIONS,
		name: i18n.Messages.PERMISSION_MANAGE_GUILD_EXPRESSIONS_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_GUILD_EXPRESSIONS_DESCRIPTION,
	},
	{
		key: Permissions.USE_APPLICATION_COMMANDS,
		name: i18n.Messages.PERMISSION_USE_APPLICATION_COMMANDS_TITLE,
		description: i18n.Messages.PERMISSION_USE_APPLICATION_COMMANDS_DESCRIPTION,
	},
	{
		key: Permissions.REQUEST_TO_SPEAK,
		name: i18n.Messages.PERMISSION_REQUEST_TO_SPEAK_TITLE,
		description: i18n.Messages.PERMISSION_REQUEST_TO_SPEAK_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_EVENTS,
		name: i18n.Messages.PERMISSION_MANAGE_EVENTS_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_EVENTS_DESCRIPTION,
	},
	{
		key: Permissions.MANAGE_THREADS,
		name: i18n.Messages.PERMISSION_MANAGE_THREADS_TITLE,
		description: i18n.Messages.PERMISSION_MANAGE_THREADS_DESCRIPTION,
	},
	{
		key: Permissions.CREATE_PUBLIC_THREADS,
		name: i18n.Messages.PERMISSION_CREATE_PUBLIC_THREADS_TITLE,
		description: i18n.Messages.PERMISSION_CREATE_PUBLIC_THREADS_DESCRIPTION,
	},
	{
		key: Permissions.CREATE_PRIVATE_THREADS,
		name: i18n.Messages.PERMISSION_CREATE_PRIVATE_THREADS_TITLE,
		description: i18n.Messages.PERMISSION_CREATE_PRIVATE_THREADS_DESCRIPTION,
	},
	{
		key: Permissions.USE_EXTERNAL_STICKERS,
		name: i18n.Messages.PERMISSION_USE_EXTERNAL_STICKERS_TITLE,
		description: i18n.Messages.PERMISSION_USE_EXTERNAL_STICKERS_DESCRIPTION,
	},
	{
		key: Permissions.SEND_MESSAGES_IN_THREADS,
		name: i18n.Messages.PERMISSION_SEND_MESSAGES_IN_THREADS_TITLE,
		description: i18n.Messages.PERMISSION_SEND_MESSAGES_IN_THREADS_DESCRIPTION,
	},
	{
		key: Permissions.MODERATE_MEMBERS,
		name: i18n.Messages.PERMISSION_MODERATE_MEMBERS_TITLE,
		description: i18n.Messages.PERMISSION_MODERATE_MEMBERS_DESCRIPTION,
	},
	{
		key: Permissions.USE_SOUNDBOARD,
		name: i18n.Messages.PERMISSION_USE_SOUNDBOARD_TITLE,
		description: i18n.Messages.PERMISSION_USE_SOUNDBOARD_DESCRIPTION,
	},
	{
		key: Permissions.CREATE_GUILD_EXPRESSIONS,
		name: i18n.Messages.PERMISSION_CREATE_GUILD_EXPRESSIONS_TITLE,
		description: i18n.Messages.PERMISSION_CREATE_GUILD_EXPRESSIONS_DESCRIPTION,
	},
	{
		key: Permissions.CREATE_EVENTS,
		name: i18n.Messages.PERMISSION_CREATE_EVENTS_TITLE,
		description: i18n.Messages.PERMISSION_CREATE_EVENTS_DESCRIPTION,
	},
	{
		key: Permissions.USE_EXTERNAL_SOUNDS,
		name: i18n.Messages.PERMISSION_USE_EXTERNAL_SOUNDS_TITLE,
		description: i18n.Messages.PERMISSION_USE_EXTERNAL_SOUNDS_DESCRIPTION,
	},
	{
		key: Permissions.SEND_VOICE_MESSAGES,
		name: i18n.Messages.PERMISSION_SEND_VOICE_MESSAGES_TITLE,
		description: i18n.Messages.PERMISSION_SEND_VOICE_MESSAGES_DESCRIPTION,
	},
	{
		key: Permissions.SEND_POLLS,
		name: i18n.Messages.PERMISSION_SEND_POLLS_TITLE,
		description: i18n.Messages.PERMISSION_SEND_POLLS_DESCRIPTION,
	},
];

export const PermissionSelector = ({
	initialPermissions,
	onChange,
}: {
	initialPermissions: bigint;
	onChange: (permissions: bigint) => void;
}) => {
	const [permissions, setPermissions] = React.useState(initialPermissions);

	const handleToggle = (permission: bigint) => {
		const newPermissions = permissions ^ permission;
		setPermissions(newPermissions);
		onChange(newPermissions);
	};

	return (
		<div className="flex flex-col gap-3">
			{PERMISSIONS.map((perm, index, {length}) => (
				<div key={perm.key.toString()} className="flex flex-col gap-3">
					<Switch
						label={perm.name}
						description={perm.description}
						value={(permissions & perm.key) !== 0n}
						onChange={() => handleToggle(perm.key)}
					/>
					{index < length - 1 && <div className="h-px bg-background-header-secondary" />}
				</div>
			))}
		</div>
	);
};
