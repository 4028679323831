import {At, XCircle} from '@phosphor-icons/react';
import clsx from 'clsx';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {MessageRecord} from '~/records/MessageRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import GuildStore from '~/stores/GuildStore';
import * as NicknameUtils from '~/utils/NicknameUtils';

type ReplyBarProps = {
	replyingMessageObject: MessageRecord;
	shouldReplyMention: boolean;
	setShouldReplyMention: (mentioning: boolean) => void;
	channel: ChannelRecord;
};

export const ReplyBar = ({
	replyingMessageObject,
	shouldReplyMention,
	setShouldReplyMention,
	channel,
}: ReplyBarProps) => {
	const guild = GuildStore.useGuild(channel.guildId)!;
	const shouldRenderMentionButton =
		replyingMessageObject.author.id !== AuthenticationStore.getId() && replyingMessageObject.webhookId == null;

	return (
		<div className="rounded-t-lg bg-background-primary">
			<div className="-mt-[3px] overflow-hidden pt-[3px]">
				<div className="grid items-center rounded-t-lg bg-background-primary" style={{gridTemplateColumns: '1fr auto'}}>
					<div className="ml-4 truncate text-sm text-text-primary">
						{i18n.Messages.REPLYING_TO}{' '}
						<span className="font-medium">{NicknameUtils.getNickname(replyingMessageObject.author, guild.id)}</span>
					</div>

					<div className="flex items-center">
						{shouldRenderMentionButton && (
							<Tooltip text={i18n.format(i18n.Messages.REPLY_MENTION_TOOLTIP, {enabled: shouldReplyMention})}>
								<div
									role="switch"
									aria-checked={shouldReplyMention}
									tabIndex={0}
									onClick={() => setShouldReplyMention(!shouldReplyMention)}
									onKeyDown={(event) => event.key === 'Enter' && setShouldReplyMention(!shouldReplyMention)}
								>
									<div
										className={clsx(
											'flex cursor-pointer items-center px-3 py-2 font-bold text-sm uppercase',
											shouldReplyMention
												? 'text-blue-400'
												: 'text-text-primary-muted transition-colors duration-200 hover:text-text-primary',
										)}
									>
										<At weight="bold" className="mr-1 h-5 w-5" />
										{shouldReplyMention ? i18n.Messages.ON : i18n.Messages.OFF}
									</div>
								</div>
							</Tooltip>
						)}

						<div className="h-[20px] w-px bg-background-header-secondary" aria-hidden={true} />

						<div
							className="pointer flex-none py-[8px] pr-[18px] pl-[16px] text-text-primary-muted leading-[0px] transition-colors duration-200 hover:text-text-primary"
							role="button"
							tabIndex={0}
							onClick={() => Dispatcher.dispatch({type: 'MESSAGE_REPLY_STOP', channelId: channel.id})}
							onKeyDown={(event) =>
								event.key === 'Enter' && Dispatcher.dispatch({type: 'MESSAGE_REPLY_STOP', channelId: channel.id})
							}
						>
							<XCircle className="h-5 w-5" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
