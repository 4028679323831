export const convertToCodePoints = (emoji: string): string => {
	const containsZWJ = emoji.includes('\u200D');
	const processedEmoji = containsZWJ ? emoji : emoji.replace(/\uFE0F/g, '');
	return Array.from(processedEmoji)
		.map((char) => char.codePointAt(0)?.toString(16).replace(/^0+/, '') || '')
		.join('-');
};

export const fromHexCodePoint = (hex: string): string => String.fromCodePoint(Number.parseInt(hex, 16));

export const getTwemojiURL = (codePoints: string): string => {
	if (process.env.NODE_ENV === 'test') {
		return '';
	}
	try {
		return require(`@discordapp/twemoji/dist/svg/${codePoints}.svg`);
	} catch (error) {
		console.warn(`Failed to load Twemoji for code points ${codePoints}`, error);
		return '';
	}
};

export const getEmojiURL = (unicode: string): string => getTwemojiURL(convertToCodePoints(unicode));
