import {GuildRoleFlags, OFFLINE_STATUS_TYPES, Permissions} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import Dispatcher from '~/flux/Dispatcher';
import {Store} from '~/flux/Store';
import type {Channel} from '~/records/ChannelRecord';
import type {GuildMemberRecord} from '~/records/GuildMemberRecord';
import type {GuildRole, GuildRoleRecord} from '~/records/GuildRoleRecord';
import GuildMemberStore from '~/stores/GuildMemberStore';
import GuildStore from '~/stores/GuildStore';
import PresenceStore from '~/stores/PresenceStore';
import * as NicknameUtils from '~/utils/NicknameUtils';
import * as PermissionUtils from '~/utils/PermissionUtils';

const ONLINE_KEY = 'online' as const;
const OFFLINE_KEY = 'offline' as const;

export type MemberGroup = {
	readonly id: string;
	readonly count: number;
	readonly members: ReadonlyArray<GuildMemberRecord>;
	readonly role: Readonly<Pick<GuildRole, 'id' | 'name' | 'position'>>;
	readonly displayName: string;
};

type MemberList = Readonly<{
	groups: ReadonlyArray<MemberGroup>;
	memberCount: number;
	onlineCount: number;
}>;

type MemberLists = Readonly<Record<string, MemberList>>;

type State = Readonly<{
	memberLists: Record<string, MemberLists>;
}>;

const initialState: State = {
	memberLists: {},
};

const sortMembers = (members: ReadonlyArray<GuildMemberRecord>, guildId: string): ReadonlyArray<GuildMemberRecord> =>
	[...members].sort((a, b) =>
		NicknameUtils.getNickname(a.user, guildId).localeCompare(NicknameUtils.getNickname(b.user, guildId)),
	);

const compareGroups = (a: MemberGroup, b: MemberGroup): number => {
	if (a.role && b.role && a.id !== ONLINE_KEY && a.id !== OFFLINE_KEY && b.id !== ONLINE_KEY && b.id !== OFFLINE_KEY) {
		return a.role.position - b.role.position;
	}

	if (a.id !== ONLINE_KEY && a.id !== OFFLINE_KEY && (b.id === ONLINE_KEY || b.id === OFFLINE_KEY)) {
		return -1;
	}
	if (b.id !== ONLINE_KEY && b.id !== OFFLINE_KEY && (a.id === ONLINE_KEY || a.id === OFFLINE_KEY)) {
		return 1;
	}

	if (a.id === ONLINE_KEY) return -1;
	if (b.id === ONLINE_KEY) return 1;
	if (a.id === OFFLINE_KEY) return 1;
	if (b.id === OFFLINE_KEY) return -1;

	return a.displayName.localeCompare(b.displayName);
};

class GuildMemberListStore extends Store<State> {
	private lastMemberListCache: Record<string, Record<string, MemberList>> = {};

	constructor() {
		super(initialState);
		this.syncWith([PresenceStore], () => this.handlePresenceUpdate());
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen();
			case 'GUILD_MEMBER_LIST_CREATE':
				return this.createMemberList(action.guildId, action.channelId);
			case 'GUILD_MEMBER_ADD':
			case 'GUILD_MEMBER_UPDATE':
			case 'GUILD_MEMBER_REMOVE':
			case 'GUILD_ROLE_CREATE':
			case 'GUILD_ROLE_UPDATE':
			case 'GUILD_ROLE_DELETE':
				return this.rebuildGuildMemberLists(action.guildId);
			case 'CHANNEL_UPDATE':
				return this.rebuildMemberList(action.channel);
			case 'CHANNEL_DELETE':
				return this.removeMemberList(action.channel);
			case 'GUILD_DELETE':
				return this.removeGuildMemberLists(action.guildId);
			default:
				return false;
		}
	}

	private getCachedMemberList(guildId: string, channelId: string): MemberList | undefined {
		return this.lastMemberListCache[guildId]?.[channelId];
	}

	useMemberList(guildId: string, channelId: string): MemberList {
		const {memberLists} = this.useStore();
		const existingList = memberLists[guildId]?.[channelId];

		if (!existingList) {
			setTimeout(() => {
				Dispatcher.dispatch({
					type: 'GUILD_MEMBER_LIST_CREATE',
					guildId,
					channelId,
				});
			}, 0);

			return {groups: [], memberCount: 0, onlineCount: 0};
		}

		return existingList;
	}

	getMemberList(guildId: string, channelId: string): MemberList {
		return (
			this.state.memberLists[guildId]?.[channelId] ?? {
				groups: [],
				memberCount: 0,
				onlineCount: 0,
			}
		);
	}

	private handlePresenceUpdate(): boolean {
		const guildsToUpdate = Object.keys(this.state.memberLists);
		for (const guildId of guildsToUpdate) {
			this.rebuildGuildMemberLists(guildId);
		}
		return true;
	}

	private handleConnectionOpen(): boolean {
		this.setState(initialState);
		return true;
	}

	private createMemberList(guildId: string, channelId: string): boolean {
		this.setState((prevState) => ({
			memberLists: {
				...prevState.memberLists,
				[guildId]: {
					...(prevState.memberLists[guildId] || {}),
					[channelId]: this.buildMemberList(guildId, channelId),
				},
			},
		}));
		return true;
	}

	private rebuildGuildMemberLists(guildId: string): boolean {
		this.setState((prevState) => {
			const existingGuildLists = prevState.memberLists[guildId];
			if (!existingGuildLists) return prevState;

			const updatedGuildLists = Object.keys(existingGuildLists).reduce<MemberLists>(
				(acc, channelId) => ({
					// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
					...acc,
					[channelId]: this.buildMemberList(guildId, channelId),
				}),
				{},
			);

			return {
				memberLists: {
					...prevState.memberLists,
					[guildId]: updatedGuildLists,
				},
			};
		});
		return true;
	}

	private rebuildMemberList(channel: Channel): boolean {
		const {guild_id: guildId, id: channelId} = channel;
		this.setState((prevState) => {
			const existingGuildLists = prevState.memberLists[guildId];
			if (!existingGuildLists?.[channelId]) return prevState;

			return {
				memberLists: {
					...prevState.memberLists,
					[guildId]: {
						...existingGuildLists,
						[channelId]: this.buildMemberList(guildId, channelId),
					},
				},
			};
		});
		return true;
	}

	private removeMemberList(channel: Channel): boolean {
		const {guild_id: guildId, id: channelId} = channel;
		this.setState((prevState) => {
			const existingGuildLists = prevState.memberLists[guildId];
			if (!existingGuildLists?.[channelId]) return prevState;

			const {[channelId]: _, ...remainingChannels} = existingGuildLists;

			return {
				memberLists: {
					...prevState.memberLists,
					...(Object.keys(remainingChannels).length > 0 ? {[guildId]: remainingChannels} : {}),
				},
			};
		});
		return true;
	}

	private removeGuildMemberLists(guildId: string): boolean {
		delete this.lastMemberListCache[guildId];
		this.setState((prevState) => {
			const {[guildId]: _, ...remainingGuilds} = prevState.memberLists;
			return {memberLists: remainingGuilds};
		});
		return true;
	}

	private buildMemberList(guildId: string, channelId: string): MemberList {
		const members = this.getMembers(guildId, channelId);
		const previousList = this.getCachedMemberList(guildId, channelId);

		if (previousList && previousList.memberCount === members.length) {
			const currentRoles = new Set(members.flatMap((m) => [...m.roles]));
			const previousRoles = new Set(
				previousList.groups.filter((g) => g.id !== ONLINE_KEY && g.id !== OFFLINE_KEY).map((g) => g.role.id),
			);

			if (this.areSetsEqual(currentRoles, previousRoles)) {
				return previousList;
			}
		}

		const {onlineMembers, offlineMembers} = this.partitionMembersByStatus(members);
		const groupsMap = this.createGroupsMap(onlineMembers, guildId);

		if (offlineMembers.length > 0) {
			groupsMap[OFFLINE_KEY] = {
				id: OFFLINE_KEY,
				count: offlineMembers.length,
				members: sortMembers(offlineMembers, guildId),
				role: {id: OFFLINE_KEY, name: 'Offline', position: -1},
				displayName: 'Offline',
			};
		}

		const groups = Object.values(groupsMap)
			.map((group) => ({
				...group,
				members: sortMembers(group.members, guildId),
			}))
			.sort(compareGroups);

		const newList = {
			groups,
			memberCount: members.length,
			onlineCount: onlineMembers.length,
		};

		this.lastMemberListCache[guildId] = {
			...(this.lastMemberListCache[guildId] || {}),
			[channelId]: newList,
		};

		return newList;
	}

	private getMembers(guildId: string, channelId: string): ReadonlyArray<GuildMemberRecord> {
		return GuildMemberStore.getMembers(guildId).filter((member) =>
			PermissionUtils.can(Permissions.VIEW_CHANNEL, {guildId, userId: member.user.id, channelId}),
		);
	}

	private partitionMembersByStatus(members: ReadonlyArray<GuildMemberRecord>): {
		onlineMembers: ReadonlyArray<GuildMemberRecord>;
		offlineMembers: ReadonlyArray<GuildMemberRecord>;
	} {
		return members.reduce(
			(acc, member) => {
				if (this.isMemberOnline(member)) {
					acc.onlineMembers.push(member);
				} else {
					acc.offlineMembers.push(member);
				}
				return acc;
			},
			{onlineMembers: [] as Array<GuildMemberRecord>, offlineMembers: [] as Array<GuildMemberRecord>},
		);
	}

	private createGroupsMap(
		onlineMembers: ReadonlyArray<GuildMemberRecord>,
		guildId: string,
	): Record<string, MemberGroup> {
		return onlineMembers.reduce(
			(groups, member) => {
				const role = this.processRole(member, guildId) ?? {
					id: ONLINE_KEY,
					name: 'Online',
					position: -1,
				};

				const existingGroup = groups[role.id] ?? {
					id: role.id,
					count: 0,
					members: [],
					role,
					displayName: role.name,
				};

				groups[role.id] = {
					...existingGroup,
					count: existingGroup.count + 1,
					members: [...existingGroup.members, member],
				};
				return groups;
			},
			{} as Record<string, MemberGroup>,
		);
	}

	private isMemberOnline(member: GuildMemberRecord): boolean {
		const status = PresenceStore.getStatus(member.user.id);
		return !OFFLINE_STATUS_TYPES.has(status as any);
	}

	private processRole(member: GuildMemberRecord, guildId: string): GuildRoleRecord | null {
		const guild = GuildStore.getGuild(guildId);
		if (!guild) return null;

		const hoistedRoles = [...member.roles]
			.map((roleId) => guild.roles[roleId])
			.filter((role): role is GuildRoleRecord => role !== undefined && (role.flags & GuildRoleFlags.HOISTED) !== 0);

		return hoistedRoles.length === 0
			? null
			: hoistedRoles.reduce((highest, role) => (role.position > highest.position ? role : highest));
	}

	private areSetsEqual(a: Set<unknown>, b: Set<unknown>): boolean {
		if (a.size !== b.size) return false;
		for (const item of a) {
			if (!b.has(item)) return false;
		}
		return true;
	}
}

export default new GuildMemberListStore();
