import 'highlight.js/styles/github-dark.css';
import 'katex/dist/katex.min.css';
import '~/i18n';
import '~/tailwind.css';

import {IconContext} from '@phosphor-icons/react';
import {Navigate, Outlet, RouterProvider, createRootRoute, createRoute, createRouter} from '@tanstack/react-router';
import {FocusGuard, LOCK_STACK} from 'focus-layers';
import React from 'react';
import * as WindowActionCreators from '~/actions/WindowActionCreators';
import {ChannelIndexPage} from '~/components/channel/ChannelIndexPage';
import {ChannelLayout} from '~/components/channel/ChannelLayout';
import {FluxerIcon} from '~/components/icons/FluxerIcon';
import {AppLayout} from '~/components/layout/AppLayout';
import {AuthLayout} from '~/components/layout/AuthLayout';
import {GuildLayout} from '~/components/layout/GuildLayout';
import {GuildsLayout} from '~/components/layout/GuildsLayout';
import {Modals} from '~/components/modals/Modals';
import {LoginPage} from '~/components/pages/LoginPage';
import {RegisterPage} from '~/components/pages/RegisterPage';
import {Button} from '~/components/uikit/Button/Button';
import {Popouts} from '~/components/uikit/Popout/Popouts';
import {SVGMasks} from '~/components/uikit/SVGMasks';
import {Toasts} from '~/components/uikit/Toast/Toasts';
import {Tooltips} from '~/components/uikit/Tooltip/Tooltips';
import {i18n} from '~/i18n';
import AppStorage from '~/lib/AppStorage';
import AccessibilityStore from '~/stores/AccessibilityStore';
import UserSettingsStore from '~/stores/UserSettingsStore';
import * as ContextMenuUtils from '~/utils/ContextMenuUtils';
import * as RouterUtils from '~/utils/RouterUtils';

const ErrorFallback = () => {
	return (
		<div className="flex h-screen flex-col items-center justify-center gap-3 px-4 py-8 text-center">
			<FluxerIcon className="h-24 w-24" />
			<div className="my-2 flex flex-col items-center gap-2 text-center">
				<h1 className="font-bold text-3xl">{i18n.Messages.ERROR_BOUNDARY_TITLE}</h1>
				<p className="text-text-primary-muted">{i18n.Messages.ERROR_BOUNDARY_DESCRIPTION}</p>
			</div>
			<div className="flex flex-col gap-4 md:flex-row">
				<Button onClick={() => location.reload()}>{i18n.Messages.ERROR_BOUNDARY_RELOAD}</Button>
				<Button
					onClick={() => {
						AppStorage.clear();
						location.reload();
					}}
					variant="danger"
				>
					{i18n.Messages.ERROR_BOUNDARY_RESET_APP}
				</Button>
			</div>
		</div>
	);
};

const NotFound = () => {
	return (
		<div className="flex h-screen flex-col items-center justify-center gap-3 px-4 py-8 text-center">
			<FluxerIcon className="h-24 w-24" />
			<div className="my-2 flex flex-col items-center gap-2 text-center">
				<h1 className="font-bold text-3xl">{i18n.Messages.NOT_FOUND_TITLE}</h1>
				<p className="text-text-primary-muted">{i18n.Messages.NOT_FOUND_DESCRIPTION}</p>
			</div>
			<div className="flex flex-col gap-4 md:flex-row">
				<Button
					onClick={() => {
						window.location.href = '/';
					}}
				>
					{i18n.Messages.NOT_FOUND_BUTTON}
				</Button>
			</div>
		</div>
	);
};

const AppWrapper = () => {
	const userSettings = UserSettingsStore.useStore();
	const {saturationFactor, alwaysUnderlineLinks} = AccessibilityStore.useStore();
	const [focusLockActive, setFocusLockActive] = React.useState(false);

	React.useEffect(() => {
		if (import.meta.env.PUBLIC_REV && import.meta.env.PUBLIC_TS) {
			console.info(`[BUILD INFO] Rev: ${import.meta.env.PUBLIC_REV}, TS: ${import.meta.env.PUBLIC_TS}`);
		}

		const preventScroll = (event: Event) => event.preventDefault();
		const handleBlur = () => WindowActionCreators.focus(false);
		const handleFocus = () => WindowActionCreators.focus(true);
		const handleResize = () => WindowActionCreators.resized();
		const handleContextMenu = ContextMenuUtils.contextMenuCallbackWeb;

		document.addEventListener('scroll', preventScroll);
		window.addEventListener('blur', handleBlur);
		window.addEventListener('focus', handleFocus);
		window.addEventListener('mousedown', handleFocus);
		window.addEventListener('resize', handleResize);
		window.addEventListener('contextmenu', handleContextMenu, false);

		return () => {
			document.removeEventListener('scroll', preventScroll);
			window.removeEventListener('blur', handleBlur);
			window.removeEventListener('focus', handleFocus);
			window.removeEventListener('mousedown', handleFocus);
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('contextmenu', handleContextMenu, false);
		};
	}, []);

	React.useEffect(() => {
		return LOCK_STACK.subscribe(setFocusLockActive);
	}, []);

	React.useEffect(() => {
		const htmlNode = document.documentElement;
		htmlNode.classList.add(`theme-${userSettings.theme}`);
		htmlNode.style.setProperty('--saturation-factor', saturationFactor.toString());

		if (alwaysUnderlineLinks) {
			htmlNode.style.setProperty('--link-decoration', 'underline');
		} else {
			htmlNode.style.removeProperty('--link-decoration');
		}

		return () => {
			htmlNode.classList.remove(`theme-${userSettings.theme}`);
			htmlNode.style.removeProperty('--saturation-factor');
			htmlNode.style.removeProperty('--link-decoration');
		};
	}, [userSettings.theme, saturationFactor, alwaysUnderlineLinks]);

	React.useEffect(() => {
		let styleElement: HTMLStyleElement | null = null;
		if (userSettings.custom_css) {
			styleElement = document.createElement('style');
			styleElement.textContent = userSettings.custom_css;
			document.head.appendChild(styleElement);
		}
		return () => {
			if (styleElement) {
				document.head.removeChild(styleElement);
			}
		};
	}, [userSettings.custom_css]);

	return (
		<IconContext.Provider value={{color: 'currentColor', weight: 'fill'}}>
			<SVGMasks />
			<FocusGuard />
			<div aria-hidden={focusLockActive}>
				<Outlet />
			</div>
			<Modals />
			<Popouts />
			<Toasts />
			<Tooltips />
			<FocusGuard />
		</IconContext.Provider>
	);
};

const redirectRoute = createRoute({
	path: '/',
	component: () => <Navigate to="/channels" />,
	getParentRoute: () => rootRoute,
});

const rootRoute = createRootRoute({
	component: AppWrapper,
});

const appRoute = createRoute({
	getParentRoute: () => rootRoute,
	id: 'app',
	component: AppLayout,
});

const channelsRoute = createRoute({
	getParentRoute: () => appRoute,
	path: '/channels',
	component: GuildsLayout,
});

const guildRoute = createRoute({
	getParentRoute: () => channelsRoute,
	path: '/$guildId',
	component: GuildLayout,
});

const channelRoute = createRoute({
	getParentRoute: () => guildRoute,
	path: '/$channelId',
	component: ChannelLayout,
});

const channelIndexRoute = createRoute({
	getParentRoute: () => channelRoute,
	path: '/',
	component: ChannelIndexPage,
});

const authRoute = createRoute({
	getParentRoute: () => rootRoute,
	id: 'auth',
	component: AuthLayout,
});

const loginRoute = createRoute({
	getParentRoute: () => authRoute,
	path: '/login',
	component: LoginPage,
});

const registerRoute = createRoute({
	getParentRoute: () => authRoute,
	path: '/register',
	component: RegisterPage,
});

const notFoundRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: '*',
	component: NotFound,
});

const routeTree = rootRoute.addChildren([
	redirectRoute,
	appRoute.addChildren([
		channelsRoute.addChildren([guildRoute.addChildren([channelRoute.addChildren([channelIndexRoute])])]),
	]),
	authRoute.addChildren([loginRoute, registerRoute]),
	notFoundRoute,
]);

const router = createRouter({
	routeTree,
	defaultPreload: 'intent',
	defaultErrorComponent: ErrorFallback,
});

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}

export const App = () => {
	return <RouterProvider router={router} history={RouterUtils.getHistory()} />;
};
