import {DndContext, type DragEndEvent, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {SortableContext, arrayMove, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {type Icon, Pencil, Plus, Trash} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import {useForm} from 'react-hook-form';
import {MAX_GUILD_EMOJIS_ANIMATED, MAX_GUILD_EMOJIS_STATIC} from '~/Constants';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as GuildEmojiActionCreators from '~/actions/GuildEmojiActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Form} from '~/components/form/Form';
import {Input, Textarea} from '~/components/form/Input';
import {ConfirmModal} from '~/components/modals/ConfirmModal';
import {GuildDeleteModal} from '~/components/modals/GuildDeleteModal';
import {GuildDomainModal} from '~/components/modals/GuildDomainModal';
import {GuildEmojiEditModal} from '~/components/modals/GuildEmojiEditModal';
import {GuildRoleEditModal} from '~/components/modals/GuildRoleEditModal';
import {GuildVanityURLModal} from '~/components/modals/GuildVanityURLModal';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import type {GuildEmojiRecord} from '~/records/GuildEmojiRecord';
import type {GuildRecord} from '~/records/GuildRecord';
import type {GuildRoleRecord} from '~/records/GuildRoleRecord';
import GuildEmojiStore from '~/stores/GuildEmojiStore';
import GuildStore from '~/stores/GuildStore';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as ColorUtils from '~/utils/ColorUtils';
import * as FormUtils from '~/utils/FormUtils';

export type GuildSettingsTabType =
	| 'overview'
	| 'roles'
	| 'emojis'
	| 'stickers'
	| 'webhooks'
	| 'audit_log'
	| 'bans'
	| 'members'
	| 'invites';

type SettingsTab = {
	type: GuildSettingsTabType;
	label: string;
	component: React.ReactNode;
};

const GuildContext = React.createContext<GuildRecord | undefined>(undefined);

export const useGuild = () => {
	const context = React.useContext(GuildContext);
	if (context == null) {
		throw new Error('useGuild must be used within a <GuildProvider />');
	}
	return context;
};

type GuildProviderProps = {
	guild: GuildRecord;
	children: React.ReactNode;
};

export const GuildProvider = ({guild, children}: GuildProviderProps) => {
	return <GuildContext.Provider value={guild}>{children}</GuildContext.Provider>;
};

export const GuildSettingsModal = ({guildId}: {guildId: string}) => {
	const guild = GuildStore.useGuild(guildId);
	const [selectedTab, setSelectedTab] = React.useState<GuildSettingsTabType>('overview');

	React.useEffect(() => {
		if (!guild) {
			ModalActionCreators.pop();
		}
	}, [guild]);

	if (!guild) {
		return null;
	}

	return (
		<GuildProvider guild={guild}>
			<Modal.Root label={i18n.Messages.GUILD_SETTINGS} size="large">
				<div className="grid h-full flex-auto grid-cols-[1fr,3fr]">
					<div className="grid h-full grid-rows-[1fr,auto] bg-background-primary p-2">
						<ScrollArea className="flex flex-col">
							<div className="px-[10px] py-[6px] font-semibold text-sm text-text-tertiary">{guild.name}</div>
							<div className="mb-2">
								{SETTINGS_TABS.map((tab) => (
									<div
										key={tab.label}
										onClick={() => setSelectedTab(tab.type)}
										onKeyDown={(event) => event.key === 'Enter' && setSelectedTab(tab.type)}
										tabIndex={0}
										aria-selected={tab.type === selectedTab}
										aria-label={tab.label}
										aria-controls={tab.type}
										role="tab"
										className="active:translate-z-0 mb-[2px] transform-gpu cursor-pointer rounded-md px-[10px] py-[6px] text-text-primary-muted leading-[20px] transition-colors duration-200 ease-in-out hover:bg-background-modifier-hover hover:text-text-primary active:translate-y-px aria-selected:bg-background-modifier-selected aria-selected:text-text-primary"
									>
										{tab.label}
									</div>
								))}
							</div>
						</ScrollArea>

						<div className="flex flex-col gap-4">
							<div
								role="button"
								onClick={() => ModalActionCreators.push(() => <GuildDeleteModal guildId={guild.id} />)}
								onKeyDown={(event) =>
									event.key === 'Enter' && ModalActionCreators.push(() => <GuildDeleteModal guildId={guild.id} />)
								}
								tabIndex={0}
								className="active:translate-z-0 mb-[2px] flex transform-gpu cursor-pointer items-center justify-between rounded-md px-[10px] py-[6px] text-text-primary-muted leading-[20px] transition-colors duration-200 ease-in-out hover:bg-background-modifier-hover hover:text-text-primary active:translate-y-px aria-selected:bg-background-modifier-selected aria-selected:text-text-primary"
							>
								{i18n.Messages.DELETE_GUILD}
								<Trash className="mt-px h-4 w-4" />
							</div>
						</div>
					</div>

					<ScrollArea className="relative flex h-full min-h-0 min-w-0 flex-1 flex-grow flex-col border border-background-header-secondary">
						<div className="p-5">{SETTINGS_TABS.find((tab) => tab.type === selectedTab)?.component}</div>
					</ScrollArea>
				</div>
			</Modal.Root>
		</GuildProvider>
	);
};

type FormInputs = {
	icon?: string | null;
	name: string;
	description: string | null;
};

const GuildOverviewTab = () => {
	const guild = useGuild();
	const [hasClearedIcon, setHasClearedIcon] = React.useState(false);
	const [previewIconUrl, setPreviewIconUrl] = React.useState<string | null>(null);
	const form = useForm<FormInputs>({
		defaultValues: React.useMemo(() => ({name: guild.name, description: guild.description}), [guild]),
	});

	const onSubmit = async (data: FormInputs) => {
		try {
			const newUser = await GuildActionCreators.update(guild.id, {
				icon: data.icon,
				name: data.name,
				description: data.description,
			});

			form.reset({
				name: newUser.name,
				description: newUser.description,
			});

			ToastActionCreators.createToast({
				type: 'success',
				children: i18n.Messages.GUILD_UPDATED,
			});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'name');
		}
	};

	const inputRef = React.useRef<HTMLInputElement>(null);
	const handleFileChange = React.useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			try {
				const file = event.target.files?.[0];
				if (!file) {
					return;
				}
				const base64 = await AvatarUtils.fileToBase64(file);
				event.target.value = '';
				form.setValue('icon', base64);
				setPreviewIconUrl(base64);
				setHasClearedIcon(false);
			} catch {
				ToastActionCreators.createToast({
					type: 'error',
					children: i18n.Messages.INVALID_IMAGE,
				});
			}
		},
		[form],
	);

	const handleClearIcon = React.useCallback(() => {
		form.setValue('icon', null);
		setPreviewIconUrl(null);
		setHasClearedIcon(true);
	}, [form]);

	const iconPresentable = hasClearedIcon ? null : (previewIconUrl ?? AvatarUtils.getGuildIconURL(guild, true));

	return (
		<div className="flex flex-col gap-6">
			{/* Overview Section */}
			<section>
				<div className="mb-4">
					<h2 className="font-semibold text-xl">{i18n.Messages.GUILD_OVERVIEW}</h2>
				</div>

				<Form
					form={form}
					onSubmit={onSubmit}
					className="flex h-full flex-col items-center rounded-md border border-background-header-secondary bg-background-tertiary p-6"
				>
					<div className="flex flex-col items-center gap-2">
						<div
							className="active:translate-z-0 relative inline-block h-20 w-20 transform-gpu cursor-pointer rounded-full bg-center bg-cover active:translate-y-px"
							style={{
								backgroundImage: iconPresentable ? `url(${iconPresentable})` : undefined,
							}}
						>
							{!iconPresentable && (
								<div className="absolute inset-0 flex items-center justify-center rounded-full border-4 border-text-tertiary border-dashed">
									<Plus weight="regular" className="h-8 w-8 text-text-tertiary" />
								</div>
							)}
							<input
								accept="image/jpeg,image/png,image/gif,image/webp"
								onChange={handleFileChange}
								ref={inputRef}
								style={{display: 'none'}}
								type="file"
							/>
							<div
								aria-label={i18n.Messages.CHANGE_ICON}
								className="absolute inset-0 flex items-center justify-center"
								onClick={() => inputRef.current?.click()}
								onKeyDown={(event) => event.key === 'Enter' && inputRef.current?.click()}
								role="button"
								tabIndex={0}
							/>
						</div>

						<span
							className="font-medium text-sm text-text-link hover:underline"
							onClick={handleClearIcon}
							onKeyDown={(event) => event.key === 'Enter' && handleClearIcon()}
							role="button"
							tabIndex={0}
						>
							{i18n.Messages.CLEAR_ICON}
						</span>

						<div className="flex flex-col items-center gap-1 text-center text-sm text-text-primary-muted">
							<p>{i18n.Messages.SUPPORTED_FORMATS}</p>
							<p>{i18n.Messages.RECOMMENDED_SIZE}</p>
						</div>

						{form.formState.errors.icon?.message && (
							<p className="text-sm text-text-danger">{form.formState.errors.icon.message}</p>
						)}
					</div>

					<div className="mt-6 flex w-full flex-col gap-3">
						<Input
							{...form.register('name')}
							type="text"
							label={i18n.Messages.NAME}
							placeholder={i18n.Messages.GUILD_NAME_PLACEHOLDER}
							minLength={1}
							maxLength={100}
							error={form.formState.errors.name?.message}
						/>

						<Textarea
							{...form.register('description')}
							label={i18n.Messages.GUILD_DESCRIPTION}
							placeholder={i18n.Messages.GUILD_DESCRIPTION_PLACEHOLDER}
							maxLength={120}
							error={form.formState.errors.description?.message}
						/>
					</div>

					<div className="mt-6 flex gap-2">
						<Button variant="ghost" onClick={form.reset}>
							{i18n.Messages.RESET}
						</Button>
						<Button type="submit" variant="primary" submitting={form.formState.isSubmitting}>
							{i18n.Messages.SAVE_CHANGES}
						</Button>
					</div>
				</Form>
			</section>

			{/* Advanced Settings Section */}
			<section>
				<h2 className="mb-4 font-semibold text-xl">{i18n.Messages.ADVANCED_SETTINGS}</h2>
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
					<div className="rounded-md border border-background-header-secondary bg-background-tertiary p-4">
						<h3 className="mb-2 font-medium text-lg">{i18n.Messages.CUSTOM_URL}</h3>
						<p className="mb-4 text-sm text-text-primary-muted">
							Create a custom invite URL for your server for a more memorable link.
						</p>
						<Button
							variant="brand"
							className="w-full"
							onClick={() => ModalActionCreators.push(() => <GuildVanityURLModal guild={guild} />)}
						>
							{i18n.Messages.MANAGE_GUILD_VANITY_URL}
						</Button>
					</div>

					<div className="rounded-md border border-background-header-secondary bg-background-tertiary p-4">
						<h3 className="mb-2 font-medium text-lg">{i18n.Messages.DOMAIN_VERIFICATION}</h3>
						<p className="mb-4 text-sm text-text-primary-muted">
							Verify server ownership by connecting your domain through DNS records.
						</p>
						<Button
							variant="brand"
							className="w-full"
							onClick={() => ModalActionCreators.push(() => <GuildDomainModal guild={guild} />)}
						>
							{i18n.Messages.VERIFY_GUILD_WITH_DNS}
						</Button>
					</div>
				</div>
			</section>
		</div>
	);
};

const RoleItemIcon = ({
	icon: Icon,
	label,
	onClick,
	className,
}: {
	icon: Icon;
	label: string;
	onClick?: () => void;
	className?: string;
}) => {
	const handleSelect = (event: React.KeyboardEvent | React.MouseEvent) => {
		event.stopPropagation();
		onClick?.();
	};
	return (
		<Tooltip text={label}>
			<div
				className={clsx(
					'flex h-4 w-4 cursor-pointer items-center justify-center rounded-full transition-colors duration-200',
					'text-text-primary-muted hover:text-text-primary',
					className,
				)}
				aria-label={label}
				onClick={handleSelect}
				onKeyDown={(event) => event.key === 'Enter' && handleSelect(event)}
				role="button"
				tabIndex={0}
			>
				<Icon className="h-4 w-4" />
			</div>
		</Tooltip>
	);
};

const RoleItem = ({
	guild,
	role,
	isSortingList = false,
}: {
	guild: GuildRecord;
	role: GuildRoleRecord;
	isSortingList?: boolean;
}) => {
	const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: role.id});
	return (
		<div className="relative w-full">
			<div
				{...listeners}
				{...attributes}
				aria-label={role.name}
				className="flex items-center gap-2 rounded-md border border-background-header-secondary bg-background-tertiary px-3 py-2 text-base"
				ref={setNodeRef}
				style={{
					transform: CSS.Transform.toString(transform),
					transition,
					opacity: isDragging ? 0.5 : 1,
					cursor: isDragging ? 'grabbing' : undefined,
				}}
			>
				<div
					className="inline-block h-4 w-4 rounded-full"
					style={{backgroundColor: ColorUtils.int2rgb(role.color)}}
					title={role.name}
				/>
				<span className="flex-auto truncate text-[16px] leading-[20px]">{role.name}</span>

				{!isSortingList && (
					<div className="ml-auto flex items-center justify-center gap-1">
						<RoleItemIcon
							icon={Pencil}
							label={i18n.Messages.EDIT_ROLE}
							onClick={() => ModalActionCreators.push(() => <GuildRoleEditModal role={role} />)}
						/>
						{!role.isEveryone && (
							<RoleItemIcon
								icon={Trash}
								label={i18n.Messages.DELETE_ROLE}
								onClick={() =>
									ModalActionCreators.push(() => (
										<ConfirmModal
											title={i18n.Messages.DELETE_ROLE_MODAL_TITLE}
											description={i18n.Messages.DELETE_ROLE_MODAL_DESCRIPTION}
											primaryText={i18n.Messages.DELETE_ROLE_MODAL_PRIMARY_ACTION}
											onPrimary={() => GuildActionCreators.deleteRole(guild.id, role.id)}
										/>
									))
								}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const sortRoles = (roles: Array<GuildRoleRecord>): Array<GuildRoleRecord> => {
	return [...roles].sort((a, b) => a.position - b.position);
};

const SortableRolesList = ({guild}: {guild: GuildRecord}) => {
	const [isDragging, setIsDragging] = React.useState(false);
	const roles = Object.values(guild.roles).filter((role) => !role.isEveryone);
	const everyoneRole = Object.values(guild.roles).find((role) => role.isEveryone)!;
	const sensors = useSensors(useSensor(PointerSensor, {activationConstraint: {distance: 8}}));
	const sortedRoles = React.useMemo(() => sortRoles(roles), [roles]);

	const handleDragEnd = React.useCallback(
		(event: DragEndEvent) => {
			const {active, over} = event;
			if (over && active.id !== over.id) {
				const oldIndex = sortedRoles.findIndex((role) => role.id === active.id);
				const newIndex = sortedRoles.findIndex((role) => role.id === over.id);
				const newArray = arrayMove(sortedRoles.slice(), oldIndex, newIndex);
				GuildActionCreators.sortRoles(
					guild.id,
					newArray.map((role) => role.id),
				);
			}
			setIsDragging(false);
		},
		[guild.id, sortedRoles],
	);

	const renderRole = React.useCallback(
		(role: GuildRoleRecord) => <RoleItem role={role} key={role.id} guild={guild} isSortingList={isDragging} />,
		[guild, isDragging],
	);

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-2">
				<h3 className="font-semibold text-lg">{i18n.Messages.DEFAULT_ROLE}</h3>
				<RoleItem key={everyoneRole.id} role={everyoneRole} guild={guild} />
			</div>

			{sortedRoles.length > 0 && (
				<div className="flex flex-col gap-2">
					<h3 className="font-semibold text-lg">{i18n.Messages.CUSTOM_ROLES}</h3>
					<DndContext
						modifiers={[restrictToVerticalAxis]}
						onDragEnd={handleDragEnd}
						onDragStart={() => setIsDragging(true)}
						sensors={sensors}
					>
						<SortableContext items={sortedRoles.map((role) => role.id)} strategy={verticalListSortingStrategy}>
							{sortedRoles.map((role) => renderRole(role))}
						</SortableContext>
					</DndContext>
				</div>
			)}

			<div className="flex justify-end">
				<Button
					variant="brand"
					onClick={() => {
						GuildActionCreators.createRole(guild.id, 'new role');
						ToastActionCreators.createToast({type: 'success', children: i18n.Messages.ROLE_CREATED});
					}}
				>
					{i18n.Messages.CREATE_NEW_ROLE}
				</Button>
			</div>
		</div>
	);
};

const GuildRolesTab = () => {
	const guild = useGuild();
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-1">
					<h2 className="font-semibold text-xl">{i18n.Messages.ROLES}</h2>
				</div>
			</div>
			<SortableRolesList guild={guild} />
		</div>
	);
};

const EmojiItem = ({emoji}: {emoji: GuildEmojiRecord}) => {
	const handleEditClick = () => {
		ModalActionCreators.push(() => <GuildEmojiEditModal emoji={emoji} />);
	};

	const handleDeleteClick = () => {
		ModalActionCreators.push(() => (
			<ConfirmModal
				title={i18n.Messages.DELETE_EMOJI}
				description={i18n.Messages.CONFIRM_DELETE_EMOJI}
				primaryText={i18n.Messages.DELETE}
				onPrimary={() => GuildEmojiActionCreators.remove(emoji.guildId, emoji.id)}
			/>
		));
	};

	return (
		<div className="flex items-center gap-2 rounded-md border border-background-header-secondary p-2 transition-colors duration-200 ease-in-out hover:bg-background-modifier-hover">
			<img src={`${emoji.url}?size=64`} alt={emoji.name} className="h-8 w-8 rounded-md" />
			<span className="flex-auto truncate">{emoji.name}</span>
			<div className="flex items-center gap-1">
				<Tooltip text={i18n.Messages.EDIT_EMOJI}>
					<div
						className="flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-text-primary-muted hover:text-text-primary"
						aria-label={i18n.Messages.EDIT_EMOJI}
						onClick={handleEditClick}
						onKeyDown={(event) => event.key === 'Enter' && handleEditClick()}
						role="button"
						tabIndex={0}
					>
						<Pencil className="h-4 w-4" />
					</div>
				</Tooltip>
				<Tooltip text={i18n.Messages.DELETE_EMOJI}>
					<div
						className="flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-text-primary-muted hover:text-text-primary"
						aria-label={i18n.Messages.DELETE_EMOJI}
						onClick={handleDeleteClick}
						onKeyDown={(event) => event.key === 'Enter' && handleDeleteClick()}
						role="button"
						tabIndex={0}
					>
						<Trash className="h-4 w-4" />
					</div>
				</Tooltip>
			</div>
		</div>
	);
};

const EmojiSection = ({
	title,
	emojis,
	maxEmojis,
}: {title: string; emojis: Array<GuildEmojiRecord>; maxEmojis: number | string}) => (
	<div className="flex flex-col gap-4">
		<div className="flex flex-col gap-1">
			<h3 className="font-semibold text-lg">
				{title} —{' '}
				{i18n.format(i18n.Messages.N_SLOTS_AVAILABLE, {
					n: typeof maxEmojis === 'string' ? maxEmojis : maxEmojis - emojis.length,
				})}
			</h3>
		</div>
		<div className="flex flex-col gap-2">
			{emojis.length === 0 && (
				<div className="flex items-center gap-2">
					<span>{i18n.Messages.NO_EMOJIS}</span>
				</div>
			)}
			{emojis.map((emoji) => (
				<EmojiItem key={emoji.id} emoji={emoji} />
			))}
		</div>
	</div>
);

const GuildEmojisTab = () => {
	const guild = useGuild();
	const emojis = GuildEmojiStore.useEmojis(guild.id);
	const staticEmojis = emojis.filter((emoji) => !emoji.animated);
	const animatedEmojis = emojis.filter((emoji) => emoji.animated);
	const fileInputRef = React.useRef<HTMLInputElement>(null);
	const [processedCount, setProcessedCount] = React.useState(0);
	const [totalCount, setTotalCount] = React.useState(0);
	const [isUploading, setIsUploading] = React.useState(false);

	const handleButtonClick = () => {
		fileInputRef.current?.click();
	};

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files) {
			await uploadFiles(files);
		}
	};

	const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files) {
			await uploadFiles(files);
		}
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const uploadFiles = async (files: FileList) => {
		setIsUploading(true);
		setTotalCount(files.length);
		setProcessedCount(0);

		const fileArray = Array.from(files);
		for (let i = 0; i < fileArray.length; i++) {
			await uploadFile(fileArray[i]);
			setProcessedCount((prevCount) => prevCount + 1);
		}

		setIsUploading(false);
	};

	const uploadFile = async (file: File) => {
		await GuildEmojiActionCreators.upload(guild.id, file);
	};

	const staticSlotsAvailable = staticEmojis.length < MAX_GUILD_EMOJIS_STATIC;
	const animatedSlotsAvailable = animatedEmojis.length < MAX_GUILD_EMOJIS_ANIMATED;

	const uploadButtonText = isUploading
		? `${processedCount}/${totalCount} ${i18n.Messages.UPLOADING}`
		: i18n.Messages.UPLOAD_EMOJI;

	return (
		<div className="flex flex-col gap-4" onDrop={handleDrop} onDragOver={handleDragOver}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-1">
					<h2 className="font-semibold text-xl">{i18n.Messages.EMOJIS}</h2>
				</div>
			</div>

			<div className="flex justify-start">
				<Button
					variant="brand"
					onClick={handleButtonClick}
					disabled={!(staticSlotsAvailable || animatedSlotsAvailable) || isUploading}
				>
					{uploadButtonText}
				</Button>
				<input
					type="file"
					accept=".jpeg,.jpg,.png,.gif"
					ref={fileInputRef}
					onChange={handleFileChange}
					multiple={true}
					style={{display: 'none'}}
				/>
			</div>

			<div className="my-2 h-px bg-background-header-secondary" />

			<EmojiSection title={i18n.Messages.EMOJIS} emojis={staticEmojis} maxEmojis={MAX_GUILD_EMOJIS_STATIC} />

			<div className="my-2 h-px bg-background-header-secondary" />

			<EmojiSection
				title={i18n.Messages.ANIMATED_EMOJIS}
				emojis={animatedEmojis}
				maxEmojis={MAX_GUILD_EMOJIS_ANIMATED}
			/>
		</div>
	);
};

const GuildStickersTab = () => (
	<div className="flex flex-col gap-4">
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-1">
				<h2 className="font-semibold text-xl">{i18n.Messages.STICKERS}</h2>
			</div>
		</div>
	</div>
);

const GuildWebhooksTab = () => (
	<div className="flex flex-col gap-4">
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-1">
				<h2 className="font-semibold text-xl">{i18n.Messages.WEBHOOKS}</h2>
			</div>
		</div>
	</div>
);

const GuildAuditLogTab = () => (
	<div className="flex flex-col gap-4">
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-1">
				<h2 className="font-semibold text-xl">{i18n.Messages.AUDIT_LOG}</h2>
			</div>
		</div>
	</div>
);

const GuildBansTab = () => (
	<div className="flex flex-col gap-4">
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-1">
				<h2 className="font-semibold text-xl">{i18n.Messages.BANS}</h2>
			</div>
		</div>
	</div>
);

const GuildMembersTab = () => (
	<div className="flex flex-col gap-4">
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-1">
				<h2 className="font-semibold text-xl">{i18n.Messages.MEMBERS}</h2>
			</div>
		</div>
	</div>
);

const GuildInvitesTab = () => (
	<div className="flex flex-col gap-4">
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-1">
				<h2 className="font-semibold text-xl">{i18n.Messages.INVITES}</h2>
			</div>
		</div>
	</div>
);

const SETTINGS_TABS: Array<SettingsTab> = [
	{
		type: 'overview',
		label: i18n.Messages.OVERVIEW,
		component: <GuildOverviewTab />,
	},
	{
		type: 'roles',
		label: i18n.Messages.ROLES,
		component: <GuildRolesTab />,
	},
	{
		type: 'emojis',
		label: i18n.Messages.EMOJIS,
		component: <GuildEmojisTab />,
	},
	{
		type: 'stickers',
		label: i18n.Messages.STICKERS,
		component: <GuildStickersTab />,
	},
	{
		type: 'webhooks',
		label: i18n.Messages.WEBHOOKS,
		component: <GuildWebhooksTab />,
	},
	{
		type: 'audit_log',
		label: i18n.Messages.AUDIT_LOG,
		component: <GuildAuditLogTab />,
	},
	{
		type: 'bans',
		label: i18n.Messages.BANS,
		component: <GuildBansTab />,
	},
	{
		type: 'members',
		label: i18n.Messages.MEMBERS,
		component: <GuildMembersTab />,
	},
	{
		type: 'invites',
		label: i18n.Messages.INVITES,
		component: <GuildInvitesTab />,
	},
];
