import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type State = Readonly<{
	highlightChannelId: string | null;
}>;

const initialState: State = {
	highlightChannelId: null,
};

class AutocompleteStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'HIGHLIGHT_CHANNEL':
				return this.highlightChannel(action);
			case 'HIGHLIGHT_CHANNEL_CLEAR':
				return this.highlightChannelClear();
			default:
				return false;
		}
	}

	useHighlightChannelId(): string | null {
		const {highlightChannelId} = this.useStore();
		return highlightChannelId;
	}

	private highlightChannel({channelId}: {readonly channelId: string}): boolean {
		if (!channelId) {
			return false;
		}

		this.setState((prevState) => {
			if (prevState.highlightChannelId === channelId) {
				return prevState;
			}

			return {highlightChannelId: channelId};
		});

		return true;
	}

	private highlightChannelClear(): boolean {
		this.setState((prevState) => {
			if (prevState.highlightChannelId == null) {
				return prevState;
			}

			return {highlightChannelId: null};
		});

		return true;
	}
}

export default new AutocompleteStore();
