import {File} from '@phosphor-icons/react';
import {AnimatePresence, motion} from 'motion/react';
import React from 'react';
import {MAX_ATTACHMENTS_PER_MESSAGE} from '~/Constants';
import * as AttachmentActionCreators from '~/actions/AttachmentActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {TooManyAttachmentsModal} from '~/components/alerts/TooManyAttachmentsModal';
import {i18n} from '~/i18n';
import type {ChannelRecord} from '~/records/ChannelRecord';
import ModalStore from '~/stores/ModalStore';
import UploadAttachmentStore from '~/stores/UploadAttachmentStore';

export const UploadManager = ({channel}: {channel: ChannelRecord}) => {
	const [isDragging, setIsDragging] = React.useState(false);
	const [dragCounter, setDragCounter] = React.useState(0);

	const onDrop = React.useCallback(
		(files: Array<File>) => {
			const existingAttachments = UploadAttachmentStore.getUploadAttachments(channel.id);
			if (existingAttachments.length + files.length > MAX_ATTACHMENTS_PER_MESSAGE) {
				ModalActionCreators.push(() => <TooManyAttachmentsModal />);
				return;
			}
			AttachmentActionCreators.upload({channelId: channel.id, files: files});
		},
		[channel.id],
	);

	const handlePaste = React.useCallback(
		(event: ClipboardEvent) => {
			const items = event.clipboardData?.items;
			if (!items) {
				return;
			}

			const files: Array<File> = [];
			for (const item of items) {
				if (item.kind === 'file') {
					const file = item.getAsFile();
					if (file) {
						files.push(file);
					}
				}
			}

			if (files.length > 0) {
				onDrop(files);
			}
		},
		[onDrop],
	);

	React.useEffect(() => {
		const handleDragEnter = (event: DragEvent) => {
			event.preventDefault();
			if (ModalStore.hasModalOpen()) {
				return;
			}
			if (event.dataTransfer?.types?.includes('Files')) {
				setDragCounter((prev) => prev + 1);
				setIsDragging(true);
			}
		};

		const handleDragOver = (event: DragEvent) => {
			event.preventDefault();
		};

		const handleDragLeave = (event: DragEvent) => {
			event.preventDefault();
			if (ModalStore.hasModalOpen()) {
				return;
			}
			if (event.dataTransfer?.types?.includes('Files')) {
				setDragCounter((prev) => prev - 1);
			}
		};

		const handleDrop = (event: DragEvent) => {
			event.preventDefault();
			if (ModalStore.hasModalOpen()) {
				return;
			}
			if (event.dataTransfer?.types?.includes('Files')) {
				setIsDragging(false);
				setDragCounter(0);
				if (event.dataTransfer?.files) {
					onDrop(Array.from(event.dataTransfer.files));
				}
			}
		};

		window.addEventListener('dragenter', handleDragEnter);
		window.addEventListener('dragover', handleDragOver);
		window.addEventListener('dragleave', handleDragLeave);
		window.addEventListener('drop', handleDrop);
		window.addEventListener('paste', handlePaste);

		return () => {
			window.removeEventListener('dragenter', handleDragEnter);
			window.removeEventListener('dragover', handleDragOver);
			window.removeEventListener('dragleave', handleDragLeave);
			window.removeEventListener('drop', handleDrop);
			window.removeEventListener('paste', handlePaste);
		};
	}, [onDrop, handlePaste]);

	React.useEffect(() => {
		if (dragCounter === 0) {
			setIsDragging(false);
		}
	}, [dragCounter]);

	if (!isDragging) {
		return null;
	}

	return (
		<AnimatePresence>
			{isDragging && (
				<motion.div
					className="fixed inset-0 z-overlay flex items-center justify-center bg-black bg-opacity-50"
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					exit={{opacity: 0}}
					transition={{duration: 0.3}}
				>
					<div className="flex max-w-sm flex-col items-center justify-center gap-2 rounded-md bg-brand-primary p-4 text-center text-brand-primary-fill">
						<div className="flex flex-col items-center justify-center gap-1">
							<File className="h-24 w-24" />
							<h2 className="font-bold text-2xl">
								{i18n.format(i18n.Messages.UPLOAD_OVERLAY_TITLE, {channelName: `#${channel.name}`})}
							</h2>
						</div>
						<p className="text-lg">{i18n.Messages.UPLOAD_OVERLAY_DESCRIPTION}</p>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
