import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {type GuildEmoji, GuildEmojiRecord} from '~/records/GuildEmojiRecord';
import type {GuildReadyData} from '~/records/GuildRecord';

type GuildEmojis = Readonly<Record<string, GuildEmojiRecord>>;

type State = Readonly<{
	guildEmojis: Readonly<Record<string, GuildEmojis>>;
}>;

const initialState: State = {
	guildEmojis: {},
};

class GuildEmojiStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'GUILD_CREATE':
				return this.handleGuildCreate(action.guild);
			case 'GUILD_DELETE':
				return this.handleGuildDelete(action);
			case 'GUILD_EMOJIS_UPDATE':
				return this.handleEmojisUpdate(action);
			default:
				return false;
		}
	}

	getEmojis(guildId: string): ReadonlyArray<GuildEmojiRecord> {
		return Object.values(this.state.guildEmojis[guildId] ?? {});
	}

	useEmojis(guildId: string): ReadonlyArray<GuildEmojiRecord> {
		const {guildEmojis} = this.useStore();
		return Object.values(guildEmojis[guildId] ?? {});
	}

	getEmoji(guildId: string, emojiId: string): GuildEmojiRecord | null {
		return this.state.guildEmojis[guildId]?.[emojiId] ?? null;
	}

	private createEmojiMap(guildId: string, emojis: ReadonlyArray<GuildEmoji>): GuildEmojis {
		return emojis.reduce<GuildEmojis>(
			(acc, emoji) => ({
				// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
				...acc,
				[emoji.id]: new GuildEmojiRecord(guildId, emoji),
			}),
			{},
		);
	}

	private handleConnectionOpen({guilds}: {guilds: ReadonlyArray<GuildReadyData>}): boolean {
		this.setState(initialState);

		const availableGuilds = guilds.filter((guild) => !guild.unavailable);

		if (availableGuilds.length > 0) {
			this.setState(() => ({
				guildEmojis: availableGuilds.reduce<Record<string, GuildEmojis>>(
					(acc, guild) => ({
						// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
						...acc,
						[guild.id]: this.createEmojiMap(guild.id, guild.emojis),
					}),
					{},
				),
			}));
		}

		return true;
	}

	private handleGuildCreate(guild: GuildReadyData): boolean {
		if (guild.unavailable) {
			return false;
		}

		this.setState((prevState) => ({
			guildEmojis: {
				...prevState.guildEmojis,
				[guild.id]: this.createEmojiMap(guild.id, guild.emojis),
			},
		}));

		return true;
	}

	private handleGuildDelete({guildId}: {guildId: string}): boolean {
		this.setState((prevState) => {
			const {[guildId]: _, ...remainingEmojis} = prevState.guildEmojis;
			return {guildEmojis: remainingEmojis};
		});
		return true;
	}

	private handleEmojisUpdate({
		guildId,
		emojis,
	}: {
		guildId: string;
		emojis: ReadonlyArray<GuildEmoji>;
	}): boolean {
		this.setState((prevState) => ({
			guildEmojis: {
				...prevState.guildEmojis,
				[guildId]: this.createEmojiMap(guildId, emojis),
			},
		}));
		return true;
	}
}

export default new GuildEmojiStore();
