import {Endpoints} from '~/Endpoints';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {EmojiUploadFailedModal} from '~/components/modals/EmojiUploadFailedModal';
import * as HttpClient from '~/lib/HttpClient';
import {type UserPartial, UserRecord} from '~/records/UserRecord';

const VALID_EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif'];

const isValidFileExtension = (file: File): boolean => {
	const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';
	return VALID_EXTENSIONS.includes(fileExtension);
};

const readFileAsBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64str = reader.result?.toString().split(',')[1] ?? '';
			resolve(base64str);
		};
		reader.onerror = () => reject(new Error('File reading failed'));
		reader.readAsDataURL(file);
	});
};

const handleError = (error: Error) => {
	console.error('Unexpected error:', error);
	ModalActionCreators.push(() => <EmojiUploadFailedModal />);
};

export const upload = async (guildId: string, file: File) => {
	try {
		if (!isValidFileExtension(file)) {
			ModalActionCreators.push(() => <EmojiUploadFailedModal />);
			return;
		}

		const base64str = await readFileAsBase64(file);
		let fileName =
			file.name
				.split('.')
				.shift()
				?.replace(/[^a-zA-Z0-9_]/g, '') ?? '';
		fileName = fileName.padEnd(2, '_').slice(0, 32);

		await HttpClient.post({
			url: Endpoints.GUILD_EMOJIS(guildId),
			body: {name: fileName, image: base64str},
		});
	} catch (error) {
		handleError(error as Error);
	}
};

export const update = async (guildId: string, emojiId: string, data: {name: string}) => {
	await HttpClient.patch({url: Endpoints.GUILD_EMOJI(guildId, emojiId), body: data});
};

export const remove = async (guildId: string, emojiId: string) => {
	await HttpClient.del({url: Endpoints.GUILD_EMOJI(guildId, emojiId)});
};

export const getUser = async (guildId: string, emojiId: string) => {
	const {body} = await HttpClient.get<UserPartial>({url: Endpoints.GUILD_EMOJI_USER(guildId, emojiId)});
	return new UserRecord(body);
};
