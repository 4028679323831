import {makeAutoObservable} from 'mobx';
import type {Action} from '~/flux/ActionTypes';
import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';
import type {GuildReadyData} from '~/records/GuildRecord';
import ChannelStore from '~/stores/ChannelStore';

const logger = new Logger('ChannelStateStore');

export type ChannelState = {
	isLoading: boolean;
	hasMoreMessages: boolean;
	failedToLoadMessages: boolean;
	scrollPosition: number;
	hasLoadedFirstPage: boolean;
	hasSeenNewMessages: boolean;
	lastKnownScrollHeight?: number;
};

const defaultChannelState: ChannelState = {
	isLoading: false,
	hasMoreMessages: true,
	failedToLoadMessages: false,
	scrollPosition: 0,
	hasLoadedFirstPage: false,
	hasSeenNewMessages: false,
};

class ChannelStateStore {
	private channels = new Map<string, ChannelState>();

	constructor() {
		makeAutoObservable(this);
		Dispatcher.register(this.handleAction.bind(this));
		ChannelStore.addChangeListener(this.syncWithChannelStore.bind(this));
	}

	getState(channelId: string): ChannelState {
		return this.channels.get(channelId) ?? {...defaultChannelState};
	}

	updateState(channelId: string, updates: Partial<ChannelState>) {
		const currentState = this.getState(channelId);
		const newState = {...currentState, ...updates};

		if (JSON.stringify(currentState) !== JSON.stringify(newState)) {
			this.channels.set(channelId, newState);
		}
	}

	setLoadingState(channelId: string, isLoading: boolean) {
		this.updateState(channelId, {isLoading});
	}

	setScrollPosition(channelId: string, position: number, scrollHeight?: number) {
		this.updateState(channelId, {
			scrollPosition: position,
			lastKnownScrollHeight: scrollHeight,
		});
	}

	setHasSeenNewMessages(channelId: string, value: boolean) {
		this.updateState(channelId, {hasSeenNewMessages: value});
	}

	resetChannel(channelId: string) {
		this.channels.set(channelId, {...defaultChannelState});
	}

	private handleAction(action: Action) {
		try {
			switch (action.type) {
				case 'CONNECTION_OPEN':
					this.handleConnectionOpen(action.guilds);
					break;

				case 'GUILD_CREATE':
					this.handleGuildCreate(action.guild);
					break;

				case 'CHANNEL_DELETE':
					this.channels.delete(action.channel.id);
					break;
			}
		} catch (error) {
			logger.error('Error handling action:', error);
		}
	}

	private handleConnectionOpen(guilds: ReadonlyArray<GuildReadyData>) {
		this.channels.clear();

		// biome-ignore lint/complexity/noForEach: <explanation>
		guilds
			.filter((guild) => !guild.unavailable)
			.flatMap((guild) => guild.channels)
			.forEach((channel) => {
				this.channels.set(channel.id, {...defaultChannelState});
			});
	}

	private handleGuildCreate(guild: GuildReadyData) {
		if (guild.unavailable) return;

		// biome-ignore lint/complexity/noForEach: <explanation>
		guild.channels.forEach((channel) => {
			if (!this.channels.has(channel.id)) {
				this.channels.set(channel.id, {...defaultChannelState});
			}
		});
	}

	private syncWithChannelStore() {
		const allChannels = ChannelStore.getChannels();

		for (const channel of allChannels.values()) {
			if (!this.channels.has(channel.id)) {
				this.channels.set(channel.id, {...defaultChannelState});
			}
		}

		const validIds = new Set(allChannels.map((channel) => channel.id));
		for (const channelId of this.channels.keys()) {
			if (!validIds.has(channelId)) {
				this.channels.delete(channelId);
			}
		}
	}

	useChannelState(channelId: string): ChannelState {
		return this.getState(channelId);
	}
}

export default new ChannelStateStore();
