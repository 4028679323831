import {clsx} from 'clsx';
import {Typing} from '~/components/channel/Typing';
import {Avatar} from '~/components/uikit/Avatar';
import {i18n} from '~/i18n';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {UserRecord} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import GuildMemberStore from '~/stores/GuildMemberStore';
import TypingStore from '~/stores/TypingStore';
import UserStore from '~/stores/UserStore';
import * as NicknameUtils from '~/utils/NicknameUtils';

const getDisplayName = (user: UserRecord, guildId?: string | null) => NicknameUtils.getNickname(user, guildId);

export const getTypingText = (typingUsers: Array<UserRecord>, channel: ChannelRecord) => {
	const [a, b, c] = typingUsers.map((user) => {
		const member = GuildMemberStore.getMember(channel.guildId, user.id);
		return (
			<strong key={user.id} style={{color: member?.getHighestRoleColor()}}>
				{getDisplayName(user, channel.guildId)}
			</strong>
		);
	});

	if (typingUsers.length === 1) {
		return i18n.format(i18n.Messages.TYPING_USERS_1, {a});
	}

	if (typingUsers.length === 2) {
		return i18n.format(i18n.Messages.TYPING_USERS_2, {a, b});
	}

	if (typingUsers.length === 3) {
		return i18n.format(i18n.Messages.TYPING_USERS_3, {a, b, c});
	}

	if (typingUsers.length === 4) {
		return i18n.Messages.TYPING_USERS_4;
	}

	if (typingUsers.length > 4 && typingUsers.length < 10) {
		return i18n.Messages.TYPING_USERS_5;
	}

	if (typingUsers.length > 9 && typingUsers.length < 15) {
		return i18n.Messages.TYPING_USERS_10;
	}

	if (typingUsers.length > 14 && typingUsers.length < 20) {
		return i18n.Messages.TYPING_USERS_15;
	}

	return i18n.Messages.TYPING_USERS_20;
};

export const usePresentableTypingUsers = (channel: ChannelRecord) => {
	const typingUserIds = TypingStore.useTypingUsers(channel.id);
	return [...typingUserIds]
		.filter((userId) => DeveloperOptionsStore.getShowMyselfTyping() || userId !== AuthenticationStore.getId())
		.map((userId) => UserStore.getUser(userId))
		.filter((user): user is UserRecord => user != null);
};

const AVATAR_THRESHOLD = 5;

export const TypingUsers = ({channel, withText = true}: {channel: ChannelRecord; withText?: boolean}) => {
	const typingUsers = usePresentableTypingUsers(channel);
	if (typingUsers.length === 0) {
		return null;
	}

	return (
		<div className="flex items-center overflow-hidden overflow-ellipsis">
			<Typing className="mr-1" />
			<div className="flex">
				{typingUsers.slice(0, AVATAR_THRESHOLD).map((user, index) => (
					<Avatar key={user.id} user={user} size={16} className={clsx(index > 0 && '-ml-1')} />
				))}
				{typingUsers.length > AVATAR_THRESHOLD && (
					<div
						aria-hidden={true}
						className="-ml-1 flex h-6 w-6 items-center justify-center rounded-full bg-background-header-secondary font-semibold text-background-header-primary text-xs"
					>
						+{typingUsers.length - AVATAR_THRESHOLD}
					</div>
				)}
			</div>
			{withText && (
				<span
					aria-atomic={true}
					aria-live="polite"
					className="ml-1 block min-w-0 overflow-hidden overflow-ellipsis whitespace-nowrap"
				>
					{getTypingText(typingUsers, channel)}
				</span>
			)}
		</div>
	);
};
