import {Outlet, useLocation} from '@tanstack/react-router';
import {AnimatePresence, motion} from 'motion/react';

const INITIAL_ANIMATION_STATE = {opacity: 0, y: 100};
const ANIMATE_ANIMATION_STATE = {opacity: 1, y: 0};
const EXIT_ANIMATION_STATE = {opacity: 0, y: -100};
const TRANSITION_ANIMATION_STATE = {
	type: 'spring',
	damping: 100,
	stiffness: 1000,
};

export const AuthLayout = () => {
	const location = useLocation();
	return (
		<AnimatePresence mode="wait">
			<motion.div
				key={location.pathname}
				animate={ANIMATE_ANIMATION_STATE}
				exit={EXIT_ANIMATION_STATE}
				initial={INITIAL_ANIMATION_STATE}
				transition={TRANSITION_ANIMATION_STATE}
			>
				<Outlet />
			</motion.div>
		</AnimatePresence>
	);
};
