import {ArrowLeft, House, Link, Plus} from '@phosphor-icons/react';
import {AnimatePresence, motion} from 'motion/react';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as InviteActionCreators from '~/actions/InviteActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as FormUtils from '~/utils/FormUtils';
import * as InviteUtils from '~/utils/InviteUtils';
import * as RouterUtils from '~/utils/RouterUtils';

const slideVariants = {
	enter: (direction: number) => ({
		x: direction > 0 ? 300 : -300,
		opacity: 0,
	}),
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction: number) => ({
		zIndex: 0,
		x: direction < 0 ? 300 : -300,
		opacity: 0,
	}),
};

const ActionButton = ({
	onClick,
	icon,
	label,
}: {
	onClick: () => void;
	icon: React.ReactNode;
	label: string;
}) => (
	<button
		type="button"
		className="translate flex flex-1 transform-gpu cursor-pointer flex-row items-center justify-center gap-2 rounded-md bg-brand-primary px-2 py-4 text-brand-primary-fill transition duration-300 ease-in-out hover:bg-brand-secondary active:translate-y-px"
		onClick={onClick}
	>
		{icon}
		<span className="font-semibold text-lg">{label}</span>
	</button>
);

type GuildCreateFormInputs = {
	icon?: string | null;
	name: string;
};

type GuildJoinFormInputs = {
	code: string;
};

export type AddGuildModalViews = 'landing' | 'create_guild' | 'join_guild';

export const AddGuildModal = () => {
	const [view, setView] = useState<AddGuildModalViews>('landing');
	const [direction, setDirection] = useState(0);

	const handleViewChange = (newView: AddGuildModalViews, newDirection = 1) => {
		setDirection(newDirection);
		setView(newView);
	};

	return (
		<Modal.Root label={i18n.Messages.ADD_A_GUILD} size="small">
			<div className="flex h-full w-full flex-col bg-background-secondary">
				<div className="relative flex items-center gap-4 border-background-header-secondary border-b px-4 py-3">
					{view !== 'landing' && (
						<Button
							variant="ghost"
							small
							fitContent
							className="absolute flex items-center"
							onClick={() => handleViewChange('landing', -1)}
							aria-label="Go back"
						>
							<ArrowLeft weight="regular" className="h-4 w-4" />
							<span className="ml-1">{i18n.Messages.BACK}</span>
						</Button>
					)}
					<h2 className="mx-auto font-semibold text-lg text-text-primary">
						{view === 'landing'
							? i18n.Messages.ADD_A_GUILD
							: view === 'create_guild'
								? i18n.Messages.CREATE_GUILD_MODAL_TITLE
								: i18n.Messages.JOIN_GUILD_MODAL_TITLE}
					</h2>
				</div>

				<div className="min-h-0 flex-1">
					<div className="relative flex-1 overflow-hidden">
						<AnimatePresence initial={false} custom={direction} mode="wait">
							{view === 'landing' && (
								<LandingView key="landing" direction={direction} onViewChange={handleViewChange} />
							)}
							{view === 'create_guild' && <GuildCreateForm key="create" direction={direction} />}
							{view === 'join_guild' && <GuildJoinForm key="join" direction={direction} />}
						</AnimatePresence>
					</div>
				</div>
			</div>
		</Modal.Root>
	);
};

const LandingView = ({
	direction,
	onViewChange,
}: {
	direction: number;
	onViewChange: (view: AddGuildModalViews, direction?: number) => void;
}) => (
	<motion.div
		custom={direction}
		variants={slideVariants}
		initial="enter"
		animate="center"
		exit="exit"
		transition={{
			x: {type: 'spring', stiffness: 300, damping: 30},
			opacity: {duration: 0.2},
		}}
		className="w-full overflow-auto"
	>
		<div className="space-y-6 p-6">
			<p>{i18n.Messages.ADD_A_GUILD_DESCRIPTION}</p>

			<div className="flex w-full flex-col gap-3">
				<ActionButton
					onClick={() => onViewChange('create_guild')}
					icon={<House className="h-6 w-6" />}
					label={i18n.Messages.CREATE_GUILD}
				/>
				<ActionButton
					onClick={() => onViewChange('join_guild')}
					icon={<Link className="h-6 w-6" weight="regular" />}
					label={i18n.Messages.JOIN_GUILD}
				/>
			</div>
		</div>
	</motion.div>
);

const GuildCreateForm = ({direction}: {direction: number}) => {
	const [previewIconUrl, setPreviewIconUrl] = React.useState<string | null>(null);
	const form = useForm<GuildCreateFormInputs>({defaultValues: {name: ''}});
	const inputRef = React.useRef<HTMLInputElement>(null);

	const handleFileChange = React.useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			try {
				const file = event.target.files?.[0];
				if (!file) {
					return;
				}
				const base64 = await AvatarUtils.fileToBase64(file);
				event.target.value = '';
				form.setValue('icon', base64);
				setPreviewIconUrl(base64);
			} catch {
				form.setError('icon', {message: i18n.Messages.INVALID_IMAGE});
			}
		},
		[form],
	);

	const onSubmit = async (data: GuildCreateFormInputs) => {
		try {
			const guild = await GuildActionCreators.create({
				icon: data.icon,
				name: data.name,
			});
			ModalActionCreators.pop();
			RouterUtils.transitionTo(`/channels/${guild.id}/${guild.id}`);
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'name');
		}
	};

	return (
		<Form form={form} onSubmit={onSubmit}>
			<motion.div
				custom={direction}
				variants={slideVariants}
				initial="enter"
				animate="center"
				exit="exit"
				transition={{
					x: {type: 'spring', stiffness: 300, damping: 30},
					opacity: {duration: 0.2},
				}}
				className="w-full overflow-auto"
			>
				<div className="space-y-6 p-6">
					<p>{i18n.Messages.CREATE_GUILD_MODAL_DESCRIPTION}</p>

					<div className="mt-2 flex flex-col items-center gap-4">
						<div
							className="active:translate-z-0 relative inline-block h-20 w-20 transform-gpu cursor-pointer rounded-full bg-center bg-cover active:translate-y-px"
							style={{
								backgroundImage: previewIconUrl ? `url(${previewIconUrl})` : undefined,
							}}
						>
							{!previewIconUrl && (
								<div className="absolute inset-0 flex items-center justify-center rounded-full border-4 border-text-tertiary border-dashed">
									<Plus weight="regular" className="h-8 w-8 text-text-tertiary" />
								</div>
							)}
							<input
								accept="image/jpeg,image/png,image/gif,image/webp"
								onChange={handleFileChange}
								ref={inputRef}
								style={{display: 'none'}}
								type="file"
							/>
							<div
								aria-label={i18n.Messages.CHANGE_ICON}
								className="absolute inset-0 flex items-center justify-center"
								onClick={() => inputRef.current?.click()}
								onKeyDown={(event) => event.key === 'Enter' && inputRef.current?.click()}
								role="button"
								tabIndex={0}
							/>
						</div>

						<div className="flex flex-col items-center gap-1 text-center text-sm text-text-primary-muted">
							<p>{i18n.Messages.SUPPORTED_FORMATS}</p>
							<p>{i18n.Messages.RECOMMENDED_SIZE}</p>
						</div>

						{form.formState.errors.icon?.message && (
							<p className="text-sm text-text-danger">{form.formState.errors.icon.message}</p>
						)}
					</div>

					<Input
						{...form.register('name')}
						autoFocus={true}
						error={form.formState.errors.name?.message}
						label={i18n.Messages.NAME}
						minLength={1}
						maxLength={100}
						name="name"
						placeholder={i18n.Messages.GUILD_NAME_PLACEHOLDER}
						required={true}
						type="text"
					/>

					<div className="flex justify-end">
						<Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
							{i18n.Messages.CREATE_GUILD}
						</Button>
					</div>
				</div>
			</motion.div>
		</Form>
	);
};

const GuildJoinForm = ({direction}: {direction: number}) => {
	const form = useForm<GuildJoinFormInputs>({defaultValues: {code: ''}});

	const onSubmit = async (data: GuildJoinFormInputs) => {
		try {
			const parsedCode = InviteUtils.findInvite(data.code) ?? data.code;
			const invite = await InviteActionCreators.fetch(parsedCode);
			await InviteActionCreators.acceptAndTransitionToChannel(invite.code);
			ModalActionCreators.pop();
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'code');
		}
	};

	return (
		<Form form={form} onSubmit={onSubmit}>
			<motion.div
				custom={direction}
				variants={slideVariants}
				initial="enter"
				animate="center"
				exit="exit"
				transition={{
					x: {type: 'spring', stiffness: 300, damping: 30},
					opacity: {duration: 0.2},
				}}
				className="w-full overflow-auto"
			>
				<div className="space-y-6 p-6">
					<p>{i18n.Messages.JOIN_GUILD_MODAL_DESCRIPTION}</p>

					<Input
						{...form.register('code')}
						autoFocus={true}
						error={form.formState.errors.code?.message}
						label={i18n.Messages.INVITE_LINK}
						minLength={1}
						maxLength={100}
						name="code"
						placeholder={`${location.protocol}//${location.host}/invite/${i18n.Messages.INVITE_LINK_PLACEHOLDER}`}
						required={true}
						type="text"
					/>

					<div className="flex justify-end">
						<Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
							{i18n.Messages.JOIN_GUILD}
						</Button>
					</div>
				</div>
			</motion.div>
		</Form>
	);
};
