import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import type {Message} from '~/records/MessageRecord';

export const fetch = async () => {
	Dispatcher.dispatch({type: 'RECENT_MENTIONS_FETCH_PENDING'});
	try {
		const {body} = await HttpClient.get<Array<Message>>({url: Endpoints.USER_MENTIONS});
		Dispatcher.dispatch({
			type: 'RECENT_MENTIONS_FETCH_SUCCESS',
			messages: body,
		});
	} catch (error) {
		Dispatcher.dispatch({type: 'RECENT_MENTIONS_FETCH_ERROR', error});
		throw error;
	}
};

export const remove = async (messageId: string) => {
	Dispatcher.dispatch({type: 'RECENT_MENTION_DELETE', messageId});
	await HttpClient.del({url: Endpoints.USER_MENTION(messageId)});
};
