import React from 'react';
import {useForm} from 'react-hook-form';
import * as GuildEmojiActionCreators from '~/actions/GuildEmojiActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {Avatar} from '~/components/uikit/Avatar';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {GuildEmojiRecord} from '~/records/GuildEmojiRecord';
import type {UserRecord} from '~/records/UserRecord';

type FormInputs = {
	name: string;
};

export const GuildEmojiEditModal = ({emoji}: {emoji: GuildEmojiRecord}) => {
	const form = useForm<FormInputs>({defaultValues: {name: emoji.name}});
	const title = i18n.format(i18n.Messages.EDIT_EMOJI_MODAL_TITLE, {emojiName: emoji.name});
	const [emojiUser, setEmojiUser] = React.useState<UserRecord | null>(null);

	React.useEffect(() => {
		GuildEmojiActionCreators.getUser(emoji.guildId, emoji.id).then(setEmojiUser);
	}, [emoji.guildId, emoji.id]);

	const onSubmit = async (data: FormInputs) => {
		GuildEmojiActionCreators.update(emoji.guildId, emoji.id, data);
		ModalActionCreators.pop();
		ToastActionCreators.createToast({type: 'success', children: i18n.Messages.EMOJI_UPDATED});
	};

	return (
		<Form form={form} onSubmit={onSubmit}>
			<Modal.Root label={title} size="small">
				<Modal.Header title={title} />
				<Modal.Content className={styles.content}>
					<div className="flex flex-col gap-3">
						<div className="flex flex-col gap-1">
							<div className="font-semibold text-sm text-text-tertiary">{i18n.Messages.UPLOADED_BY}</div>
							<div className="flex gap-1">
								{emojiUser ? (
									<>
										<Avatar user={emojiUser} size={24} />
										<div className="flex flex-auto flex-row flex-nowrap items-center justify-start gap-1 truncate">
											<span className="truncate text-base text-text-chat">{emojiUser.displayName}</span>
											<span className="text-sm text-text-chat-muted">{emojiUser.handle}</span>
										</div>
									</>
								) : (
									<EmojiUserSkeleton />
								)}
							</div>
						</div>

						<Input
							{...form.register('name')}
							autoFocus={true}
							label={i18n.Messages.NAME}
							minLength={2}
							maxLength={32}
							required={true}
							type="text"
						/>
					</div>
				</Modal.Content>
				<Modal.Footer>
					<div className={styles.footer}>
						<Button onClick={ModalActionCreators.pop} variant="ghost">
							{i18n.Messages.CANCEL}
						</Button>
						<Button type="submit" variant="brand">
							{i18n.Messages.SAVE}
						</Button>
					</div>
				</Modal.Footer>
			</Modal.Root>
		</Form>
	);
};

const EmojiUserSkeleton = () => (
	<div className="flex gap-1">
		<div className="h-6 w-6 animate-pulse rounded-full bg-background-header-secondary" />
		<div className="flex flex-auto flex-row flex-nowrap items-center justify-start gap-1 truncate">
			<span className="h-4 w-1/2 animate-pulse rounded-md bg-background-header-secondary" />
			<span className="h-4 w-1/4 animate-pulse rounded-md bg-background-header-secondary" />
		</div>
	</div>
);
