import {ME} from '~/Constants';

export const Endpoints = {
	AUTH_LOGIN: '/auth/login',
	AUTH_LOGIN_MFA_TOTP: '/auth/login/mfa/totp',
	AUTH_LOGOUT: '/auth/logout',
	AUTH_REGISTER: '/auth/register',
	AUTH_SESSIONS: '/auth/sessions',
	AUTH_SESSIONS_LOGOUT: '/auth/sessions/logout',

	ATTACHMENT: (uploadFilename: string) => `/attachments/${encodeURIComponent(uploadFilename)}`,

	CHANNEL: (channelId: string) => `/channels/${channelId}`,
	CHANNEL_ATTACHMENTS: (channelId: string) => `/channels/${channelId}/attachments`,
	CHANNEL_INVITES: (channelId: string) => `/channels/${channelId}/invites`,
	CHANNEL_MESSAGES: (channelId: string) => `/channels/${channelId}/messages`,
	CHANNEL_MESSAGE: (channelId: string, messageId: string) => `/channels/${channelId}/messages/${messageId}`,
	CHANNEL_MESSAGE_ACK: (channelId: string, messageId: string) => `/channels/${channelId}/messages/${messageId}/ack`,
	CHANNEL_MESSAGE_REACTION: (channelId: string, messageId: string, emoji: string) =>
		`/channels/${channelId}/messages/${messageId}/reactions/${emoji}`,
	CHANNEL_MESSAGE_REACTION_QUERY: (channelId: string, messageId: string, emoji: string, query = ME) =>
		`/channels/${channelId}/messages/${messageId}/reactions/${emoji}/${query}`,
	CHANNEL_MESSAGE_REACTIONS: (channelId: string, messageId: string) =>
		`/channels/${channelId}/messages/${messageId}/reactions`,
	CHANNEL_MESSAGES_ACK: (channelId: string) => `/channels/${channelId}/messages/ack`,
	CHANNEL_PIN: (channelId: string, messageId: string) => `/channels/${channelId}/pins/${messageId}`,
	CHANNEL_PINS: (channelId: string) => `/channels/${channelId}/pins`,
	CHANNEL_PINS_ACK: (channelId: string) => `/channels/${channelId}/pins/ack`,
	CHANNEL_TYPING: (channelId: string) => `/channels/${channelId}/typing`,
	CHANNEL_WEBHOOKS: (channelId: string) => `/channels/${channelId}/webhooks`,

	GUILDS: '/guilds',
	GUILD: (guildId: string) => `/guilds/${guildId}`,
	GUILD_CHANNELS: (guildId: string) => `/guilds/${guildId}/channels`,
	GUILD_MEMBER: (guildId: string, query = ME) => `/guilds/${guildId}/members/${query}`,
	GUILD_MEMBERS: (guildId: string) => `/guilds/${guildId}/members`,
	GUILD_MEMBER_ROLE: (guildId: string, userId: string, roleId: string) =>
		`/guilds/${guildId}/members/${userId}/roles/${roleId}`,
	GUILD_BAN: (guildId: string, userId: string) => `/guilds/${guildId}/bans/${userId}`,
	GUILD_BANS: (guildId: string) => `/guilds/${guildId}/bans`,
	GUILD_ROLE: (guildId: string, roleId: string) => `/guilds/${guildId}/roles/${roleId}`,
	GUILD_ROLES: (guildId: string) => `/guilds/${guildId}/roles`,
	GUILD_DELETE: (guildId: string) => `/guilds/${guildId}/delete`,
	GUILD_EMOJI: (guildId: string, emojiId: string) => `/guilds/${guildId}/emojis/${emojiId}`,
	GUILD_EMOJI_USER: (guildId: string, emojiId: string) => `/guilds/${guildId}/emojis/${emojiId}/user`,
	GUILD_EMOJIS: (guildId: string) => `/guilds/${guildId}/emojis`,
	GUILD_INVITES: (guildId: string) => `/guilds/${guildId}/invites`,
	GUILD_VANITY_URL: (guildId: string) => `/guilds/${guildId}/vanity-url`,
	GUILD_WEBHOOKS: (guildId: string) => `/guilds/${guildId}/webhooks`,
	GUILD_DOMAIN_VERIFY: (guildId: string) => `/guilds/${guildId}/domain-verify`,

	INVITE: (code: string) => `/invites/${code}`,

	READ_STATES_ACK_BULK: '/read-states/ack-bulk',

	TENOR_FEATURED: '/tenor/featured',
	TENOR_REGISTER_SHARE: '/tenor/register-share',
	TENOR_SEARCH: '/tenor/search',
	TENOR_SUGGEST: '/tenor/suggest',
	TENOR_TRENDING_GIFS: '/tenor/trending-gifs',

	USER_BETA_CODE: (code: string) => `/users/@me/beta-codes/${code}`,
	USER_BETA_CODES: '/users/@me/beta-codes',
	USER_ME: '/users/@me',
	USER_MENTION: (messageId: string) => `/users/@me/mentions/${messageId}`,
	USER_MENTIONS: '/users/@me/mentions',
	USER_MFA_BACKUP_CODES: '/users/@me/mfa/backup-codes',
	USER_MFA_TOTP_DISABLE: '/users/@me/mfa/totp/disable',
	USER_MFA_TOTP_ENABLE: '/users/@me/mfa/totp/enable',
	USER_NOTE: (userId: string) => `/users/@me/notes/${userId}`,
	USER_PROFILE: (query = ME) => `/users/${query}/profile`,
	USER_SAVED_MESSAGE: (messageId: string) => `/users/@me/saved-messages/${messageId}`,
	USER_SAVED_MESSAGES: '/users/@me/saved-messages',
	USER_SETTINGS: '/users/@me/settings',
	USER_GUILDS: (guildId: string) => `/users/@me/guilds/${guildId}`,
	USER_DOMAIN_VERIFY: '/users/@me/domain-verify',
	USER_AVATAR_DECORATIONS: '/users/@me/avatar-decorations',
	USER_AVATAR_DECORATION: (avatarDecorationId: string) => `/users/@me/avatar-decorations/${avatarDecorationId}`,

	WEBHOOK: (webhookId: string) => `/webhooks/${webhookId}`,
} as const;
