import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';

type State = Readonly<{
	hideMfaNagbar: boolean;
}>;

const initialState: State = {
	hideMfaNagbar: false,
};

class NagbarStore extends PersistedStore<State> {
	constructor() {
		super(initialState, 'NagbarStore', 1);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'HIDE_MFA_NAGBAR':
				return this.handleHideMfaNagbar();
			default:
				return false;
		}
	}

	shouldShowMfaNagbar(): boolean {
		return !this.state.hideMfaNagbar;
	}

	private handleHideMfaNagbar(): boolean {
		this.setState((prevState) => ({
			...prevState,
			hideMfaNagbar: true,
		}));
		return true;
	}
}

export default new NagbarStore();
