import {Gif, type Icon, PaperPlaneRight, PlusCircle, Smiley, Sticker} from '@phosphor-icons/react';
import clsx from 'clsx';
import {matchSorter} from 'match-sorter';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {
	ChannelTypes,
	GuildRoleFlags,
	MAX_ATTACHMENTS_PER_MESSAGE,
	MessageFlags,
	MessageStates,
	MessageTypes,
	Permissions,
} from '~/Constants';
import * as AttachmentActionCreators from '~/actions/AttachmentActionCreators';
import * as ExpressionPickerActionCreators from '~/actions/ExpressionPickerActionCreators';
import * as MessageActionCreators from '~/actions/MessageActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import type {TenorGif} from '~/actions/TenorActionCreators';
import {TooManyAttachmentsModal} from '~/components/alerts/TooManyAttachmentsModal';
import {
	Autocomplete,
	type AutocompleteOption,
	type AutocompleteType,
	COMMANDS,
	isChannel,
	isCommand,
	isEmoji,
	isMentionMember,
	isMentionRole,
	isSpecialMention,
} from '~/components/channel/Autocomplete';
import {ChannelAttachmentArea} from '~/components/channel/ChannelAttachmentArea';
import {ReplyBar} from '~/components/channel/ReplyBar';
import {TypingUsers} from '~/components/channel/TypingUsers';
import {ExpressionPickerPopout} from '~/components/popouts/ExpressionPickerPopout';
import {Popout, openPopout} from '~/components/uikit/Popout/Popout';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {GuildRecord} from '~/records/GuildRecord';
import {MessageRecord} from '~/records/MessageRecord';
import ChannelStore from '~/stores/ChannelStore';
import DraftStore from '~/stores/DraftStore';
import EmojiStore, {type Emoji} from '~/stores/EmojiStore';
import ExpressionPickerStore, {type ExpressionPickerTabType} from '~/stores/ExpressionPickerStore';
import GuildMemberStore from '~/stores/GuildMemberStore';
import GuildStore from '~/stores/GuildStore';
import MessageEditStore from '~/stores/MessageEditStore';
import MessageReplyStore from '~/stores/MessageReplyStore';
import MessageStore from '~/stores/MessageStore';
import MobileLayoutStore from '~/stores/MobileLayoutStore';
import ModalStore from '~/stores/ModalStore';
import PopoutStore from '~/stores/PopoutStore';
import UploadAttachmentStore from '~/stores/UploadAttachmentStore';
import UserStore from '~/stores/UserStore';
import * as PermissionUtils from '~/utils/PermissionUtils';
import * as SnowflakeUtils from '~/utils/SnowflakeUtils';
import {TypingUtils} from '~/utils/TypingUtils';

const MENTION_REGEX = /(^|\s)@(\S*)$/;
const CHANNEL_REGEX = /(^|\s)#(\S*)$/;
const EMOJI_REGEX = /(^|\s):([^\s]{2,})$/;
const COMMAND_REGEX = /(^|\s)\/$/;
const REPLACE_REGEX = /^s\/([^\/]+)\/([^\/]+)(\/g)?$/;

type ChannelTextareaButtonProps = React.HTMLProps<HTMLButtonElement> & {
	className?: string;
	disabled?: boolean;
	icon: Icon;
	isSelected?: boolean;
	label: string;
	onClick?: () => void;
};

const ChannelTextareaButton = React.forwardRef<HTMLButtonElement, ChannelTextareaButtonProps>(
	({icon: Icon, label, onClick, disabled, isSelected, className, ...props}, ref) => {
		return (
			<div className="sticky flex-none self-stretch">
				<button
					{...props}
					ref={ref}
					type="button"
					aria-label={label}
					disabled={disabled}
					onClick={onClick}
					className={clsx(
						'flex h-[44px] w-auto cursor-pointer items-center justify-center transition-colors duration-200',
						isSelected ? 'text-text-primary' : 'text-text-primary-muted hover:text-text-primary',
						disabled ? 'cursor-not-allowed opacity-70' : 'hover:text-text-primary',
						className,
					)}
				>
					<Icon className="h-6 w-6" />
				</button>
			</div>
		);
	},
);

const ChannelTextareaContent = ({
	channel,
	draft,
	disabled,
}: {
	channel: ChannelRecord;
	draft: string | null;
	disabled: boolean;
}) => {
	const [autocompleteOptions, setAutocompleteOptions] = React.useState<Array<AutocompleteOption>>([]);
	const [autocompleteType, setAutocompleteType] = React.useState<AutocompleteType>('mention');
	const [expressionPickerOpen, setExpressionPickerOpen] = React.useState(false);
	const [isFocused, setIsFocused] = React.useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [value, setValue] = React.useState('');
	const [valueUpToCursor, setValueUpToCursor] = React.useState('');
	const [wasEditing, setWasEditing] = React.useState(false);

	const editingMessageId = MessageEditStore.useEditingMessageId(channel.id);
	const mobileLayout = MobileLayoutStore.useStore();
	const replyingMessage = MessageReplyStore.useReplyingMessage(channel.id);
	const referencedMessage = replyingMessage ? MessageStore.getMessage(channel.id, replyingMessage.messageId) : null;
	const uploadAttachments = UploadAttachmentStore.useUploadAttachments(channel.id);

	const textareaRef = React.useRef<HTMLTextAreaElement>(null);
	const expressionPickerTriggerRef = React.useRef<HTMLButtonElement>(null);
	const fileInputRef = React.useRef<HTMLInputElement>(null);

	const handleFileButtonClick = () => {
		fileInputRef.current?.click();
	};

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files ?? [];
		if (files.length === 0) {
			return;
		}
		if (uploadAttachments.length + files.length > MAX_ATTACHMENTS_PER_MESSAGE) {
			ModalActionCreators.push(() => <TooManyAttachmentsModal />);
			return;
		}
		AttachmentActionCreators.upload({channelId: channel.id, files: Array.from(files)});
	};

	const mentionMatch = React.useMemo(() => valueUpToCursor.match(MENTION_REGEX), [valueUpToCursor]);
	const channelMatch = React.useMemo(() => valueUpToCursor.match(CHANNEL_REGEX), [valueUpToCursor]);
	const emojiMatch = React.useMemo(() => valueUpToCursor.match(EMOJI_REGEX), [valueUpToCursor]);
	const commandMatch = React.useMemo(() => valueUpToCursor.match(COMMAND_REGEX), [valueUpToCursor]);

	const hasOpenCodeBlock = React.useCallback(() => {
		const textarea = textareaRef.current;
		const match = textarea?.value.slice(0, textarea.selectionStart).match(/```/g);
		return match != null && match.length > 0 && match.length % 2 !== 0;
	}, []);

	React.useEffect(() => {
		const handleEmojiSelect = ({emoji}: {emoji: Emoji}) => {
			setValue((prevValue) => `${prevValue}${prevValue.length === 0 ? '' : ' '}${EmojiStore.getEmojiMarkdown(emoji)} `);
			textareaRef.current?.focus();
		};

		return ComponentDispatch.subscribe('EMOJI_SELECT', handleEmojiSelect);
	}, []);

	React.useEffect(() => {
		const handleGifSelect = ({gif}: {gif: TenorGif}) => {
			setValue((prevValue) => `${prevValue}${prevValue.length === 0 ? '' : ' '}${gif.url} `);
			textareaRef.current?.focus();
		};

		return ComponentDispatch.subscribe('GIF_SELECT', handleGifSelect);
	}, []);

	const {selectedTab} = ExpressionPickerStore.useStore();

	const handleExpressionPickerTabToggle = React.useCallback(
		(tab: ExpressionPickerTabType) => {
			if (expressionPickerOpen) {
				if (selectedTab === tab) {
					ExpressionPickerActionCreators.close();
				} else {
					ExpressionPickerActionCreators.selectTab(tab);
				}
			} else if (expressionPickerTriggerRef.current) {
				ExpressionPickerActionCreators.selectTab(tab);
				openPopout(
					expressionPickerTriggerRef.current,
					{
						render: () => <ExpressionPickerPopout channelId={channel.id} />,
						position: 'top-end',
						animationType: 'none',
						offsetCrossAxis: 16,
						onOpen: () => setExpressionPickerOpen(true),
						onClose: () => setExpressionPickerOpen(false),
						returnFocusRef: textareaRef,
					},
					'expression-picker',
				);
			}
		},
		[channel.id, expressionPickerOpen, selectedTab],
	);

	React.useEffect(() => {
		const handleKeydown = (event: KeyboardEvent) => {
			if (event.metaKey) {
				switch (event.key) {
					case 'g': {
						handleExpressionPickerTabToggle('gifs');
						event.preventDefault();
						break;
					}
					case 's': {
						handleExpressionPickerTabToggle('stickers');
						event.preventDefault();
						break;
					}
					case 'e': {
						handleExpressionPickerTabToggle('emojis');
						event.preventDefault();
						break;
					}
					default:
						break;
				}
			}
		};

		document.addEventListener('keydown', handleKeydown);
		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	}, [handleExpressionPickerTabToggle]);

	const isAutocompleteAttached =
		!!(mentionMatch || channelMatch || emojiMatch || commandMatch) && autocompleteOptions.length > 0;

	const matchedText = mentionMatch
		? mentionMatch[2]
		: channelMatch
			? channelMatch[2]
			: emojiMatch
				? emojiMatch[2]
				: commandMatch
					? commandMatch[2]
					: null;

	React.useEffect(() => {
		if (isAutocompleteAttached) {
			if (channelMatch) {
				const firstChannel = autocompleteOptions.find(isChannel);
				if (firstChannel) {
					Dispatcher.dispatch({type: 'HIGHLIGHT_CHANNEL', channelId: firstChannel.channel.id});
				}
			}
		} else {
			Dispatcher.dispatch({type: 'HIGHLIGHT_CHANNEL_CLEAR'});
		}
		return () => {
			Dispatcher.dispatch({type: 'HIGHLIGHT_CHANNEL_CLEAR'});
		};
	}, [isAutocompleteAttached, channelMatch, autocompleteOptions]);

	const canMentionEveryone = PermissionUtils.can(Permissions.MENTION_EVERYONE, {
		guildId: channel.guildId,
		channelId: channel.id,
	});

	React.useEffect(() => {
		let options: Array<AutocompleteOption> = [];
		if (mentionMatch) {
			setAutocompleteType('mention');
			const members = matchSorter(GuildMemberStore.getMembers(channel.guildId), matchedText ?? '', {
				keys: ['nickname', 'user.nickname', 'user.username'],
			})
				.map((member) => ({
					type: 'mention' as const,
					kind: 'member' as const,
					member,
				}))
				.sort((a, b) => a.member.user.username.toLowerCase().localeCompare(b.member.user.username.toLowerCase()))
				.slice(0, 10);

			const roles = GuildStore.getGuildRoles(channel.guildId)
				.filter((role) => canMentionEveryone || (role.flags & GuildRoleFlags.MENTIONABLE) !== 0)
				.map((role) => ({
					type: 'mention' as const,
					kind: 'role' as const,
					role,
				}))
				.sort((a, b) => a.role.position - b.role.position)
				.slice(0, 10);

			const specialMentions = [
				{type: 'mention' as const, kind: '@everyone' as const},
				{type: 'mention' as const, kind: '@here' as const},
			];

			options = [...members, ...(canMentionEveryone ? specialMentions : []), ...roles];
		} else if (channelMatch) {
			setAutocompleteType('channel');
			options = matchSorter(ChannelStore.getGuildChannels(channel.guildId), matchedText ?? '', {keys: ['name']})
				.map((channel) => ({
					type: 'channel' as const,
					channel,
				}))
				.sort((a, b) => a.channel.position - b.channel.position)
				.slice(0, 10);
		} else if (emojiMatch) {
			setAutocompleteType('emoji');
			options = EmojiStore.search(channel, matchedText ?? '', 10).map((emoji) => ({
				type: 'emoji' as const,
				emoji,
			}));
		} else if (commandMatch) {
			setAutocompleteType('command');
			options = matchSorter(COMMANDS, matchedText ?? '', {keys: ['name']}).map((command) => ({
				type: 'command' as const,
				command,
			}));
		}
		if (hasOpenCodeBlock()) {
			setAutocompleteOptions([]);
		} else {
			setAutocompleteOptions(options);
		}
	}, [
		channel,
		channelMatch,
		mentionMatch,
		emojiMatch,
		commandMatch,
		canMentionEveryone,
		matchedText,
		hasOpenCodeBlock,
	]);

	React.useEffect(() => {
		if (editingMessageId) {
			ComponentDispatch.dispatch('SCROLL_TO_BOTTOM');
			setWasEditing(true);
		} else if (wasEditing) {
			textareaRef.current?.focus();
			textareaRef.current?.setSelectionRange(value.length, value.length);
			setWasEditing(false);
		}
	}, [editingMessageId, wasEditing, value.length]);

	React.useEffect(() => {
		if (replyingMessage) {
			ComponentDispatch.dispatch('SCROLL_TO_BOTTOM');
		}
	}, [replyingMessage]);

	const onSubmit = React.useCallback(async () => {
		const content = value.trim();
		if (!content && uploadAttachments.length === 0) {
			return;
		}

		const replaceMatch = content.match(REPLACE_REGEX);
		if (replaceMatch) {
			const [, source, replacement, globalFlag] = replaceMatch;
			const lastMessage = MessageStore.getLastEditableMessage(channel.id);
			if (lastMessage) {
				const regex = new RegExp(source, globalFlag ? 'g' : '');
				const newContent = lastMessage.content.replace(regex, replacement);
				if (newContent !== lastMessage.content) {
					MessageActionCreators.edit(lastMessage.channelId, lastMessage.id, {content: newContent});
				}
			}
			setValue('');
			return;
		}

		const nonce = SnowflakeUtils.getSnowflake().toString();
		if (uploadAttachments.some((attachment) => attachment.status !== 'completed')) {
			return;
		}

		const message = new MessageRecord({
			id: nonce,
			channel_id: channel.id,
			author: UserStore.getCurrentUser()!,
			type: referencedMessage ? MessageTypes.REPLY : MessageTypes.DEFAULT,
			flags: content.startsWith('@silent ') ? MessageFlags.SUPPRESS_NOTIFICATIONS : 0,
			content: content.startsWith('@silent ') ? content.replace('@silent ', '') : content,
			created_at: Date.now(),
			mentions: [...(referencedMessage && replyingMessage?.mentioning ? [referencedMessage.author] : [])],
			message_reference: referencedMessage ? {channel_id: channel.id, message_id: referencedMessage.id} : undefined,
			state: MessageStates.SENDING,
			nonce,
		});

		TypingUtils.clear(channel.id);
		setValue('');

		Dispatcher.dispatch({type: 'DRAFT_DELETE', channelId: channel.id});
		Dispatcher.dispatch({type: 'MESSAGE_REPLY_STOP', channelId: channel.id});

		if (uploadAttachments.length === 0) {
			Dispatcher.dispatch({
				type: 'MESSAGE_CREATE',
				message: {...message.toJSON(), referenced_message: referencedMessage?.toJSON()},
				optimistic: true,
			});
		}

		MessageActionCreators.send(channel.id, {
			content: message.content,
			nonce,
			uploadAttachments,
			allowedMentions: {replied_user: replyingMessage?.mentioning ?? true},
			messageReference: referencedMessage ? {message_id: referencedMessage.id} : undefined,
			flags: message.flags,
		});
	}, [channel.id, value, referencedMessage, replyingMessage, uploadAttachments]);

	React.useLayoutEffect(() => {
		draft && setValue(draft);
	}, [draft]);

	React.useEffect(() => {
		if (value) {
			Dispatcher.dispatch({type: 'DRAFT_CREATE', channelId: channel.id, content: value});
		} else {
			Dispatcher.dispatch({type: 'DRAFT_DELETE', channelId: channel.id});
		}
	}, [channel.id, value]);

	React.useEffect(() => {
		if (channel.type !== ChannelTypes.GUILD_TEXT) {
			return;
		}
		const content = value.trim();
		const isInReplaceMode = REPLACE_REGEX.test(content);
		if (content && content !== draft && !isAutocompleteAttached && !isInReplaceMode) {
			TypingUtils.typing(channel.id);
		} else {
			TypingUtils.clear(channel.id);
		}
	}, [channel.id, channel.type, draft, value, isAutocompleteAttached]);

	React.useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (
				mobileLayout.enabled ||
				editingMessageId ||
				event.ctrlKey ||
				event.metaKey ||
				ModalStore.hasModalOpen() ||
				PopoutStore.getPopouts().length > 0
			) {
				return;
			}

			if (!isFocused && event.key.length === 1) {
				textareaRef.current?.focus();
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [editingMessageId, isFocused, mobileLayout.enabled]);

	React.useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && replyingMessage) {
				Dispatcher.dispatch({type: 'MESSAGE_REPLY_STOP', channelId: channel.id});
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [channel.id, replyingMessage]);

	const onCursorMove = React.useCallback(() => {
		const position = textareaRef.current?.selectionStart ?? 0;
		const beforeCursor = value.slice(0, position);
		setValueUpToCursor(beforeCursor);
	}, [value]);

	React.useEffect(() => {
		const handleSelectionChange = () => {
			onCursorMove();
		};

		document.addEventListener('selectionchange', handleSelectionChange);
		return () => {
			document.removeEventListener('selectionchange', handleSelectionChange);
		};
	}, [onCursorMove]);

	const handleSelect = (selectedValue: string) => {
		if (!textareaRef.current) {
			return;
		}

		let triggerMatch: RegExpMatchArray | null = null;
		if (mentionMatch) {
			triggerMatch = mentionMatch;
		} else if (channelMatch) {
			triggerMatch = channelMatch;
		} else if (emojiMatch) {
			triggerMatch = emojiMatch;
		} else if (commandMatch) {
			triggerMatch = commandMatch;
		}

		if (!triggerMatch) {
			return;
		}

		const matchStart = triggerMatch.index ?? 0;
		const matchEnd = matchStart + triggerMatch[0].length;

		const beforeMatch = value.slice(0, matchStart);
		const afterMatch = value.slice(matchEnd);

		const guildBeforeMatch = beforeMatch.endsWith(' ') ? '' : ' ';

		const newValue = `${beforeMatch}${guildBeforeMatch}${selectedValue} ${afterMatch}`;
		const newCursorPosition = beforeMatch.length + guildBeforeMatch.length + selectedValue.length + 1;

		setValue(newValue.trimStart());
		setSelectedIndex(0);
		Dispatcher.dispatch({type: 'HIGHLIGHT_CHANNEL_CLEAR'});

		setTimeout(() => {
			if (textareaRef.current) {
				textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
				textareaRef.current.focus();
			}
		}, 0);
	};

	return (
		<div className="-mt-2 relative z-elevated-1 flex-shrink-0 px-4">
			{referencedMessage && (
				<ReplyBar
					replyingMessageObject={referencedMessage}
					shouldReplyMention={replyingMessage?.mentioning ?? false}
					setShouldReplyMention={(mentioning) =>
						Dispatcher.dispatch({type: 'MESSAGE_REPLY_MENTIONING', channelId: channel.id, mentioning})
					}
					channel={channel}
				/>
			)}

			<div
				className={clsx(
					'no-scrollbar relative mb-6 max-h-[50vh] overflow-x-hidden bg-background-textarea',
					referencedMessage ? 'rounded-b-md' : 'rounded-md',
					disabled && 'opacity-60',
				)}
			>
				<ChannelAttachmentArea channelId={channel.id} />

				<div className={clsx('relative flex pl-4', disabled && 'cursor-not-allowed')}>
					{!disabled && (
						<ChannelTextareaButton
							icon={PlusCircle}
							label={i18n.Messages.UPLOAD_FILE}
							className="-ml-[16px] sticky top-0 px-[16px] py-[10px]"
							onClick={handleFileButtonClick}
						/>
					)}

					<input type="file" ref={fileInputRef} onChange={handleFileChange} multiple={true} style={{display: 'none'}} />

					<TextareaAutosize
						disabled={disabled}
						autoFocus={!mobileLayout.enabled}
						className={clsx(
							'no-scrollbar relative h-full min-h-[44px] w-full resize-none overflow-x-hidden overflow-y-scroll whitespace-pre-wrap break-words bg-transparent py-[11px] pr-[11px] text-text-chat leading-[1.375rem] caret-text-chat',
							disabled && 'pointer-events-none',
						)}
						maxLength={channel.type === ChannelTypes.GUILD_DOCUMENT ? undefined : 4000}
						onBlur={() => setIsFocused(false)}
						onChange={(event) => setValue(event.target.value)}
						onFocus={() => setIsFocused(true)}
						onKeyDown={(event) => {
							onCursorMove();
							if (isAutocompleteAttached) {
								if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
									event.preventDefault();
									setSelectedIndex((prevIndex) => {
										const newIndex = event.key === 'ArrowUp' ? prevIndex - 1 : prevIndex + 1;
										const clampedIndex = (newIndex + autocompleteOptions.length) % autocompleteOptions.length;
										if (isChannel(autocompleteOptions[clampedIndex])) {
											Dispatcher.dispatch({
												type: 'HIGHLIGHT_CHANNEL',
												channelId: autocompleteOptions[clampedIndex].channel.id,
											});
										} else {
											Dispatcher.dispatch({type: 'HIGHLIGHT_CHANNEL_CLEAR'});
										}
										return clampedIndex;
									});
								} else if (event.key === 'Enter') {
									event.preventDefault();
									const selectedOption = autocompleteOptions[selectedIndex];
									if (selectedOption) {
										if (isMentionMember(selectedOption)) {
											handleSelect(`<@${selectedOption.member.user.id}>`);
										} else if (isMentionRole(selectedOption)) {
											handleSelect(`<@&${selectedOption.role.id}>`);
										} else if (isSpecialMention(selectedOption)) {
											handleSelect(selectedOption.kind);
										} else if (isChannel(selectedOption)) {
											handleSelect(`<#${selectedOption.channel.id}>`);
										} else if (isEmoji(selectedOption)) {
											handleSelect(EmojiStore.getEmojiMarkdown(selectedOption.emoji));
										} else if (isCommand(selectedOption)) {
											handleSelect(selectedOption.command.content);
										}
									}
								}
							} else if (event.key === 'Enter' && !event.shiftKey && !mobileLayout.enabled) {
								event.preventDefault();
								onSubmit();
							} else if (event.key === 'ArrowUp' && !event.shiftKey && !value) {
								const message = MessageStore.getLastEditableMessage(channel.id);
								if (!message) {
									return;
								}
								event.preventDefault();
								Dispatcher.dispatch({
									type: 'MESSAGE_EDIT_START',
									channelId: channel.id,
									messageId: message.id,
								});
							}
						}}
						placeholder={
							disabled
								? i18n.Messages.MISSING_SEND_PERMISSIONS
								: i18n.format(i18n.Messages.MESSAGE_EDITOR_PLACEHOLDER, {channelName: channel.name})
						}
						ref={textareaRef}
						value={value}
					/>

					{!disabled && (
						<div className="sticky top-0 flex h-[44px] items-center justify-center gap-[8px] pr-[16px]">
							{!mobileLayout.enabled && (
								<>
									<ChannelTextareaButton
										icon={Gif}
										label={i18n.Messages.GIFS}
										isSelected={expressionPickerOpen && selectedTab === 'gifs'}
										onClick={() => handleExpressionPickerTabToggle('gifs')}
										data-expression-picker-tab={'gifs'}
									/>

									<ChannelTextareaButton
										icon={Sticker}
										label={i18n.Messages.STICKERS}
										isSelected={expressionPickerOpen && selectedTab === 'stickers'}
										onClick={() => handleExpressionPickerTabToggle('stickers')}
										data-expression-picker-tab={'stickers'}
									/>
								</>
							)}

							<Popout
								uniqueId="expression-picker"
								position="top-end"
								animationType="none"
								offsetCrossAxis={16}
								render={() => <ExpressionPickerPopout channelId={channel.id} />}
								onOpen={() => setExpressionPickerOpen(true)}
								onClose={() => setExpressionPickerOpen(false)}
								onCloseRequest={(event) => {
									if (event) {
										const target = event.target as HTMLElement;
										const closest = target.closest('[data-expression-picker-tab]');
										if (closest != null) {
											const value = closest.getAttribute('data-expression-picker-tab');
											if (value != null && selectedTab !== value) {
												return false;
											}
										}
									}
									return true;
								}}
								ref={expressionPickerTriggerRef}
								returnFocusRef={textareaRef}
							>
								<ChannelTextareaButton
									icon={Smiley}
									label={i18n.Messages.EMOJIS}
									isSelected={expressionPickerOpen && selectedTab === 'emojis'}
									onClick={() => handleExpressionPickerTabToggle('emojis')}
									data-expression-picker-tab={'emojis'}
								/>
							</Popout>

							{mobileLayout.enabled && (
								<ChannelTextareaButton
									disabled={!value && uploadAttachments.length === 0}
									icon={PaperPlaneRight}
									label={i18n.Messages.SEND_MESSAGE}
									onClick={onSubmit}
								/>
							)}
						</div>
					)}
				</div>
			</div>

			{isAutocompleteAttached && (
				<Autocomplete
					type={autocompleteType}
					onSelect={handleSelect}
					selectedIndex={selectedIndex}
					options={autocompleteOptions}
					setSelectedIndex={setSelectedIndex}
				/>
			)}

			<div className="absolute right-4 bottom-px left-4 ml-1 flex h-6 min-w-0 items-center overflow-visible overflow-y-hidden overflow-ellipsis whitespace-nowrap font-medium text-sm text-text-chat leading-6">
				<TypingUsers channel={channel} />
			</div>
		</div>
	);
};

export const ChannelTextarea = ({guild, channel}: {guild: GuildRecord; channel: ChannelRecord}) => {
	const draft = DraftStore.getDraft(channel.id);
	const canSendMessages = PermissionUtils.can(Permissions.SEND_MESSAGES, {guildId: guild.id, channelId: channel.id});
	if (channel.type === ChannelTypes.GUILD_DOCUMENT && !canSendMessages) {
		return null;
	}
	return <ChannelTextareaContent key={channel.id} channel={channel} disabled={!canSendMessages} draft={draft} />;
};
