import type {ToastProps} from '~/components/uikit/Toast';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type ToastEntry = {id: string; data: ToastProps};

type State = Readonly<{
	currentToast: ToastEntry | null;
}>;

const initialState: State = {
	currentToast: null,
};

class ToastStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'TOAST_SET':
				return this.handleToastSet(action);
			case 'TOAST_DELETE':
				return this.handleToastDelete(action);
			default:
				return false;
		}
	}

	private handleToastSet({id, data}: Readonly<{id: string; data: ToastProps}>): boolean {
		if (!id || !data) return false;

		this.setState({
			currentToast: {id, data},
		});
		return true;
	}

	private handleToastDelete({id}: Readonly<{id: string}>): boolean {
		this.setState((prevState) => {
			if (prevState.currentToast?.id === id) {
				return {
					currentToast: null,
				};
			}
			return prevState;
		});
		return true;
	}

	getCurrentToast() {
		return this.state.currentToast;
	}

	hasToast(id: string): boolean {
		return this.state.currentToast?.id === id;
	}

	getToast(id: string): ToastProps | undefined {
		if (this.state.currentToast?.id === id) {
			return this.state.currentToast.data;
		}
		return undefined;
	}
}

export default new ToastStore();
