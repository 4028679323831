import {Info} from '@phosphor-icons/react';
import {noop} from 'lodash';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import type {GuildRecord} from '~/records/GuildRecord';
import * as FormUtils from '~/utils/FormUtils';

type FormInputs = {
	domain: string;
	password: string;
};

const DnsInputField = ({label, value}: {label: string; value: string}) => {
	const handleClick = () => {
		TextCopyActionCreators.copy(value);
	};

	return (
		<Input
			value={value}
			readOnly
			className="flex-1 cursor-pointer"
			aria-label={label}
			label={label}
			onClick={handleClick}
			onFocus={(e) => e.target.select()}
		/>
	);
};

const DomainVerificationSection = ({domain, guildId}: {domain: string; guildId: string}) => (
	<div className="rounded-lg border border-background-header-secondary bg-background-tertiary p-6 shadow-sm">
		<div className="space-y-4">
			<div className="flex items-center gap-2">
				<Info className="h-5 w-5 text-text-primary" />
				<h4 className="font-medium text-text-primary">DNS Verification Required</h4>
			</div>

			<div className="space-y-4 rounded-lg border border-background-header-secondary bg-background-secondary p-4">
				<DnsInputField label="Name" value={`_fluxer.${domain}`} />
				<DnsInputField label="Type" value="TXT" />
				<DnsInputField label="Content" value={`guild=${guildId}`} />
			</div>

			<p className="text-sm text-text-primary-muted">It may take a few minutes for the DNS record to propagate.</p>
		</div>
	</div>
);

export const GuildDomainModal = ({guild}: {guild: GuildRecord}) => {
	const [isVerifying, setIsVerifying] = useState(false);

	const form = useForm<FormInputs>({
		defaultValues: {
			domain: '',
			password: '',
		},
	});

	const verifyDomain = async () => {
		const domain = form.getValues('domain');
		const password = form.getValues('password');

		try {
			setIsVerifying(true);
			await GuildActionCreators.verifyDomain(guild.id, domain, password);
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: 'Domain verified successfully'});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'domain');
		} finally {
			setIsVerifying(false);
		}
	};

	return (
		<Modal.Root label="Set Custom Domain" size="small">
			<Form form={form} onSubmit={noop}>
				<div className="flex h-full w-full flex-col bg-background-secondary">
					<div className="relative flex items-center gap-4 border-background-header-secondary border-b px-4 py-3">
						<h2 className="mx-auto font-semibold text-lg text-text-primary">Set Custom Domain</h2>
					</div>

					<div className="min-h-0 flex-1">
						<div className="space-y-6 p-6">
							<Input
								{...form.register('domain')}
								label="Domain Name"
								placeholder="yourdomain.com"
								error={form.formState.errors.domain?.message}
							/>

							{form.watch('domain') && (
								<>
									<DomainVerificationSection domain={form.watch('domain')!} guildId={guild.id} />
									<Input
										{...form.register('password')}
										autoComplete="current-password"
										error={form.formState.errors.password?.message}
										label={i18n.Messages.PASSWORD}
										placeholder="••••••••••••••••••••••••••••••"
										type="password"
									/>
								</>
							)}
						</div>
					</div>

					<div className="flex justify-end gap-3 border-background-header-secondary border-t p-4">
						<Button onClick={ModalActionCreators.pop} variant="ghost" aria-label="Cancel">
							{i18n.Messages.CANCEL}
						</Button>

						<Button
							onClick={verifyDomain}
							submitting={isVerifying}
							variant="brand"
							disabled={!form.watch('domain') || !form.watch('password')}
							aria-label="Verify Domain"
						>
							{i18n.Messages.DONE}
						</Button>
					</div>
				</div>
			</Form>
		</Modal.Root>
	);
};
