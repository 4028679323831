import * as TypingActionCreators from '~/actions/TypingActionCreators';
import Dispatcher from '~/flux/Dispatcher';
import AuthenticationStore from '~/stores/AuthenticationStore';
import TypingStore from '~/stores/TypingStore';

class TypingManager {
	private currentChannelId: string | null = null;
	private nextSend: number | null = null;
	private timeoutId: NodeJS.Timeout | null = null;
	private hasSent = false;

	typing(channelId: string): void {
		Dispatcher.dispatch({
			type: 'TYPING_START',
			channelId,
			userId: AuthenticationStore.getId(),
		});

		if (this.shouldReturn(channelId)) {
			return;
		}

		this.updateStateForTyping(channelId);
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.sendTyping(channelId);
		}, 1500);

		this.nextSend = Date.now() + 10_000 * 0.8;
	}

	clear(channelId: string): void {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}

		if (!this.hasSent) {
			Dispatcher.dispatch({
				type: 'TYPING_STOP',
				channelId,
				userId: AuthenticationStore.getId(),
			});
		}

		this.resetState(channelId);
	}

	private shouldReturn(channelId: string): boolean {
		return this.currentChannelId === channelId && this.nextSend != null && this.nextSend > Date.now();
	}

	private updateStateForTyping(channelId: string): void {
		this.currentChannelId = channelId;
		const count = TypingStore.getCount(channelId);
		if (count > 5) {
			this.nextSend = Date.now() + 10_000;
		}
	}

	private sendTyping(channelId: string): void {
		TypingActionCreators.sendTyping(channelId);
		this.hasSent = true;
		this.timeoutId = null;
	}

	private resetState(channelId: string): void {
		this.hasSent = this.currentChannelId === channelId ? false : this.hasSent;
		if (this.currentChannelId === channelId) {
			this.nextSend = null;
		}
	}
}

export const TypingUtils = new TypingManager();
