import {Endpoints} from '~/Endpoints';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import * as HttpClient from '~/lib/HttpClient';

export type UploadStatus = 'pending' | 'uploading' | 'completed' | 'failed';

export type UploadAttachment = Readonly<{
	id: number;
	channelId: string;
	file: File;
	filename: string;
	description?: string;
	spoiler: boolean;
	previewURL: string | null;
	status: UploadStatus;
	uploadURL?: string;
	uploadFilename?: string;
	width: number;
	height: number;
}>;

type State = Readonly<{
	uploadAttachments: Readonly<Record<string, ReadonlyArray<UploadAttachment>>>;
}>;

const initialState: State = {
	uploadAttachments: {},
};

class UploadAttachmentStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action) {
		switch (action.type) {
			case 'UPLOAD_ATTACHMENT_CREATE':
				return this.handleUploadAttachmentCreate(action);
			case 'UPLOAD_ATTACHMENT_UPDATE':
				return this.handleUploadAttachmentUpdate(action);
			case 'UPLOAD_ATTACHMENT_DELETE':
				return this.handleUploadAttachmentDelete(action);
			case 'UPLOAD_ATTACHMENT_REPLACE':
				return this.handleUploadAttachmentReplace(action);
			case 'UPLOAD_ATTACHMENT_START_UPLOAD':
				return this.handleUploadAttachmentStartUpload(action);
			case 'UPLOAD_ATTACHMENT_UPLOAD_COMPLETE':
				return this.handleUploadAttachmentUploadComplete(action);
			case 'UPLOAD_ATTACHMENT_UPLOAD_FAILED':
				return this.handleUploadAttachmentUploadFailed(action);
			default:
				return false;
		}
	}

	getUploadAttachments(channelId: string): ReadonlyArray<UploadAttachment> {
		return this.state.uploadAttachments[channelId] ?? [];
	}

	useUploadAttachments(channelId: string): ReadonlyArray<UploadAttachment> {
		const {uploadAttachments} = this.useStore();
		return uploadAttachments[channelId] ?? [];
	}

	private updateAttachmentList(
		channelId: string,
		updater: (attachments: ReadonlyArray<UploadAttachment>) => ReadonlyArray<UploadAttachment>,
	): State {
		return {
			uploadAttachments: {
				...this.state.uploadAttachments,
				[channelId]: updater(this.state.uploadAttachments[channelId] ?? []),
			},
		};
	}

	private updateAttachment(
		channelId: string,
		attachmentId: number,
		updater: (attachment: UploadAttachment) => UploadAttachment,
	): State {
		return this.updateAttachmentList(channelId, (attachments) => {
			const index = attachments.findIndex((a) => a.id === attachmentId);
			if (index === -1) return attachments;

			return [...attachments.slice(0, index), updater(attachments[index]), ...attachments.slice(index + 1)];
		});
	}

	private handleUploadAttachmentCreate({
		channelId,
		attachments,
	}: {
		channelId: string;
		attachments: ReadonlyArray<UploadAttachment>;
	}): boolean {
		this.setState((prevState) => ({
			uploadAttachments: {
				...prevState.uploadAttachments,
				[channelId]: [...(prevState.uploadAttachments[channelId] ?? []), ...attachments],
			},
		}));

		return true;
	}

	private handleUploadAttachmentUpdate({
		channelId,
		attachmentId,
		patch,
	}: {
		channelId: string;
		attachmentId: number;
		patch: Partial<UploadAttachment>;
	}): boolean {
		this.setState(() =>
			this.updateAttachment(channelId, attachmentId, (attachment) => ({
				...attachment,
				...patch,
			})),
		);
		return true;
	}

	private handleUploadAttachmentDelete({
		channelId,
		attachmentId,
	}: {
		channelId: string;
		attachmentId: number;
	}): boolean {
		this.setState((prevState) => {
			const attachments = prevState.uploadAttachments[channelId] ?? [];
			const attachment = attachments.find((a) => a.id === attachmentId);

			if (!attachment) return prevState;

			if (attachment.uploadFilename) {
				HttpClient.del({url: Endpoints.ATTACHMENT(attachment.uploadFilename)});
			}

			return this.updateAttachmentList(channelId, (attachments) => attachments.filter((a) => a.id !== attachmentId));
		});
		return true;
	}

	private handleUploadAttachmentReplace({
		channelId,
		attachments,
	}: {
		channelId: string;
		attachments: ReadonlyArray<UploadAttachment>;
	}): boolean {
		this.setState((prevState) => ({
			uploadAttachments: {
				...prevState.uploadAttachments,
				[channelId]: attachments,
			},
		}));
		return true;
	}

	private handleUploadAttachmentStartUpload({
		channelId,
		attachmentId,
	}: {
		channelId: string;
		attachmentId: number;
	}): boolean {
		this.setState(() =>
			this.updateAttachment(channelId, attachmentId, (attachment) => ({
				...attachment,
				status: 'uploading',
			})),
		);
		return true;
	}

	private handleUploadAttachmentUploadComplete({
		channelId,
		attachmentId,
		uploadURL,
		uploadFilename,
	}: {
		channelId: string;
		attachmentId: number;
		uploadURL: string;
		uploadFilename: string;
	}): boolean {
		this.setState(() =>
			this.updateAttachment(channelId, attachmentId, (attachment) => ({
				...attachment,
				status: 'completed',
				uploadURL,
				uploadFilename,
			})),
		);
		return true;
	}

	private handleUploadAttachmentUploadFailed({
		channelId,
		attachmentId,
	}: {
		channelId: string;
		attachmentId: number;
	}): boolean {
		this.setState(() =>
			this.updateAttachment(channelId, attachmentId, (attachment) => ({
				...attachment,
				status: 'failed',
			})),
		);
		return true;
	}
}

export default new UploadAttachmentStore();
