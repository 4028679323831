import {useForm} from 'react-hook-form';
import * as AttachmentActionCreators from '~/actions/AttachmentActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import {Switch} from '~/components/form/Switch';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {UploadAttachment} from '~/stores/UploadAttachmentStore';

type FormInputs = {
	filename: string;
	spoiler: boolean;
};

export const AttachmentEditModal = ({channelId, attachment}: {channelId: string; attachment: UploadAttachment}) => {
	const form = useForm<FormInputs>({defaultValues: {filename: attachment.filename, spoiler: attachment.spoiler}});

	const onSubmit = async (data: FormInputs) => {
		AttachmentActionCreators.update({
			channelId,
			attachmentId: attachment.id,
			filename: data.filename,
			spoiler: data.spoiler,
		});
		ModalActionCreators.pop();
	};

	return (
		<Form form={form} onSubmit={onSubmit}>
			<Modal.Root label={i18n.Messages.EDIT_ATTACHMENT} size="small">
				<Modal.Header title={attachment.filename} />
				<Modal.Content className={styles.content}>
					<div className="flex flex-col gap-3">
						<Input
							{...form.register('filename')}
							autoFocus={true}
							label={i18n.Messages.FILENAME}
							minLength={1}
							maxLength={512}
							required={true}
							type="text"
						/>
						<Switch
							label={i18n.Messages.MARK_AS_SPOILER}
							value={form.watch('spoiler')}
							onChange={(value) => form.setValue('spoiler', value)}
						/>
					</div>
				</Modal.Content>
				<Modal.Footer>
					<div className={styles.footer}>
						<Button onClick={ModalActionCreators.pop} variant="ghost">
							{i18n.Messages.CANCEL}
						</Button>
						<Button type="submit" variant="brand">
							{i18n.Messages.SAVE}
						</Button>
					</div>
				</Modal.Footer>
			</Modal.Root>
		</Form>
	);
};
