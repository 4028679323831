import {Endpoints} from '~/Endpoints';
import * as HttpClient from '~/lib/HttpClient';
import type {Channel} from '~/records/ChannelRecord';

export const create = async (guildId: string, params: Pick<Channel, 'name' | 'url' | 'type'>) => {
	const {body} = await HttpClient.post<Channel>({url: Endpoints.GUILD_CHANNELS(guildId), body: params});
	return body;
};

export const update = async (channelId: string, params: Partial<Pick<Channel, 'name' | 'topic' | 'url'>>) => {
	const {body} = await HttpClient.patch<Channel>({url: Endpoints.CHANNEL(channelId), body: params});
	return body;
};

export const remove = async (channelId: string) => {
	await HttpClient.del({url: Endpoints.CHANNEL(channelId)});
};
