import {Endpoints} from '~/Endpoints';
import * as HttpClient from '~/lib/HttpClient';

const LOCALE = 'en_US';

export type TenorGif = {
	id: string;
	title: string;
	url: string;
	src: string;
	proxy_src: string;
	width: number;
	height: number;
};

type TenorCategory = {
	name: string;
	src: string;
	proxy_src: string;
};

export type TenorFeatured = {
	categories: Array<TenorCategory>;
	gifs: Array<TenorGif>;
};

let tenorFeaturedCache: TenorFeatured | null = null;

export const search = async (q: string) => {
	const {body} = await HttpClient.get<Array<TenorGif>>({url: Endpoints.TENOR_SEARCH, query: {q, locale: LOCALE}});
	return body;
};

export const getFeatured = async () => {
	if (tenorFeaturedCache) {
		return tenorFeaturedCache;
	}
	const {body} = await HttpClient.get<TenorFeatured>({url: Endpoints.TENOR_FEATURED, query: {locale: LOCALE}});
	tenorFeaturedCache = body;
	return body;
};

export const getTrending = async () => {
	const {body} = await HttpClient.get<Array<TenorGif>>({
		url: Endpoints.TENOR_TRENDING_GIFS,
		query: {locale: LOCALE},
	});
	return body;
};

export const registerShare = async (id: string, q: string) => {
	await HttpClient.post({url: Endpoints.TENOR_REGISTER_SHARE, body: {id, q, locale: LOCALE}});
};

export const suggest = async (q: string) => {
	const {body} = await HttpClient.get<Array<string>>({url: Endpoints.TENOR_SUGGEST, query: {q, locale: LOCALE}});
	return body;
};
