import emojiRegex from 'emoji-regex';
import punycode from 'punycode/';
import UnicodeEmojis from '~/lib/UnicodeEmojis';
import * as EmojiUtils from '~/utils/EmojiUtils';

const MAX_AST_NODES = 10000;
const MAX_INLINE_DEPTH = 10;
const MAX_LINES = 10000;
const MAX_LINE_LENGTH = 4096;
const MAX_LINK_URL_LENGTH = 2048;
const PLAINTEXT_SURROGATES: Set<string> = new Set(['™', '™️', '©', '©️', '®', '®️']);

export enum ParserFlags {
	ALLOW_SPOILERS = 1 << 0,
	ALLOW_HEADINGS = 1 << 1,
	ALLOW_LISTS = 1 << 2,
	ALLOW_CODE_BLOCKS = 1 << 3,
	ALLOW_MASKED_LINKS = 1 << 4,
	ALLOW_COMMAND_MENTIONS = 1 << 5,
	ALLOW_GUILD_NAVIGATIONS = 1 << 6,
	ALLOW_USER_MENTIONS = 1 << 7,
	ALLOW_ROLE_MENTIONS = 1 << 8,
	ALLOW_CHANNEL_MENTIONS = 1 << 9,
	ALLOW_EVERYONE_MENTIONS = 1 << 10,
	ALLOW_BLOCKQUOTES = 1 << 11,
	ALLOW_MULTILINE_BLOCKQUOTES = 1 << 12,
	ALLOW_SUBTEXT = 1 << 13,
	ALLOW_TABLES = 1 << 14,
	ALLOW_ALERTS = 1 << 15,
}

export enum NodeType {
	Text = 'Text',
	Blockquote = 'Blockquote',
	Strong = 'Strong',
	Emphasis = 'Emphasis',
	Underline = 'Underline',
	Strikethrough = 'Strikethrough',
	Spoiler = 'Spoiler',
	Heading = 'Heading',
	Subtext = 'Subtext',
	List = 'List',
	CodeBlock = 'CodeBlock',
	InlineCode = 'InlineCode',
	Sequence = 'Sequence',
	Link = 'Link',
	Mention = 'Mention',
	Timestamp = 'Timestamp',
	Emoji = 'Emoji',
	TripleAsterisk = 'TripleAsterisk',
	TripleUnderscore = 'TripleUnderscore',
	Table = 'Table',
	TableRow = 'TableRow',
	TableCell = 'TableCell',
	Alert = 'Alert',
	Math = 'Math',
}

export enum AlertType {
	Note = 'Note',
	Tip = 'Tip',
	Important = 'Important',
	Warning = 'Warning',
	Caution = 'Caution',
}

export enum TableAlignment {
	Left = 'Left',
	Center = 'Center',
	Right = 'Right',
	None = 'None',
}

export enum TimestampStyle {
	ShortTime = 'ShortTime',
	LongTime = 'LongTime',
	ShortDate = 'ShortDate',
	LongDate = 'LongDate',
	ShortDateTime = 'ShortDateTime',
	LongDateTime = 'LongDateTime',
	RelativeTime = 'RelativeTime',
}

export enum GuildNavKind {
	Customize = 'Customize',
	Browse = 'Browse',
	Guide = 'Guide',
	LinkedRoles = 'LinkedRoles',
}

export enum MentionKind {
	User = 'User',
	Channel = 'Channel',
	Role = 'Role',
	Command = 'Command',
	GuildNavigation = 'GuildNavigation',
	Everyone = 'Everyone',
	Here = 'Here',
}

export enum EmojiKind {
	Standard = 'Standard',
	Custom = 'Custom',
}

export type BaseNode = {
	type: NodeType;
};

export type TextNode = BaseNode & {
	type: NodeType.Text;
	content: string;
};

export type BlockquoteNode = BaseNode & {
	type: NodeType.Blockquote;
	children: Array<Node>;
};

export type FormattingNode = BaseNode & {
	type:
		| NodeType.Strong
		| NodeType.Emphasis
		| NodeType.Underline
		| NodeType.Strikethrough
		| NodeType.Spoiler
		| NodeType.Sequence;
	children: Array<Node>;
};

export type HeadingNode = BaseNode & {
	type: NodeType.Heading;
	level: number;
	children: Array<Node>;
};

export type SubtextNode = BaseNode & {
	type: NodeType.Subtext;
	children: Array<Node>;
};

export type ListNode = BaseNode & {
	type: NodeType.List;
	ordered: boolean;
	items: Array<ListItem>;
};

export type ListItem = {
	children: Array<Node>;
};

export type CodeBlockNode = BaseNode & {
	type: NodeType.CodeBlock;
	language?: string;
	content: string;
};

export type InlineCodeNode = BaseNode & {
	type: NodeType.InlineCode;
	content: string;
};

export type LinkNode = BaseNode & {
	type: NodeType.Link;
	text?: Node;
	url: string;
	escaped: boolean;
};

export type MentionNode = BaseNode & {
	type: NodeType.Mention;
	kind: MentionType;
};

export type TimestampNode = BaseNode & {
	type: NodeType.Timestamp;
	timestamp: number;
	milliseconds: number;
	style: TimestampStyle;
};

export type EmojiNode = BaseNode & {
	type: NodeType.Emoji;
	kind: EmojiType;
};

export type SequenceNode = BaseNode & {
	type: NodeType.Sequence;
	children: Array<Node>;
};

export type TableNode = BaseNode & {
	type: NodeType.Table;
	header: TableRowNode;
	alignments: Array<TableAlignment>;
	rows: Array<TableRowNode>;
};

export type TableRowNode = BaseNode & {
	type: NodeType.TableRow;
	cells: Array<TableCellNode>;
};

export type TableCellNode = BaseNode & {
	type: NodeType.TableCell;
	children: Array<Node>;
};

export type AlertNode = BaseNode & {
	type: NodeType.Alert;
	alertType: AlertType;
	children: Array<Node>;
};

export type SpoilerNode = BaseNode & {
	type: NodeType.Spoiler;
	children: Array<Node>;
	isBlock: boolean;
};

export type MathNode = BaseNode & {
	type: NodeType.Math;
	content: string;
	isBlock: boolean;
};

export type Node =
	| TextNode
	| BlockquoteNode
	| FormattingNode
	| HeadingNode
	| SubtextNode
	| ListNode
	| CodeBlockNode
	| InlineCodeNode
	| LinkNode
	| MentionNode
	| TimestampNode
	| EmojiNode
	| SequenceNode
	| TableNode
	| TableRowNode
	| TableCellNode
	| AlertNode
	| SpoilerNode
	| MathNode;

export type MentionType =
	| {kind: MentionKind.User; id: string}
	| {kind: MentionKind.Channel; id: string}
	| {kind: MentionKind.Role; id: string}
	| {
			kind: MentionKind.Command;
			name: string;
			subcommandGroup?: string;
			subcommand?: string;
			id: string;
	  }
	| {
			kind: MentionKind.GuildNavigation;
			navigationType: GuildNavKind.Customize | GuildNavKind.Browse | GuildNavKind.Guide;
	  }
	| {
			kind: MentionKind.GuildNavigation;
			navigationType: GuildNavKind.LinkedRoles;
			id?: string;
	  }
	| {kind: MentionKind.Everyone}
	| {kind: MentionKind.Here};

export type EmojiType =
	| {kind: EmojiKind.Standard; raw: string; codepoints: string; name: string}
	| {kind: EmojiKind.Custom; name: string; id: string; animated: boolean};

export type EmojiData = {
	people: Array<EmojiEntry>;
	nature: Array<EmojiEntry>;
	food: Array<EmojiEntry>;
	activity: Array<EmojiEntry>;
	travel: Array<EmojiEntry>;
	objects: Array<EmojiEntry>;
	symbols: Array<EmojiEntry>;
	flags: Array<EmojiEntry>;
};

export type EmojiEntry = {
	names: Array<string>;
	surrogates: string;
	skins?: Array<EmojiSkin>;
};

export type EmojiSkin = {
	names: Array<string>;
	surrogates: string;
};

export type EmojiCacheEntry = {
	raw: string;
	skinTones: Array<EmojiSkinData>;
};

export type EmojiSkinData = {
	raw: string;
};

export type SkinToneVariant = {
	raw: string;
	codepoints: string;
	name: string;
};

export type EmojiInfo = {
	raw: string;
	codepoints: string;
	name: string;
	hasSkins: boolean;
	skinTones: Array<SkinToneVariant>;
};

export type ParserResult = {
	node: Node;
	advance: number;
};

class FormattingContext {
	private readonly formattingStack: Array<[string, boolean]> = [];
	private currentTextContent = '';
	private currentDepth = 0;

	canEnterFormatting(delimiter: string, isDouble: boolean): boolean {
		if (delimiter === '_' && isDouble) {
			const underscoreCount = [...this.currentTextContent].filter((c) => c === '_').length;
			if (underscoreCount > 0) return false;
		}
		if (this.isFormattingActive(delimiter, isDouble)) return false;
		return this.currentDepth < MAX_INLINE_DEPTH;
	}

	isFormattingActive(delimiter: string, isDouble: boolean): boolean {
		return this.formattingStack.some(([d, double]) => d === delimiter && double === isDouble);
	}

	pushFormatting(delimiter: string, isDouble: boolean): void {
		this.formattingStack.push([delimiter, isDouble]);
		this.currentDepth++;
	}

	setCurrentText(text: string): void {
		this.currentTextContent = text;
	}
}

export class Parser {
	private readonly lines: Array<string>;
	private currentLineIndex: number;
	private readonly totalLineCount: number;
	private readonly parserFlags: number;
	private nodeCount: number;

	constructor(input: string, flags: number) {
		this.lines = input.split('\n').slice(0, MAX_LINES);
		if (this.lines.length === 1 && this.lines[0] === '') {
			this.lines.length = 0;
		}
		this.currentLineIndex = 0;
		this.totalLineCount = this.lines.length;
		this.parserFlags = flags;
		this.nodeCount = 0;
	}

	parse(): Array<Node> {
		const ast: Array<Node> = [];
		if (this.totalLineCount === 0) return ast;

		while (this.currentLineIndex < this.totalLineCount && this.nodeCount <= MAX_AST_NODES) {
			const line = this.lines[this.currentLineIndex];
			let lineLength = line.length;
			if (lineLength > MAX_LINE_LENGTH) {
				this.lines[this.currentLineIndex] = line.slice(0, MAX_LINE_LENGTH);
				lineLength = MAX_LINE_LENGTH;
			}

			const trimmedLine = line.trimStart();
			if (trimmedLine === '') {
				const blankLineCount = this.countBlankLines(this.currentLineIndex);
				if (ast.length > 0 && this.currentLineIndex + blankLineCount < this.totalLineCount) {
					const nextLine = this.lines[this.currentLineIndex + blankLineCount];
					const nextTrimmed = nextLine.trimStart();
					const isNextHeading = nextTrimmed ? this.parseHeading(nextTrimmed) !== null : false;
					const isPreviousHeading = ast[ast.length - 1]?.type === NodeType.Heading;

					if (!isNextHeading && !isPreviousHeading) {
						ast.push({type: NodeType.Text, content: '\n'});
						this.nodeCount++;
					}
				}
				this.currentLineIndex += blankLineCount;
				continue;
			}

			const blockNode = this.parseBlock();
			if (blockNode) {
				ast.push(blockNode);
				this.nodeCount++;
				continue;
			}

			this.parseInlineLine(ast);
			this.currentLineIndex++;
		}

		this.flattenAST(ast);
		return ast;
	}

	private countBlankLines(startLine: number): number {
		let count = 0;
		let current = startLine;
		while (current < this.totalLineCount && this.lines[current].trim() === '') {
			count++;
			current++;
		}
		return count;
	}

	private parseInlineLine(ast: Array<Node>): void {
		let text = this.lines[this.currentLineIndex];
		if (this.currentLineIndex < this.totalLineCount - 1) {
			const nextLine = this.lines[this.currentLineIndex + 1];
			if (!this.isBlockStart(nextLine.trimStart())) {
				text += '\n';
			}
		}

		const inlineNodes = this.parseInline(text);

		for (const node of inlineNodes) {
			ast.push(node);
			this.nodeCount++;
			if (this.nodeCount > MAX_AST_NODES) break;
		}
	}

	private isBlockStart(line: string): boolean {
		return (
			line.startsWith('#') ||
			this.matchListItem(line) !== null ||
			line.startsWith('```') ||
			line.startsWith('>') ||
			line.startsWith('>>> ')
		);
	}

	private parseBlock(): Node | null {
		if (this.currentLineIndex >= this.totalLineCount) return null;
		const line = this.lines[this.currentLineIndex];
		const trimmed = line.trimStart();

		if (trimmed.startsWith('$$')) {
			return this.parseBlockMath();
		}

		if (trimmed.startsWith('>>> ')) {
			if (!(this.parserFlags & ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES)) {
				return this.parseBlockAsText('>>> ');
			}
			return this.parseMultilineBlockquote();
		}

		if (trimmed.startsWith('>')) {
			if (!(this.parserFlags & ParserFlags.ALLOW_BLOCKQUOTES)) {
				return this.parseBlockAsText('>');
			}
			return this.parseBlockquote();
		}

		const listMatch = this.matchListItem(line);
		if (listMatch) {
			const [isOrdered, indentLevel, _content] = listMatch;
			if (this.parserFlags & ParserFlags.ALLOW_LISTS) {
				return this.parseList(isOrdered, indentLevel, 1);
			}
			this.currentLineIndex++;
			return {type: NodeType.Text, content: line};
		}

		if (trimmed.startsWith('||') && !trimmed.slice(2).includes('||')) {
			if (this.parserFlags & ParserFlags.ALLOW_SPOILERS) {
				return this.parseSpoiler();
			}
			this.currentLineIndex++;
			return {type: NodeType.Text, content: line};
		}

		if (trimmed.startsWith('```')) {
			if (this.parserFlags & ParserFlags.ALLOW_CODE_BLOCKS) {
				return this.parseCodeBlock();
			}
			return this.parseBlockAsText('```');
		}

		if (trimmed.startsWith('-#')) {
			if (this.parserFlags & ParserFlags.ALLOW_SUBTEXT) {
				const subtextNode = this.parseSubtext(trimmed);
				if (subtextNode) {
					this.currentLineIndex++;
					this.nodeCount++;
					return subtextNode;
				}
			}
			const content = this.handleLineAsText(line);
			this.currentLineIndex++;
			return {type: NodeType.Text, content};
		}

		if (trimmed.startsWith('#')) {
			if (this.parserFlags & ParserFlags.ALLOW_HEADINGS) {
				const headingNode = this.parseHeading(trimmed);
				if (headingNode) {
					this.currentLineIndex++;
					this.nodeCount++;
					return headingNode;
				}
			}
			const content = this.handleLineAsText(line);
			this.currentLineIndex++;
			return {type: NodeType.Text, content};
		}

		if (trimmed.includes('|') && this.parserFlags & ParserFlags.ALLOW_TABLES) {
			const startIndex = this.currentLineIndex;
			const tableNode = this.parseTable();
			if (tableNode) return tableNode;

			this.currentLineIndex = startIndex;
			return null;
		}

		return null;
	}

	private handleLineAsText(line: string): string {
		const isLastLine = this.currentLineIndex === this.totalLineCount - 1;
		return isLastLine ? line : `${line}\n`;
	}

	private parseBlockAsText(marker: string): Node {
		let content = this.lines[this.currentLineIndex];
		this.currentLineIndex++;

		while (this.currentLineIndex < this.totalLineCount) {
			const line = this.lines[this.currentLineIndex];
			const trimmed = line.trimStart();
			if (trimmed.startsWith(marker)) {
				content += `\n${line}`;
				this.currentLineIndex++;
				break;
			}
			content += `\n${line}`;
			this.currentLineIndex++;
		}

		return {type: NodeType.Text, content};
	}

	private parseMathExpression(text: string): ParserResult | null {
		if (!text.startsWith('$')) return null;

		if (text.startsWith('$$')) {
			if (text === '$$' || text === '$$$') return null;
			return null;
		}

		let position = 1;
		let content = '';
		let escaped = false;

		while (position < text.length) {
			const char = text[position];

			if (!escaped && char === '\\') {
				escaped = true;
				position++;
				continue;
			}

			if (!escaped && char === '$') {
				if (content.trim().length === 0) return null;

				return {
					node: {
						type: NodeType.Math,
						content: content.trim(),
						isBlock: false,
					},
					advance: position + 1,
				};
			}

			if (escaped) {
				if (char === '$') content += '$';
				else content += `\\${char}`;
				escaped = false;
			} else {
				content += char;
			}

			position++;

			if (position > MAX_LINE_LENGTH) break;
		}

		return {
			node: {type: NodeType.Text, content: `$${content}`},
			advance: content.length + 1,
		};
	}

	private parseBlockMath(): Node | null {
		const startLine = this.currentLineIndex;
		const line = this.lines[startLine];

		const trimmedStart = line.trimStart();
		if (!trimmedStart.startsWith('$$')) return null;

		let content = trimmedStart.slice(2);
		this.currentLineIndex++;
		let foundEnd = false;

		while (this.currentLineIndex < this.totalLineCount) {
			const currentLine = this.lines[this.currentLineIndex];
			const trimmedEnd = currentLine.trimEnd();

			if (trimmedEnd.endsWith('$$')) {
				content += `\n${currentLine.slice(0, -2)}`;
				foundEnd = true;
				this.currentLineIndex++;
				break;
			}

			content += `\n${currentLine}`;
			this.currentLineIndex++;

			if (content.length > MAX_LINE_LENGTH * 10) break;
		}

		if (!foundEnd) {
			this.currentLineIndex = startLine;
			return {
				type: NodeType.Text,
				content: line,
			};
		}

		const trimmedContent = content.trim();
		if (trimmedContent.length === 0) {
			this.currentLineIndex = startLine;
			return {
				type: NodeType.Text,
				content: line,
			};
		}

		return {
			type: NodeType.Math,
			content: trimmedContent,
			isBlock: true,
		};
	}

	private parseTable(): TableNode | null {
		const startIndex = this.currentLineIndex;

		try {
			const potentialTable = this.lines.slice(startIndex, startIndex + 3);
			if (!this.isValidTableStructure(potentialTable)) {
				return null;
			}

			const headerRow = this.parseTableRow(this.lines[this.currentLineIndex]);
			if (!headerRow) return null;

			this.currentLineIndex++;

			if (this.currentLineIndex >= this.totalLineCount) {
				this.currentLineIndex = startIndex;
				return null;
			}

			const alignmentRow = this.parseTableAlignments(this.lines[this.currentLineIndex]);
			if (!alignmentRow || headerRow.cells.length !== alignmentRow.length) {
				this.currentLineIndex = startIndex;
				return null;
			}

			this.currentLineIndex++;

			const rows: Array<TableRowNode> = [];
			while (this.currentLineIndex < this.totalLineCount) {
				const line = this.lines[this.currentLineIndex];
				if (!line.includes('|')) break;

				const row = this.parseTableRow(line);
				if (!row || row.cells.length !== headerRow.cells.length) break;

				rows.push(row);
				this.currentLineIndex++;
			}

			return {
				type: NodeType.Table,
				header: headerRow,
				alignments: alignmentRow,
				rows,
			};
		} catch (_) {
			this.currentLineIndex = startIndex;
			return null;
		}
	}

	private isValidTableStructure(lines: Array<string>): boolean {
		if (lines.length < 2) return false;

		const headerLine = lines[0].trim();
		if (!this.hasValidTableContent(headerLine)) return false;

		const alignmentLine = lines[1].trim();
		if (!this.isValidAlignmentRow(alignmentLine)) return false;

		return true;
	}

	private hasValidTableContent(line: string): boolean {
		if (!line.includes('|')) return false;

		const cells = this.splitTableCells(line);
		return cells.some((cell) => cell.trim().length > 0);
	}

	private isValidAlignmentRow(line: string): boolean {
		if (!line.includes('|') || !line.includes('-')) return false;

		const cells = this.splitTableCells(line);
		return cells.every((cell) => {
			const trimmed = cell.trim();
			if (trimmed.length === 0) return false;

			return /^[\s:|-]+$/.test(trimmed) && trimmed.includes('-');
		});
	}

	private splitTableCells(line: string): Array<string> {
		const content = line.trim().replace(/^\||\|$/g, '');
		return content.split('|');
	}

	private parseTableAlignments(line: string): Array<TableAlignment> | null {
		const cells = this.splitTableCells(line.trim());
		const alignments: Array<TableAlignment> = [];

		for (const cell of cells) {
			const trimmed = cell.trim();
			if (!trimmed || !trimmed.includes('-')) return null;

			const left = trimmed.startsWith(':');
			const right = trimmed.endsWith(':');

			if (left && right) {
				alignments.push(TableAlignment.Center);
			} else if (left) {
				alignments.push(TableAlignment.Left);
			} else if (right) {
				alignments.push(TableAlignment.Right);
			} else {
				alignments.push(TableAlignment.None);
			}
		}

		return alignments.length > 0 ? alignments : null;
	}

	private parseTableRow(line: string): TableRowNode | null {
		const trimmedLine = line.trim();
		if (!trimmedLine || !this.hasValidTableContent(trimmedLine)) return null;

		const cells: Array<TableCellNode> = [];
		const cellContents = this.splitTableCells(trimmedLine);

		for (const cellContent of cellContents) {
			const trimmed = cellContent.trim();
			const inlineNodes = this.parseInline(trimmed);
			cells.push({
				type: NodeType.TableCell,
				children: inlineNodes.length > 0 ? inlineNodes : [{type: NodeType.Text, content: trimmed}],
			});
		}

		return cells.length === 0 ? null : {type: NodeType.TableRow, cells};
	}

	private parseSpoiler(): SpoilerNode | TextNode {
		const startLine = this.currentLineIndex;
		let foundEnd = false;
		let blockContent = '';

		while (this.currentLineIndex < this.totalLineCount) {
			const line = this.lines[this.currentLineIndex];

			if (this.currentLineIndex === startLine) {
				const startIdx = line.indexOf('||');
				if (startIdx !== -1) {
					blockContent += line.slice(startIdx + 2);
				}
			} else {
				const endIdx = line.indexOf('||');
				if (endIdx !== -1) {
					blockContent += line.slice(0, endIdx);
					foundEnd = true;
					this.currentLineIndex++;
					break;
				}
				blockContent += line;
			}

			blockContent += '\n';
			this.currentLineIndex++;

			if (blockContent.length > MAX_LINE_LENGTH * 10) break;
		}

		if (!foundEnd) {
			return {type: NodeType.Text, content: `||${blockContent.trimEnd()}`};
		}

		const innerParser = new Parser(blockContent.trim(), this.parserFlags);
		const innerNodes = innerParser.parse();

		return {type: NodeType.Spoiler, children: innerNodes, isBlock: true};
	}

	private parseCodeBlock(): CodeBlockNode {
		const line = this.lines[this.currentLineIndex];
		const trimmed = line.trimStart();
		const language = trimmed.length > 3 ? trimmed.slice(3).trim() || undefined : undefined;
		this.currentLineIndex++;

		let content = '';
		while (this.currentLineIndex < this.totalLineCount) {
			const current = this.lines[this.currentLineIndex];
			if (current.trimStart().startsWith('```')) {
				this.currentLineIndex++;
				break;
			}
			content += `${current}\n`;
			if (content.length > MAX_LINE_LENGTH * 100) break;
			this.currentLineIndex++;
		}

		return {type: NodeType.CodeBlock, language, content};
	}

	private parseMultilineBlockquote(): Node {
		const line = this.lines[this.currentLineIndex];
		const trimmed = line.trimStart();
		if (!trimmed.startsWith('>>> ')) return {type: NodeType.Text, content: ''};

		let content = trimmed.slice(4);
		this.currentLineIndex++;

		while (this.currentLineIndex < this.totalLineCount) {
			const current = this.lines[this.currentLineIndex];
			content += `\n${current}`;
			this.currentLineIndex++;
			if (content.length > MAX_LINE_LENGTH * 100) break;
		}

		const childFlags = (this.parserFlags & ~ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES) | ParserFlags.ALLOW_BLOCKQUOTES;
		const childNodes = new Parser(content, childFlags).parse();
		return {type: NodeType.Blockquote, children: childNodes};
	}

	private parseAlert(blockquoteText: string): AlertNode | null {
		const alertMatch = blockquoteText.match(/^\[!([A-Z]+)\]\s*\n?/);
		if (!alertMatch) return null;

		const alertTypeStr = alertMatch[1].toUpperCase();
		let alertType: AlertType;

		switch (alertTypeStr) {
			case 'NOTE':
				alertType = AlertType.Note;
				break;
			case 'TIP':
				alertType = AlertType.Tip;
				break;
			case 'IMPORTANT':
				alertType = AlertType.Important;
				break;
			case 'WARNING':
				alertType = AlertType.Warning;
				break;
			case 'CAUTION':
				alertType = AlertType.Caution;
				break;
			default:
				return null;
		}

		const content = blockquoteText.slice(alertMatch[0].length);
		const childFlags =
			(this.parserFlags & ~ParserFlags.ALLOW_BLOCKQUOTES) | ParserFlags.ALLOW_LISTS | ParserFlags.ALLOW_HEADINGS;

		const lines = content.split('\n');
		const processedLines = lines.map((line) => {
			const trimmed = line.trim();
			if (trimmed.startsWith('-') || /^\d+\./.test(trimmed)) {
				return line;
			}
			return trimmed;
		});

		const processedContent = processedLines
			.join('\n')
			.replace(/\n{3,}/g, '\n\n')
			.trim();

		const childNodes = new Parser(processedContent, childFlags).parse();
		const mergedNodes: Array<Node> = [];
		let currentText = '';

		for (const node of childNodes) {
			if (node.type === NodeType.Text) {
				if (currentText) {
					currentText += node.content;
				} else {
					currentText = node.content;
				}
			} else {
				if (currentText) {
					mergedNodes.push({type: NodeType.Text, content: currentText});
					currentText = '';
				}
				mergedNodes.push(node);
			}
		}

		if (currentText) {
			mergedNodes.push({type: NodeType.Text, content: currentText});
		}

		const finalNodes = this.postProcessAlertNodes(mergedNodes);
		return {
			type: NodeType.Alert,
			alertType,
			children: finalNodes,
		};
	}

	private postProcessAlertNodes(nodes: Array<Node>): Array<Node> {
		const result: Array<Node> = [];
		let i = 0;

		while (i < nodes.length) {
			const node = nodes[i];

			if (node.type === NodeType.Text && i + 1 < nodes.length) {
				if (nodes[i + 1].type === NodeType.List) {
					const trimmedContent = node.content.replace(/\s+$/, '\n');
					if (trimmedContent) {
						result.push({type: NodeType.Text, content: trimmedContent});
					}
				} else {
					result.push(node);
				}
			} else if (node.type === NodeType.List && i + 1 < nodes.length) {
				result.push(node);

				const nextNode = nodes[i + 1];
				if (nextNode.type === NodeType.Text) {
					const content = nextNode.content.trim();
					if (content) {
						result.push({type: NodeType.Text, content: `\n${content}`});
						i++;
					}
				}
			} else {
				result.push(node);
			}

			i++;
		}

		return result;
	}

	private parseBlockquote(): Node | null {
		let blockquoteContent = '';
		const startLine = this.currentLineIndex;

		while (this.currentLineIndex < this.totalLineCount) {
			if (this.nodeCount > MAX_AST_NODES) break;
			const line = this.lines[this.currentLineIndex];
			const trimmed = line.trimStart();

			if (trimmed === '>') {
				// Empty quote line
				if (blockquoteContent.length > 0) blockquoteContent += '\n\n';
				this.currentLineIndex++;
			} else if (trimmed.startsWith('>')) {
				// Quote line with content
				const content = trimmed.startsWith('> ') ? trimmed.slice(2) : trimmed.slice(1);
				if (blockquoteContent.length > 0) blockquoteContent += '\n';
				blockquoteContent += content;
				this.currentLineIndex++;
			} else {
				break;
			}

			if (blockquoteContent.length > MAX_LINE_LENGTH * 100) break;
		}

		if (blockquoteContent === '' && this.currentLineIndex === startLine) return null;

		if (this.parserFlags & ParserFlags.ALLOW_ALERTS) {
			const alertNode = this.parseAlert(blockquoteContent);
			if (alertNode) return alertNode;
		}

		const childFlags = this.parserFlags & ~ParserFlags.ALLOW_BLOCKQUOTES;
		const childNodes = new Parser(blockquoteContent, childFlags).parse();

		return {
			type: NodeType.Blockquote,
			children: childNodes,
		};
	}

	private parseHeading(trimmed: string): HeadingNode | null {
		const level = [...trimmed].filter((c) => c === '#').length;
		if (level >= 1 && level <= 3 && trimmed[level] === ' ') {
			const content = trimmed.slice(level + 1);
			const inlineNodes = this.parseInline(content);
			return {type: NodeType.Heading, level, children: inlineNodes};
		}
		return null;
	}

	private parseSubtext(trimmed: string): SubtextNode | null {
		if (trimmed.startsWith('-#')) {
			if ((trimmed.length > 2 && trimmed[2] !== ' ') || (trimmed.length > 3 && trimmed[3] === ' ')) {
				return null;
			}

			const content = trimmed.slice(3);
			const inlineNodes = this.parseInline(content);
			return {type: NodeType.Subtext, children: inlineNodes};
		}
		return null;
	}

	private matchListItem(line: string): [boolean, number, string] | null {
		let indent = 0;
		let pos = 0;

		while (pos < line.length && line[pos] === ' ') {
			indent++;
			pos++;
		}

		if (indent > 0 && indent < 2) return null;
		const indentLevel = Math.floor(indent / 2);
		if (pos >= line.length) return null;

		const marker = line[pos];
		if (marker === '*' || marker === '-') {
			return this.handleUnorderedListMarker(line, pos, indentLevel);
		}
		if (/[0-9]/.test(marker)) {
			return this.handleOrderedListMarker(line, pos, indentLevel);
		}

		return null;
	}

	private handleUnorderedListMarker(line: string, pos: number, indentLevel: number): [boolean, number, string] | null {
		if (line[pos + 1] === ' ') {
			return [false, indentLevel, line.slice(pos + 2)];
		}
		return null;
	}

	private handleOrderedListMarker(line: string, pos: number, indentLevel: number): [boolean, number, string] | null {
		let currentPos = pos;

		while (currentPos < line.length && /[0-9]/.test(line[currentPos])) {
			currentPos++;
		}

		if (line[currentPos] === '.' && line[currentPos + 1] === ' ') {
			return [true, indentLevel, line.slice(currentPos + 2)];
		}

		return null;
	}

	private parseList(isOrdered: boolean, indentLevel: number, depth: number): ListNode {
		const items: Array<ListItem> = [];
		const startLine = this.currentLineIndex;
		const firstLineContent = this.lines[startLine];

		while (this.currentLineIndex < this.totalLineCount) {
			if (this.nodeCount > MAX_AST_NODES) break;
			const currentLine = this.lines[this.currentLineIndex];
			const trimmed = currentLine.trimStart();

			if (this.isBlockBreak(trimmed)) break;

			const listMatch = this.matchListItem(currentLine);
			if (listMatch) {
				const [itemOrdered, itemIndent, content] = listMatch;
				if (itemIndent < indentLevel) break;

				if (itemIndent === indentLevel) {
					if (itemOrdered !== isOrdered) {
						if (this.currentLineIndex === startLine) {
							return this.createSimpleList(firstLineContent);
						}
						break;
					}
					this.handleSameIndentLevel(items, content, indentLevel, depth);
				} else if (itemIndent === indentLevel + 1) {
					this.handleNestedIndentLevel(items, currentLine, itemOrdered, itemIndent, depth);
				} else {
					break;
				}
			} else if (this.isBulletPointText(currentLine)) {
				this.handleBulletPointText(items, currentLine);
			} else if (this.isListContinuation(currentLine, indentLevel)) {
				this.handleListContinuation(items, currentLine);
			} else {
				break;
			}

			if (items.length > MAX_AST_NODES) break;
		}

		if (items.length === 0 && this.currentLineIndex === startLine) {
			return this.createSimpleList(firstLineContent);
		}

		return {type: NodeType.List, ordered: isOrdered, items};
	}

	private isBlockBreak(trimmed: string): boolean {
		return (
			trimmed.startsWith('#') || trimmed.startsWith('```') || trimmed.startsWith('>') || trimmed.startsWith('>>> ')
		);
	}

	private createSimpleList(content: string): ListNode {
		return {
			type: NodeType.List,
			ordered: false,
			items: [{children: [{type: NodeType.Text, content}]}],
		};
	}

	private handleSameIndentLevel(items: Array<ListItem>, content: string, indentLevel: number, depth: number): void {
		this.currentLineIndex++;
		const itemNodes = this.parseInline(content);
		const nestedList = this.tryParseNestedContent(indentLevel, depth);
		if (nestedList) {
			itemNodes.push(nestedList);
			this.nodeCount++;
		}
		items.push({children: itemNodes});
	}

	private handleNestedIndentLevel(
		items: Array<ListItem>,
		currentLine: string,
		isOrdered: boolean,
		indentLevel: number,
		depth: number,
	): void {
		if (depth >= 5) {
			if (items.length > 0) {
				items[items.length - 1].children.push({type: NodeType.Text, content: currentLine.trim()});
				this.nodeCount++;
			}
			this.currentLineIndex++;
		} else {
			const nested = this.parseList(isOrdered, indentLevel, depth + 1);
			if (items.length > 0) {
				items[items.length - 1].children.push(nested);
				this.nodeCount++;
			}
		}
	}

	private handleBulletPointText(items: Array<ListItem>, currentLine: string): void {
		if (items.length > 0) {
			items[items.length - 1].children.push({type: NodeType.Text, content: currentLine.trim()});
			this.nodeCount++;
		}
		this.currentLineIndex++;
	}

	private handleListContinuation(items: Array<ListItem>, currentLine: string): void {
		if (items.length > 0) {
			items[items.length - 1].children.push({type: NodeType.Text, content: currentLine.trimStart()});
			this.nodeCount++;
		}
		this.currentLineIndex++;
	}

	private tryParseNestedContent(parentIndent: number, depth: number): Node | null {
		if (this.currentLineIndex >= this.totalLineCount) return null;
		const line = this.lines[this.currentLineIndex];
		const listMatch = this.matchListItem(line);

		if (listMatch) {
			const [isOrdered, indent, _] = listMatch;
			if (indent > parentIndent && depth < 5) {
				return this.parseList(isOrdered, indent, depth + 1);
			}
		}

		return null;
	}

	private isListContinuation(line: string, indentLevel: number): boolean {
		const indent = [...line].filter((c) => c === ' ').length;
		return indent > indentLevel * 2;
	}

	private isBulletPointText(text: string): boolean {
		const trimmed = text.trimStart();
		return trimmed.startsWith('- ') && !text.startsWith('  ');
	}

	private parseInline(text: string): Array<Node> {
		const nodes = this.parseInlineWithContext(text, new FormattingContext());
		this.flattenAST(nodes);
		return nodes;
	}

	private parseInlineWithContext(text: string, context: FormattingContext): Array<Node> {
		if (!text) {
			return [];
		}

		const nodes: Array<Node> = [];
		let accumulatedText = '';
		let position = 0;
		const characters = [...text];

		while (position < characters.length) {
			if (characters[position] === '$') {
				const mathResult = this.parseMathExpression(characters.slice(position).join(''));
				if (mathResult) {
					this.addTextNode(nodes, accumulatedText);
					accumulatedText = '';
					nodes.push(mathResult.node);
					this.nodeCount++;
					position += mathResult.advance;
					continue;
				}
			}

			if (characters[position] === '\\' && position + 1 < characters.length) {
				const nextChar = characters[position + 1];
				if (this.isEscapableCharacter(nextChar)) {
					accumulatedText += nextChar;
					position += 2;
					continue;
				}
			}

			const remainingText = characters.slice(position).join('');
			if (this.startsWithUrl(remainingText)) {
				const urlResult = this.extractUrlSegment(remainingText);
				if (urlResult) {
					this.addTextNode(nodes, accumulatedText);
					accumulatedText = '';
					nodes.push(urlResult.node);
					position += urlResult.advance;
					continue;
				}
			}

			if (this.isWordUnderscore(characters, position)) {
				accumulatedText += '_';
				position += 1;
				continue;
			}

			const emojiResult = this.parseEmoji(characters.slice(position).join(''));
			if (emojiResult) {
				this.addTextNode(nodes, accumulatedText);
				accumulatedText = '';
				nodes.push(emojiResult.node);
				this.nodeCount++;
				position += emojiResult.advance;
				continue;
			}

			const specialResult = this.parseSpecialSequence(remainingText, context);
			if (specialResult) {
				this.addTextNode(nodes, accumulatedText);
				accumulatedText = '';
				nodes.push(specialResult.node);
				this.nodeCount++;
				position += specialResult.advance;
				continue;
			}

			accumulatedText += characters[position];
			position += 1;

			if (accumulatedText.length > MAX_LINE_LENGTH) {
				this.addTextNode(nodes, accumulatedText);
				accumulatedText = '';
				break;
			}
		}

		this.addTextNode(nodes, accumulatedText);
		return this.mergeTextNodes(nodes);
	}

	private startsWithUrl(text: string): boolean {
		return text.startsWith('http://') || text.startsWith('https://');
	}

	private isWordUnderscore(chars: Array<string>, pos: number): boolean {
		if (chars[pos] !== '_') return false;
		const prevChar = pos > 0 ? chars[pos - 1] : '\0';
		const nextChar = pos + 1 < chars.length ? chars[pos + 1] : '\0';
		return this.isWordCharacter(prevChar) && this.isWordCharacter(nextChar);
	}

	private parseEmoji(text: string): ParserResult | null {
		const regex = emojiRegex();
		const match = regex.exec(text);

		if (match && match.index === 0) {
			const candidate = match[0];
			if (PLAINTEXT_SURROGATES.has(candidate)) {
				return null;
			}
		}

		const standardEmoji = this.parseStandardEmoji(text, 0);
		if (standardEmoji) return standardEmoji;

		if (text.startsWith(':')) {
			return this.parseEmojiShortcode(text);
		}

		if (text.startsWith('<')) {
			return this.parseCustomEmoji(text);
		}

		return null;
	}

	private parseSpecialSequence(text: string, context: FormattingContext): ParserResult | null {
		return (
			this.parseAutolink(text) ||
			this.parseFormatting(text, context) ||
			this.parseMention(text) ||
			this.parseSimpleMention(text) ||
			this.parseTimestamp(text) ||
			(this.parserFlags & ParserFlags.ALLOW_MASKED_LINKS ? this.parseLink(text) : null)
		);
	}

	private addTextNode(nodes: Array<Node>, text: string): void {
		if (text) {
			nodes.push({type: NodeType.Text, content: text});
		}
	}

	private mergeTextNodes(nodes: Array<Node>): Array<Node> {
		const mergedNodes = nodes.reduce((acc: Array<Node>, node: Node) => {
			const lastNode = acc[acc.length - 1];
			if (node.type === NodeType.Text && lastNode?.type === NodeType.Text) {
				lastNode.content += node.content;
			} else {
				acc.push(node);
			}
			return acc;
		}, []);
		return mergedNodes;
	}

	private parseFormatting(text: string, context: FormattingContext): ParserResult | null {
		const markerInfo = this.getFormattingMarkerInfo(text);
		if (!markerInfo) return null;

		const {marker, nodeType, markerLength} = markerInfo;
		if (!context.canEnterFormatting(marker[0], marker.length > 1)) return null;

		const endResult = this.findFormattingEnd(text, marker, markerLength, nodeType);
		if (!endResult) return null;

		const {endPosition, innerContent} = endResult;
		const isBlock = context.isFormattingActive(marker[0], marker.length > 1);
		const formattingNode = this.createFormattingNode(nodeType, innerContent, marker, isBlock);

		return {node: formattingNode, advance: endPosition + markerLength};
	}

	private getFormattingMarkerInfo(text: string): {marker: string; nodeType: NodeType; markerLength: number} | null {
		const chars = [...text];

		if (chars.length >= 3) {
			if (chars[0] === '*' && chars[1] === '*' && chars[2] === '*') {
				return {marker: '***', nodeType: NodeType.TripleAsterisk, markerLength: 3};
			}
			if (chars[0] === '_' && chars[1] === '_' && chars[2] === '_') {
				return {marker: '___', nodeType: NodeType.TripleUnderscore, markerLength: 3};
			}
		}

		if (chars.length >= 2) {
			if (chars[0] === '|' && chars[1] === '|' && this.parserFlags & ParserFlags.ALLOW_SPOILERS) {
				return {marker: '||', nodeType: NodeType.Spoiler, markerLength: 2};
			}
			if (chars[0] === '~' && chars[1] === '~') {
				return {marker: '~~', nodeType: NodeType.Strikethrough, markerLength: 2};
			}
			if (chars[0] === '*' && chars[1] === '*') {
				return {marker: '**', nodeType: NodeType.Strong, markerLength: 2};
			}
			if (chars[0] === '_' && chars[1] === '_') {
				return {marker: '__', nodeType: NodeType.Underline, markerLength: 2};
			}
		}

		if (chars.length >= 1) {
			if (chars[0] === '`') {
				return {marker: '`', nodeType: NodeType.InlineCode, markerLength: 1};
			}
			if (chars[0] === '*') {
				return {marker: '*', nodeType: NodeType.Emphasis, markerLength: 1};
			}
			if (chars[0] === '_') {
				return {marker: '_', nodeType: NodeType.Emphasis, markerLength: 1};
			}
		}

		return null;
	}

	private findFormattingEnd(
		text: string,
		marker: string,
		markerLength: number,
		nodeType: NodeType,
	): {endPosition: number; innerContent: string} | null {
		const chars = [...text];
		let position = markerLength;
		let nestedLevel = 0;
		let endPosition: number | null = null;

		if (nodeType === NodeType.InlineCode) {
			while (position < chars.length) {
				if (chars[position] === '`') {
					endPosition = position;
					break;
				}
				position++;
				if (position > MAX_LINE_LENGTH) break;
			}
		} else {
			while (position < chars.length) {
				if (this.matchMarker(chars, position, marker)) {
					if (nestedLevel === 0) {
						endPosition = position;
						break;
					}
					nestedLevel--;
				} else if (this.matchMarker(chars, position, marker)) {
					nestedLevel++;
				}

				if (chars[position] === '\\' && position + 1 < chars.length) {
					position += 2;
				} else {
					position++;
				}

				if (position > MAX_LINE_LENGTH) break;
			}
		}

		if (endPosition === null) return null;

		const innerChars = chars.slice(markerLength, endPosition);
		return {endPosition, innerContent: innerChars.join('')};
	}

	private matchMarker(chars: Array<string>, pos: number, marker: string): boolean {
		if (pos + marker.length > chars.length) return false;
		return chars.slice(pos, pos + marker.length).every((ch, i) => ch === marker[i]);
	}

	private createFormattingNode(nodeType: NodeType, innerContent: string, marker: string, isBlock = false): Node {
		const newContext = new FormattingContext();
		newContext.pushFormatting(marker[0], marker.length > 1);

		switch (nodeType) {
			case NodeType.InlineCode:
				return {type: NodeType.InlineCode, content: innerContent};

			case NodeType.TripleAsterisk:
			case NodeType.TripleUnderscore: {
				const emphasisContext = new FormattingContext();
				emphasisContext.pushFormatting('*', true);
				const innerNodes = this.parseInlineWithContext(innerContent, emphasisContext);
				return {
					type: NodeType.Emphasis,
					children: [{type: NodeType.Strong, children: innerNodes}],
				};
			}

			case NodeType.Strong:
				return {
					type: NodeType.Strong,
					children: this.parseInlineWithContext(innerContent, newContext),
				};

			case NodeType.Emphasis:
				return {
					type: NodeType.Emphasis,
					children: this.parseInlineWithContext(innerContent, newContext),
				};

			case NodeType.Underline:
				return {
					type: NodeType.Underline,
					children: this.parseInlineWithContext(innerContent, newContext),
				};

			case NodeType.Strikethrough:
				return {
					type: NodeType.Strikethrough,
					children: this.parseInlineWithContext(innerContent, newContext),
				};

			case NodeType.Spoiler:
				return {
					type: NodeType.Spoiler,
					children: this.parseInlineWithContext(innerContent, newContext),
					isBlock,
				};

			default:
				throw new Error(`Unexpected node type: ${nodeType}`);
		}
	}

	private parseLink(text: string): ParserResult | null {
		if (!text.startsWith('[')) return null;

		const linkParts = this.extractLinkParts(text);
		if (!linkParts) {
			const spoofedLinkMatch = /^\[https?:\/\/[^\s\[\]]+\]\(https?:\/\/[^\s\[\]]+\)$/.test(text);
			const nestedLinkMatch = /\[[^\]]+\]\([^)]+\)/.test(text) && /\[[^\]]+\]\([^)]+\)/.test(text);

			if (spoofedLinkMatch || nestedLinkMatch) {
				return {
					node: {type: NodeType.Text, content: text},
					advance: text.length,
				};
			}

			return null;
		}

		try {
			if (this.isValidUrl(linkParts.url)) {
				const finalUrl = this.convertToAsciiUrl(linkParts.url);
				const inlineNodes = this.parseInline(linkParts.linkText);
				return {
					node: {
						type: NodeType.Link,
						text: inlineNodes.length === 1 ? inlineNodes[0] : {type: NodeType.Sequence, children: inlineNodes},
						url: finalUrl,
						escaped: linkParts.isEscaped,
					},
					advance: linkParts.advanceBy,
				};
			}
		} catch {
			return {
				node: {type: NodeType.Text, content: text.slice(0, linkParts.advanceBy)},
				advance: linkParts.advanceBy,
			};
		}

		return null;
	}

	private extractLinkParts(
		text: string,
	): {linkText: string; url: string; isEscaped: boolean; advanceBy: number} | null {
		const chars = [...text];
		const {bracketPosition, linkText} = this.findClosingBracket(chars);
		if (bracketPosition === null || linkText === null) return null;

		if (bracketPosition + 1 >= chars.length || chars[bracketPosition + 1] !== '(') return null;

		const trimmedLinkText = linkText.trim();

		// Check for spoofed links (where link text looks like a URL)
		const isLinkTextURL = /^https?:\/\/[^\s\[\]]+$/.test(trimmedLinkText);

		// Check for nested links
		const containsLinkSyntax = /\[[^\]]*\]\([^)]*\)/.test(trimmedLinkText);

		if (isLinkTextURL || containsLinkSyntax) {
			return null;
		}

		const urlInfo = this.extractUrl(chars, bracketPosition + 2);
		if (!urlInfo) return null;

		return {
			linkText,
			...urlInfo,
		};
	}

	private findClosingBracket(chars: Array<string>): {bracketPosition: number | null; linkText: string | null} {
		let position = 1;
		let nestedBrackets = 0;

		while (position < chars.length) {
			if (chars[position] === '[') {
				nestedBrackets++;
				position++;
			} else if (chars[position] === ']') {
				if (nestedBrackets > 0) {
					nestedBrackets--;
					position++;
				} else {
					return {
						bracketPosition: position,
						linkText: chars.slice(1, position).join(''),
					};
				}
			} else if (chars[position] === '\\' && position + 1 < chars.length) {
				position += 2;
			} else {
				position++;
			}
			if (position > MAX_LINE_LENGTH) break;
		}

		return {bracketPosition: null, linkText: null};
	}

	private extractUrl(
		chars: Array<string>,
		startPos: number,
	): {url: string; isEscaped: boolean; advanceBy: number} | null {
		return chars[startPos] === '<'
			? this.extractEscapedUrl(chars, startPos + 1)
			: this.extractUnescapedUrl(chars, startPos);
	}

	private extractEscapedUrl(
		chars: Array<string>,
		urlStart: number,
	): {url: string; isEscaped: boolean; advanceBy: number} | null {
		let currentPos = urlStart;

		while (currentPos < chars.length) {
			if (chars[currentPos] === '>') {
				const url = chars.slice(urlStart, currentPos).join('');

				currentPos++;
				while (currentPos < chars.length && chars[currentPos] !== ')') {
					currentPos++;
				}

				return {
					url,
					isEscaped: true,
					advanceBy: currentPos + 1,
				};
			}
			currentPos++;
		}

		return null;
	}

	private extractUnescapedUrl(
		chars: Array<string>,
		urlStart: number,
	): {url: string; isEscaped: boolean; advanceBy: number} | null {
		let currentPos = urlStart;
		let nestedParens = 0;

		while (currentPos < chars.length) {
			if (chars[currentPos] === '(') {
				nestedParens++;
				currentPos++;
			} else if (chars[currentPos] === ')') {
				if (nestedParens > 0) {
					nestedParens--;
					currentPos++;
				} else {
					const url = chars
						.slice(urlStart, currentPos)
						.join('')
						.replace(/[.,!?:;]+$/, '');

					return {
						url,
						isEscaped: false,
						advanceBy: currentPos + 1,
					};
				}
			} else {
				currentPos++;
			}
		}

		return null;
	}

	private extractUrlSegment(text: string): ParserResult | null {
		const prefixLength = text.startsWith('https://') ? 8 : text.startsWith('http://') ? 7 : 0;
		if (prefixLength === 0) return null;

		let end = prefixLength;
		while (end < text.length && !this.isUrlTerminationChar(text[end])) {
			end++;
			if (end - prefixLength > MAX_LINK_URL_LENGTH) break;
		}

		const urlString = text.slice(0, end).replace(/[.,!?:;]+$/, '');
		if (this.isValidUrl(urlString)) {
			const finalUrl = this.convertToAsciiUrl(urlString);
			return {
				node: {type: NodeType.Link, text: undefined, url: finalUrl, escaped: false},
				advance: urlString.length,
			};
		}

		return null;
	}

	private parseAutolink(text: string): ParserResult | null {
		if (!text.startsWith('<') || !this.startsWithUrl(text.slice(1))) return null;

		const end = text.indexOf('>');
		if (end === -1) return null;

		const urlString = text.slice(1, end);
		if (urlString.length > MAX_LINK_URL_LENGTH) return null;

		if (this.isValidUrl(urlString)) {
			const finalUrl = this.convertToAsciiUrl(urlString);
			return {
				node: {type: NodeType.Link, text: undefined, url: finalUrl, escaped: true},
				advance: end + 1,
			};
		}

		return null;
	}

	private parseMention(text: string): ParserResult | null {
		if (!text.startsWith('<')) return null;

		const end = text.indexOf('>');
		if (end === -1) return null;

		const inner = text.slice(1, end);
		let mentionNode: MentionNode | null = null;

		if (inner.startsWith('@')) {
			mentionNode = this.parseUserOrRoleMention(inner);
		} else if (inner.startsWith('#')) {
			mentionNode = this.parseChannelMention(inner);
		} else if (inner.startsWith('/')) {
			mentionNode = this.parseCommandMention(inner);
		} else if (inner.startsWith('id:')) {
			mentionNode = this.parseGuildNavigation(inner);
		}

		return mentionNode ? {node: mentionNode, advance: end + 1} : null;
	}

	private parseUserOrRoleMention(inner: string): MentionNode | null {
		if (inner.startsWith('@&')) {
			const roleId = inner.slice(2);
			if (roleId && /^\d+$/.test(roleId) && this.parserFlags & ParserFlags.ALLOW_ROLE_MENTIONS) {
				return {type: NodeType.Mention, kind: {kind: MentionKind.Role, id: roleId}};
			}
		} else {
			const userId = inner.startsWith('@!') ? inner.slice(2) : inner.slice(1);
			if (userId && /^\d+$/.test(userId) && this.parserFlags & ParserFlags.ALLOW_USER_MENTIONS) {
				return {type: NodeType.Mention, kind: {kind: MentionKind.User, id: userId}};
			}
		}
		return null;
	}

	private parseChannelMention(inner: string): MentionNode | null {
		const channelId = inner.slice(1);
		if (channelId && /^\d+$/.test(channelId) && this.parserFlags & ParserFlags.ALLOW_CHANNEL_MENTIONS) {
			return {type: NodeType.Mention, kind: {kind: MentionKind.Channel, id: channelId}};
		}
		return null;
	}

	private parseCommandMention(inner: string): MentionNode | null {
		if (!(this.parserFlags & ParserFlags.ALLOW_COMMAND_MENTIONS)) return null;

		const parts = inner.split(':');
		if (parts.length !== 2) return null;

		const [commandPart, idPart] = parts;
		if (!idPart || !/^\d+$/.test(idPart)) return null;

		const segments = commandPart.slice(1).trim().split(' ');
		if (segments.length === 0) return null;

		return {
			type: NodeType.Mention,
			kind: {
				kind: MentionKind.Command,
				name: segments[0],
				subcommandGroup: segments.length === 3 ? segments[1] : undefined,
				subcommand: segments.length >= 2 ? segments[segments.length - 1] : undefined,
				id: idPart,
			},
		};
	}

	private parseGuildNavigation(inner: string): MentionNode | null {
		if (!(this.parserFlags & ParserFlags.ALLOW_GUILD_NAVIGATIONS)) return null;

		const parts = inner.split(':');
		if (parts.length < 2 || parts.length > 3) return null;

		const [idLabel, navType, navId] = parts;
		if (idLabel !== 'id') return null;

		const navigationType = this.getNavigationType(navType);
		if (!navigationType) return null;

		if (navigationType === GuildNavKind.LinkedRoles) {
			return this.createLinkedRolesNavigation(parts.length === 3 ? navId : undefined);
		}

		if (parts.length !== 2) return null;
		return this.createBasicNavigation(navigationType);
	}

	private parseSimpleMention(text: string): ParserResult | null {
		if (!(this.parserFlags & ParserFlags.ALLOW_EVERYONE_MENTIONS)) return null;

		if (text.startsWith('@everyone')) {
			return {
				node: {type: NodeType.Mention, kind: {kind: MentionKind.Everyone}},
				advance: '@everyone'.length,
			};
		}

		if (text.startsWith('@here')) {
			return {
				node: {type: NodeType.Mention, kind: {kind: MentionKind.Here}},
				advance: '@here'.length,
			};
		}

		return null;
	}

	private parseTimestamp(text: string): ParserResult | null {
		if (!text.startsWith('<t:')) return null;

		const end = text.indexOf('>');
		if (end === -1) return null;

		const inner = text.slice(3, end);
		const parts = inner.split(':');

		const [timestampPart, stylePart] = parts;
		const [seconds, millisStr] = timestampPart.split('.');
		const timestamp = Number.parseInt(seconds, 10);
		const milliseconds = millisStr ? Number.parseInt(millisStr.padEnd(3, '0').slice(0, 3), 10) : 0;

		if (Number.isNaN(timestamp) || timestamp === 0 || Number.isNaN(milliseconds)) return null;

		let style: TimestampStyle;
		if (stylePart) {
			const styleChar = stylePart[0];
			const parsedStyle = this.getTimestampStyle(styleChar);
			if (!parsedStyle) return null;
			style = parsedStyle;
		} else {
			style = TimestampStyle.ShortDateTime;
		}

		return {
			node: {
				type: NodeType.Timestamp,
				timestamp,
				milliseconds,
				style,
			},
			advance: end + 1,
		};
	}

	private getTimestampStyle(char: string): TimestampStyle | null {
		switch (char) {
			case 't':
				return TimestampStyle.ShortTime;
			case 'T':
				return TimestampStyle.LongTime;
			case 'd':
				return TimestampStyle.ShortDate;
			case 'D':
				return TimestampStyle.LongDate;
			case 'f':
				return TimestampStyle.ShortDateTime;
			case 'F':
				return TimestampStyle.LongDateTime;
			case 'R':
				return TimestampStyle.RelativeTime;
			default:
				return null;
		}
	}

	private isValidUrl(urlStr: string): boolean {
		try {
			const url = new URL(urlStr);
			return url.protocol === 'http:' || url.protocol === 'https:';
		} catch {
			return false;
		}
	}

	private isUrlTerminationChar(char: string): boolean {
		return /\s|\)|\]/.test(char);
	}

	private isEscapableCharacter(char: string): boolean {
		return /^[[\]()\\*_~`@!#$%^&+={}|:;"'<>,.?/]$/.test(char);
	}

	private isWordCharacter(char: string): boolean {
		return /^[a-zA-Z0-9_]$/.test(char);
	}

	private getNavigationType(navTypeLower: string): GuildNavKind | null {
		switch (navTypeLower) {
			case 'customize':
				return GuildNavKind.Customize;
			case 'browse':
				return GuildNavKind.Browse;
			case 'guide':
				return GuildNavKind.Guide;
			case 'linked-roles':
				return GuildNavKind.LinkedRoles;
			default:
				return null;
		}
	}

	private createLinkedRolesNavigation(id?: string): MentionNode {
		return {
			type: NodeType.Mention,
			kind: {
				kind: MentionKind.GuildNavigation,
				navigationType: GuildNavKind.LinkedRoles,
				id,
			},
		};
	}

	private createBasicNavigation(navigationType: GuildNavKind): MentionNode {
		return {
			type: NodeType.Mention,
			kind: {
				kind: MentionKind.GuildNavigation,
				navigationType,
			},
		};
	}

	private convertToAsciiUrl(target: string): string {
		try {
			const url = new URL(target);
			if (url.hostname) {
				const asciiHost = punycode.toASCII(url.hostname);
				url.hostname = asciiHost;
				return url.toString();
			}
			return target;
		} catch {
			return target;
		}
	}

	private parseStandardEmoji(text: string, start: number): ParserResult | null {
		const regex = emojiRegex();
		const match = regex.exec(text.slice(start));

		if (match && match.index === 0) {
			const candidate = match[0];
			const codepoints = EmojiUtils.convertToCodePoints(candidate);
			const name = UnicodeEmojis.getSurrogateName(candidate, false);
			return {
				node: {
					type: NodeType.Emoji,
					kind: {
						kind: EmojiKind.Standard,
						raw: candidate,
						codepoints,
						name,
					},
				},
				advance: [...candidate].length,
			};
		}
		return null;
	}

	private parseEmojiShortcode(text: string): ParserResult | null {
		if (!text.startsWith(':')) return null;

		const endPos = text.indexOf(':', 1);
		if (endPos === -1) return null;

		const {baseName, skinTone} = this.extractEmojiName(text.slice(1, endPos));
		if (!this.isValidEmojiName(baseName)) return null;

		const emoji = UnicodeEmojis.findEmojiByName(baseName);
		if (!emoji) return null;

		if (PLAINTEXT_SURROGATES.has(emoji)) {
			return {
				node: {type: NodeType.Text, content: emoji},
				advance: endPos + 1,
			};
		}

		const finalEmoji = skinTone !== undefined ? UnicodeEmojis.findEmojiWithSkinTone(baseName, skinTone) : emoji;
		if (!finalEmoji) return null;

		const codepoints = EmojiUtils.convertToCodePoints(finalEmoji);
		return {
			node: {
				type: NodeType.Emoji,
				kind: {
					kind: EmojiKind.Standard,
					raw: finalEmoji,
					codepoints,
					name: baseName,
				},
			},
			advance: endPos + 1,
		};
	}

	private extractEmojiName(fullName: string): {baseName: string; skinTone?: number} {
		const skinToneMatch = fullName.match(/::skin-tone-(\d)/);
		if (!skinToneMatch) return {baseName: fullName};

		const tone = Number.parseInt(skinToneMatch[1], 10);
		if (tone >= 1 && tone <= 5) {
			return {
				baseName: fullName.slice(0, skinToneMatch.index!),
				skinTone: tone,
			};
		}

		return {baseName: fullName};
	}

	private isValidEmojiName(name: string): boolean {
		return Boolean(name && /^[a-zA-Z0-9_-]+$/.test(name));
	}

	private parseCustomEmoji(text: string): ParserResult | null {
		const emojiInfo = this.extractCustomEmojiInfo(text);
		if (!emojiInfo) return null;

		const {name, id, animated, advance} = emojiInfo;

		return {
			node: {
				type: NodeType.Emoji,
				kind: {
					kind: EmojiKind.Custom,
					name,
					id,
					animated,
				},
			},
			advance,
		};
	}

	private extractCustomEmojiInfo(text: string): {name: string; id: string; animated: boolean; advance: number} | null {
		let animated = false;
		let startIndex = 0;

		if (text.startsWith('<a:')) {
			animated = true;
			startIndex = 3;
		} else if (text.startsWith('<:')) {
			startIndex = 2;
		} else {
			return null;
		}

		const end = text.indexOf('>');
		if (end === -1) return null;

		const inner = text.slice(startIndex, end);
		const parts = inner.split(':');
		if (parts.length !== 2) return null;

		const [name, id] = parts;
		if (!name || !id || !/^\d+$/.test(id)) return null;

		return {name, id, animated, advance: end + 1};
	}

	private flattenAST(nodes: Array<Node>): void {
		for (const node of nodes) {
			this.flattenNode(node);
		}
		this.flattenChildren(nodes);
	}

	private flattenNode(node: Node): void {
		switch (node.type) {
			case NodeType.Strong:
			case NodeType.Emphasis:
			case NodeType.Underline:
			case NodeType.Strikethrough:
			case NodeType.Spoiler:
			case NodeType.Sequence:
			case NodeType.Blockquote:
			case NodeType.Heading: {
				const childNode = node as FormattingNode | BlockquoteNode | HeadingNode;
				for (const child of childNode.children) {
					this.flattenNode(child);
				}
				this.flattenChildren(childNode.children, node.type === NodeType.Blockquote);
				break;
			}
			case NodeType.List: {
				const listNode = node as ListNode;
				for (const item of listNode.items) {
					for (const child of item.children) {
						this.flattenNode(child);
					}
					this.flattenChildren(item.children, false);
				}
				break;
			}
			case NodeType.Link: {
				const linkNode = node as LinkNode;
				if (linkNode.text) {
					this.flattenNode(linkNode.text);
					if (linkNode.text.type === NodeType.Sequence) {
						const sequenceNode = linkNode.text as SequenceNode;
						for (const child of sequenceNode.children) {
							this.flattenNode(child);
						}
						this.flattenChildren(sequenceNode.children, false);
					}
				}
				break;
			}
		}
	}

	private flattenChildren(nodes: Array<Node>, insideBlockquote = false): void {
		this.flattenFormattingNodes(nodes);
		this.combineAdjacentTextNodes(nodes, insideBlockquote);
		this.removeEmptyTextNodesBetweenAlerts(nodes);
	}

	private removeEmptyTextNodesBetweenAlerts(nodes: Array<Node>): void {
		let i = 0;
		while (i < nodes.length - 1) {
			const currentNode = nodes[i];
			const nextNode = nodes[i + 1];

			if (
				currentNode.type === NodeType.Alert &&
				nextNode.type === NodeType.Text &&
				nextNode.content.trim() === '' &&
				i + 2 < nodes.length &&
				nodes[i + 2].type === NodeType.Alert
			) {
				nodes.splice(i + 1, 1);
				continue;
			}
			i++;
		}
	}

	private flattenFormattingNodes(nodes: Array<Node>): void {
		let i = 0;
		while (i < nodes.length) {
			const node = nodes[i];
			if (this.isFormattingNode(node)) {
				const formattingNode = node as FormattingNode;
				this.flattenSameType(formattingNode.children, node.type);
			}
			i++;
		}
	}

	private isFormattingNode(node: Node): boolean {
		return (
			node.type === NodeType.Strong ||
			node.type === NodeType.Emphasis ||
			node.type === NodeType.Underline ||
			node.type === NodeType.Strikethrough ||
			node.type === NodeType.Spoiler ||
			node.type === NodeType.Sequence
		);
	}

	private flattenSameType(children: Array<Node>, nodeType: NodeType): void {
		let i = 0;
		while (i < children.length) {
			const child = children[i];
			if (child.type === nodeType) {
				const innerNodes = 'children' in child ? (child as FormattingNode).children : [];
				children.splice(i, 1, ...innerNodes);
			} else {
				i++;
			}
		}
	}

	private combineAdjacentTextNodes(nodes: Array<Node>, insideBlockquote = false): void {
		const merged: Array<Node> = [];

		for (let i = 0; i < nodes.length; i++) {
			const node = nodes[i];

			if (node.type === NodeType.Text) {
				const lastMerged = merged[merged.length - 1];

				if (lastMerged?.type === NodeType.Text) {
					if (insideBlockquote || !lastMerged.content.endsWith('\n')) {
						lastMerged.content += node.content;
					} else {
						merged.push(node);
					}
				} else {
					merged.push(node);
				}
			} else {
				merged.push(node);
			}
		}

		nodes.length = 0;
		nodes.push(...merged);
	}
}
