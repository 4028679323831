import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

export type ExpressionPickerTabType = 'gifs' | 'stickers' | 'emojis';

type State = Readonly<{
	selectedTab: ExpressionPickerTabType;
}>;

const initialState: State = {
	selectedTab: 'emojis',
} as const;

class ExpressionPickerStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'EXPRESSION_PICKER_TAB_SELECT':
				return this.handleTabSelect(action);
			default:
				return false;
		}
	}

	getSelectedTab(): ExpressionPickerTabType {
		return this.state.selectedTab;
	}

	useSelectedTab(): ExpressionPickerTabType {
		const {selectedTab} = this.useStore();
		return selectedTab;
	}

	private handleTabSelect({tab}: {tab: ExpressionPickerTabType}): boolean {
		this.setState(() => ({
			selectedTab: tab,
		}));
		return true;
	}
}

export default new ExpressionPickerStore();
