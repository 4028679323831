import {ListChecks} from '@phosphor-icons/react';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useDebounceCallback, useDocumentTitle, useResizeObserver} from 'usehooks-ts';
import {Permissions} from '~/Constants';
import * as MessageActionCreators from '~/actions/MessageActionCreators';
import * as ReadStateActionCreators from '~/actions/ReadStateActionCreators';
import {Message} from '~/components/channel/Message';
import {UploadManager} from '~/components/channel/UploadManager';
import {Button} from '~/components/uikit/Button/Button';
import {Scroller} from '~/components/uikit/Scroller';
import {Spinner} from '~/components/uikit/Spinner';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {MessageRecord} from '~/records/MessageRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ChannelStateStore from '~/stores/ChannelStateStore';
import GuildStore from '~/stores/GuildStore';
import MessageStore from '~/stores/MessageStore';
import ReadStateStore from '~/stores/ReadStateStore';
import WindowStore from '~/stores/WindowStore';
import * as ChannelUtils from '~/utils/ChannelUtils';
import * as DateUtils from '~/utils/DateUtils';
import * as PermissionUtils from '~/utils/PermissionUtils';
import * as SnowflakeUtils from '~/utils/SnowflakeUtils';

const CLOSE_TO_BOTTOM_PIXELS = 300;
const INITIAL_MESSAGE_LOAD = 50;
const SCROLL_DEBOUNCE_MS = 100;
const HIGHLIGHT_DURATION_MS = 2000;

type ChatProps = {
	channel: ChannelRecord;
};

export const Chat = observer(({channel}: ChatProps) => {
	// Document title
	const guild = GuildStore.useGuild(channel.guildId);
	useDocumentTitle(`Fluxer | #${channel.name}${guild ? ` | ${guild.name}` : ''}`);

	// Refs
	const scrollerRef = React.useRef<HTMLDivElement>(null);
	const bottomRef = React.useRef<HTMLDivElement>(null);
	const spinnerRef = React.useRef<HTMLDivElement>(null);
	const isUserScrollingRef = React.useRef(false);
	const prevMessagesRef = React.useRef<Array<MessageRecord>>([]);
	const intersectionObserverRef = React.useRef<IntersectionObserver | null>(null);

	// Store states
	const channelState = ChannelStateStore.useChannelState(channel.id);
	const messages = MessageStore.useMessages(channel.id);
	const {focused} = WindowStore.useStore();
	const readState = ReadStateStore.useReadState(channel.id);
	const hasUnreadMessages = ReadStateStore.useChannelUnreadMessages(channel.id);

	// Local state
	const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(true);
	const [isFirstLoad, setIsFirstLoad] = React.useState(true);

	// Derived state
	const unreadCount = hasUnreadMessages
		? readState
			? MessageStore.getCountBelowMessage(channel.id, readState.message_id)
			: messages.length
		: 0;

	const shouldAutoAckMessages = hasUnreadMessages && isScrolledToBottom && focused && !readState?.manual;

	// Message loading
	const loadMoreMessages = React.useCallback(
		async (forceRetry = false) => {
			if (
				!forceRetry &&
				(channelState.isLoading || channelState.failedToLoadMessages || !channelState.hasMoreMessages)
			) {
				return;
			}

			ChannelStateStore.setLoadingState(channel.id, true);

			try {
				await MessageActionCreators.fetch(channel.id, {
					limit: isFirstLoad ? INITIAL_MESSAGE_LOAD : 50,
					...(messages[0]?.id ? {before: messages[0]?.id} : {}),
				});
			} catch (_) {
				// Error handling is done in MessageActionCreators
				// and state is updated through the store
			}
		},
		[channel.id, messages, isFirstLoad, channelState],
	);

	// Scroll handling
	const scrollToBottom = React.useCallback((smooth = false) => {
		const scroller = scrollerRef.current;
		if (!scroller) return;

		requestAnimationFrame(() => {
			scroller.scrollTo({
				top: scroller.scrollHeight,
				behavior: smooth ? 'smooth' : 'auto',
			});
		});
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const debouncedSaveScrollPosition = React.useCallback(
		_.debounce((position: number, scrollHeight: number) => {
			ChannelStateStore.setScrollPosition(channel.id, position, scrollHeight);
		}, SCROLL_DEBOUNCE_MS),
		[channel.id],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const handleScroll = React.useCallback(() => {
		const scroller = scrollerRef.current;
		if (!scroller) return;

		const isNearBottom = scroller.scrollHeight - scroller.scrollTop - scroller.clientHeight < CLOSE_TO_BOTTOM_PIXELS;
		setIsScrolledToBottom(isNearBottom);

		if (isUserScrollingRef.current) {
			debouncedSaveScrollPosition(scroller.scrollTop, scroller.scrollHeight);
		}

		// Load more messages when scrolling up
		if (
			scroller.scrollTop < CLOSE_TO_BOTTOM_PIXELS &&
			!channelState.isLoading &&
			channelState.hasMoreMessages &&
			!isFirstLoad
		) {
			const oldScrollHeight = scroller.scrollHeight;
			const oldScrollTop = scroller.scrollTop;

			loadMoreMessages().then(() => {
				requestAnimationFrame(() => {
					const newScrollHeight = scroller.scrollHeight;
					scroller.scrollTop = newScrollHeight - (oldScrollHeight - oldScrollTop);
				});
			});
		}
	}, [channel.id, channelState, loadMoreMessages, isFirstLoad, debouncedSaveScrollPosition]);

	// Message acknowledgment
	const ackLastMessage = React.useCallback(
		(force = false) => {
			if ((shouldAutoAckMessages || force) && channel.lastMessageId) {
				ReadStateActionCreators.ack({
					channelId: channel.id,
					messageId: channel.lastMessageId,
					mentionCount: 0,
				});
			}
		},
		[channel.id, channel.lastMessageId, shouldAutoAckMessages],
	);

	const markAsRead = React.useCallback(() => {
		const scroller = scrollerRef.current;
		if (scroller) {
			scroller.scrollTo({
				top: scroller.scrollHeight,
				behavior: 'smooth',
			});
		}
		// Force acknowledge the last message regardless of auto-ack conditions
		ackLastMessage(true);
	}, [ackLastMessage]);

	// Initial load effect
	React.useEffect(() => {
		if (!isFirstLoad) return;

		if (!channelState.hasLoadedFirstPage) {
			loadMoreMessages().then(() => {
				if (channelState.scrollPosition && channelState.lastKnownScrollHeight) {
					const scroller = scrollerRef.current;
					if (scroller && scroller.scrollHeight >= channelState.lastKnownScrollHeight) {
						scroller.scrollTop = channelState.scrollPosition;
					} else {
						scrollToBottom();
					}
				} else {
					scrollToBottom();
				}
				setIsFirstLoad(false);
			});
		} else {
			scrollToBottom();
			setIsFirstLoad(false);
		}
	}, [isFirstLoad, channelState, loadMoreMessages, scrollToBottom]);

	// Message update effect
	React.useEffect(() => {
		const lastMessage = messages[messages.length - 1];
		const prevLastMessage = prevMessagesRef.current[prevMessagesRef.current.length - 1];

		if (!lastMessage) return;

		const isNewMessage = lastMessage.id !== prevLastMessage?.id;
		const isUserMessage = lastMessage.author.id === AuthenticationStore.getId();

		if (isNewMessage && (isScrolledToBottom || isUserMessage)) {
			scrollToBottom();
		}

		if (isNewMessage && shouldAutoAckMessages) {
			ackLastMessage();
		}

		prevMessagesRef.current = messages.slice();
	}, [messages, isScrolledToBottom, shouldAutoAckMessages, ackLastMessage, scrollToBottom]);

	// User scroll detection
	React.useEffect(() => {
		const scroller = scrollerRef.current;
		if (!scroller) return;

		const handleUserScroll = () => {
			isUserScrollingRef.current = true;
			setTimeout(() => {
				isUserScrollingRef.current = false;
			}, 150);
		};

		scroller.addEventListener('wheel', handleUserScroll, {passive: true});
		scroller.addEventListener('touchstart', handleUserScroll, {passive: true});

		return () => {
			scroller.removeEventListener('wheel', handleUserScroll);
			scroller.removeEventListener('touchstart', handleUserScroll);
		};
	}, []);

	// Bottom visibility observer
	React.useEffect(() => {
		if (!bottomRef.current || !scrollerRef.current) return;

		intersectionObserverRef.current = new IntersectionObserver(
			(entries) => {
				// biome-ignore lint/complexity/noForEach: <explanation>
				entries.forEach((entry) => {
					if (entry.target === bottomRef.current) {
						setIsScrolledToBottom(entry.isIntersecting);
					}
				});
			},
			{
				root: scrollerRef.current,
				threshold: [0, 1],
			},
		);

		intersectionObserverRef.current.observe(bottomRef.current);

		return () => {
			intersectionObserverRef.current?.disconnect();
		};
	}, []);

	// Window resize handling
	const handleResize = useDebounceCallback(() => {
		if (isScrolledToBottom) {
			scrollToBottom();
		}
	}, SCROLL_DEBOUNCE_MS);

	useResizeObserver({
		ref: scrollerRef,
		onResize: handleResize,
	});

	// Keyboard shortcuts
	React.useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				markAsRead();
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		return () => window.removeEventListener('keydown', handleKeyDown);
	}, [markAsRead]);

	// Channel selection
	React.useEffect(() => {
		Dispatcher.dispatch({
			type: 'CHANNEL_SELECT',
			guildId: channel.guildId,
			channelId: channel.id,
		});

		return () => {
			Dispatcher.dispatch({type: 'CHANNEL_DESELECT'});
		};
	}, [channel.id, channel.guildId]);

	// Component Dispatch subscriptions
	React.useEffect(() => {
		const handleJumpMessage = ({messageId}: {messageId: string}) => {
			const element = document.getElementById(`message-${channel.id}-${messageId}`);
			if (element) {
				element.scrollIntoView({behavior: 'smooth', block: 'center'});
				Dispatcher.dispatch({type: 'MESSAGE_HIGHLIGHT', messageId});
				setTimeout(() => {
					Dispatcher.dispatch({type: 'MESSAGE_HIGHLIGHT_CLEAR'});
				}, HIGHLIGHT_DURATION_MS);
			}
		};

		const disposeJumpMessage = ComponentDispatch.subscribe('MESSAGE_JUMP', handleJumpMessage);
		const disposeScrollToBottom = ComponentDispatch.subscribe('SCROLL_TO_BOTTOM', () => {
			const scroller = scrollerRef.current;
			if (!scroller) return;

			const isCloseToBottom =
				scroller.scrollHeight - scroller.scrollTop - scroller.clientHeight < CLOSE_TO_BOTTOM_PIXELS;
			if (isCloseToBottom) {
				scrollToBottom(true);
			}
		});

		return () => {
			disposeJumpMessage();
			disposeScrollToBottom();
		};
	}, [channel.id, scrollToBottom]);

	const canAttachFiles = PermissionUtils.can(Permissions.SEND_MESSAGES | Permissions.ATTACH_FILES, {
		guildId: channel.guildId,
		channelId: channel.id,
	});

	return (
		<div className="relative flex min-h-0 min-w-0 flex-1 gap-1">
			{canAttachFiles && <UploadManager channel={channel} />}

			{unreadCount > 0 && (
				<div
					aria-label={i18n.Messages.JUMP_TO_LAST_UNREAD_MESSAGE}
					className="absolute top-0 right-4 left-4 z-elevated-3 flex h-8 min-h-[24px] cursor-pointer items-center justify-between rounded-br-lg rounded-bl-lg bg-brand-primary px-3 font-medium text-sm text-text-on-brand-primary leading-[24px] opacity-95"
					onClick={markAsRead}
					onKeyDown={(event) => event.key === 'Enter' && markAsRead()}
					role="button"
					tabIndex={0}
				>
					<div className="truncate">
						{i18n.format(i18n.Messages.UNREAD_MESSAGES, {
							count: unreadCount,
							date: DateUtils.getFormattedDateTime(SnowflakeUtils.extractTimestamp(readState?.message_id ?? 0n)),
						})}
					</div>
					<div className="flex items-center truncate font-semibold">
						{i18n.Messages.MARK_AS_READ}
						<ListChecks className="ml-1 h-4 w-4" />
					</div>
				</div>
			)}

			<Scroller
				ref={scrollerRef}
				onScroll={handleScroll}
				className="absolute inset-0 flex min-h-0 min-w-0 flex-1 flex-grow flex-col overflow-x-hidden overflow-y-scroll [overflow-anchor:none]"
			>
				{!channelState.hasMoreMessages && (
					<div className="m-4 mt-auto flex min-w-0 flex-col gap-1 pt-[30px] text-text-primary">
						<div className="pointer-events-none flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-background-primary text-text-primary-muted">
							{ChannelUtils.getIcon(channel.type, {className: 'h-12 w-12'})}
						</div>
						<h1 className="mt-3 font-medium text-3xl">
							{i18n.format(i18n.Messages.WELCOME_TO_CHANNEL_TITLE, {name: channel.name})}
						</h1>
						<p className="min-w-0 text-lg text-text-primary-muted">
							{i18n.format(i18n.Messages.WELCOME_TO_CHANNEL_DESCRIPTION, {name: channel.name})}
						</p>
					</div>
				)}

				{channelState.hasMoreMessages && !channelState.failedToLoadMessages && (
					<div className="mt-auto flex justify-center py-[30px]" ref={spinnerRef}>
						<Spinner className="h-8 w-8" />
					</div>
				)}

				{channelState.failedToLoadMessages && (
					<div className="mt-auto flex justify-center py-[30px]">
						<div className="flex flex-col items-center gap-2">
							<div className="text-center text-lg text-text-primary-muted">{i18n.Messages.FAILED_TO_LOAD_MESSAGES}</div>
							<Button onClick={() => loadMoreMessages(true)} submitting={channelState.isLoading}>
								{i18n.Messages.RETRY}
							</Button>
						</div>
					</div>
				)}

				{channelState.hasLoadedFirstPage &&
					messages.map((message, index) => (
						<Message key={message.id} channel={channel} message={message} prevMessage={messages[index - 1]} />
					))}

				<div className="mt-[30px]" ref={bottomRef} />
			</Scroller>
		</div>
	);
});

Chat.displayName = 'Chat';
