import {AnimatePresence, motion} from 'motion/react';
import React from 'react';
import {FluxerIcon} from '~/components/icons/FluxerIcon';
import {i18n} from '~/i18n';
import ConnectionStore from '~/stores/ConnectionStore';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import {SplashScreenUtils} from '~/utils/SplashScreenUtils';

const SPLASH_SCREEN_DELAY = 10000;

const useConnectionState = () => {
	const connected = ConnectionStore.useConnectionStatus();
	const [showSplash, setShowSplash] = React.useState(true);

	React.useEffect(() => {
		if (connected) {
			setShowSplash(false);
			return;
		}

		const timer = setTimeout(() => setShowSplash(true), SPLASH_SCREEN_DELAY);
		return () => clearTimeout(timer);
	}, [connected]);

	return showSplash;
};

export const SplashScreen = () => {
	if (DeveloperOptionsStore.getBypassSplashScreen()) return null;
	return <AnimatePresence initial={false}>{useConnectionState() && <SplashScreenContent />}</AnimatePresence>;
};

const SplashScreenContent = () => {
	const [initialLoadTime] = React.useState(Date.now());

	return (
		<motion.div
			initial={{opacity: 0}}
			animate={{opacity: 1}}
			exit={{opacity: 0}}
			transition={{duration: 0.5}}
			className="fixed inset-0 z-overlay flex items-center justify-center bg-background-primary"
		>
			<div className="flex flex-col items-center gap-4">
				<div className="relative flex h-20 w-20 items-center justify-center">
					<div className="absolute inset-0 z-0 h-20 w-20 animate-ping rounded-full bg-brand-primary opacity-75" />
					<FluxerIcon className="z-elevated-1 h-20 w-20" />
				</div>

				<div className="flex flex-col items-center gap-1.5 text-center">
					<h1 className="font-semibold text-text-primary text-xl md:text-2xl">
						{SplashScreenUtils.getSplashScreenTitle(initialLoadTime)}
					</h1>
					<p className="text-text-tertiary">{i18n.Messages.SPLASH_SCREEN_DESCRIPTION}</p>
				</div>
			</div>
		</motion.div>
	);
};
