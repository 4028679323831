import {LRUCache} from 'lru-cache';
import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';
import type {ChannelRecord} from '~/records/ChannelRecord';
import {type Message, messageMentionsCurrentUser} from '~/records/MessageRecord';
import type {UserRecord} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ChannelStore from '~/stores/ChannelStore';
import UserStore from '~/stores/UserStore';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as NicknameUtils from '~/utils/NicknameUtils';
import * as NotificationUtils from '~/utils/NotificationUtils';

type State = Readonly<{
	notifiedMessageIds: LRUCache<string, boolean>;
	browserNotificationsEnabled: boolean;
}>;

const CACHE_SIZE = 500;
const STORE_VERSION = 1;
const PERSISTED_KEYS = ['browserNotificationsEnabled'] as const;

const initialState: State = {
	notifiedMessageIds: new LRUCache({max: CACHE_SIZE}),
	browserNotificationsEnabled: NotificationUtils.isGranted(),
};

type NotificationData = Readonly<{
	message: Message;
	user: UserRecord;
	channel: ChannelRecord;
}>;

class NotificationStore extends PersistedStore<State> {
	constructor() {
		super(initialState, 'NotificationStore', STORE_VERSION, PERSISTED_KEYS as unknown as Array<keyof State>);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'MESSAGE_CREATE':
				return this.handleMessageCreate(action);
			case 'NOTIFICATION_PERMISSION_GRANTED':
				return this.handleNotificationPermissionGranted();
			case 'NOTIFICATION_PERMISSION_DENIED':
				return this.handleNotificationPermissionDenied();
			default:
				return false;
		}
	}

	getBrowserNotificationsEnabled(): boolean {
		return this.state.browserNotificationsEnabled;
	}

	private validateNotificationData(message: Message): NotificationData | null {
		if (message.author.id === AuthenticationStore.getId()) {
			return null;
		}
		if (!messageMentionsCurrentUser(message)) {
			return null;
		}
		if (this.state.notifiedMessageIds.has(message.id)) {
			return null;
		}
		const user = UserStore.getUser(message.author.id);
		const channel = ChannelStore.getChannel(message.channel_id);
		if (!user || !channel) {
			return null;
		}
		return {message, user, channel};
	}

	private showNotification(data: NotificationData): void {
		const {message, user, channel} = data;
		NotificationUtils.showNotification({
			title: `@${NicknameUtils.getNickname(user, channel.guildId)}`,
			body: message.content,
			icon: AvatarUtils.getUserAvatarURL(user),
			url: `/channels/${channel.guildId}/${channel.id}`,
		});

		this.setState((prevState) => {
			const newCache = new LRUCache<string, boolean>({max: CACHE_SIZE});
			prevState.notifiedMessageIds.forEach((value, key) => {
				newCache.set(key, value);
			});
			newCache.set(message.id, true);
			return {
				...prevState,
				notifiedMessageIds: newCache,
			};
		});
	}

	private handleMessageCreate({message}: {message: Message}): boolean {
		const notificationData = this.validateNotificationData(message);
		if (notificationData) {
			this.showNotification(notificationData);
			return true;
		}
		return false;
	}

	private handleNotificationPermissionGranted(): boolean {
		this.setState((prevState) => ({
			...prevState,
			browserNotificationsEnabled: true,
		}));
		return true;
	}

	private handleNotificationPermissionDenied(): boolean {
		this.setState((prevState) => ({
			...prevState,
			browserNotificationsEnabled: false,
		}));
		return true;
	}
}

export default new NotificationStore();
