import {ArrowLeft, type Icon, MagnifyingGlass, SmileySad, Star, TrendUp, X} from '@phosphor-icons/react';
import clsx from 'clsx';
import _ from 'lodash';
import {AnimatePresence, motion} from 'motion/react';
import React from 'react';
import * as ExpressionPickerActionCreators from '~/actions/ExpressionPickerActionCreators';
import type {TenorFeatured, TenorGif} from '~/actions/TenorActionCreators';
import * as TenorActionCreators from '~/actions/TenorActionCreators';
import * as UserSettingsActionCreators from '~/actions/UserSettingsActionCreators';
import styles from '~/components/channel/GifPicker.module.css';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {useHover} from '~/hooks/useHover';
import {i18n} from '~/i18n';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import UserSettingsStore, {type FavoriteGif} from '~/stores/UserSettingsStore';
import * as DimensionUtils from '~/utils/DimensionUtils';

const TENOR_URL_REGEX = /https:\/\/tenor.com\/view\/([a-zA-Z0-9-]+)/;

const isTenorGif = (gif: TenorGif | FavoriteGif): gif is TenorGif => TENOR_URL_REGEX.test(gif.url);

type View = 'default' | 'trending' | 'favorites';

const mediaCalculator = DimensionUtils.createCalculator({
	maxWidth: 227,
	maxHeight: 400,
	responsive: true,
});

export const GifPicker = () => {
	const [featured, setFeatured] = React.useState<TenorFeatured>({categories: [], gifs: []});
	const [gifs, setGifs] = React.useState<Array<TenorGif>>([]);
	const [loading, setLoading] = React.useState(true);
	const [searchTerm, setSearchTerm] = React.useState('');
	const [suggestions, setSuggestions] = React.useState<Array<string>>([]);
	const [view, setView] = React.useState<View>('default');
	const {favorite_gifs: favoriteGifs} = UserSettingsStore.useStore();
	const bottomDivRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (view === 'default') {
			setLoading(true);
			TenorActionCreators.getFeatured().then((data) => {
				setFeatured(data);
				setLoading(false);
			});
		}
	}, [view]);

	const handleSearch = React.useCallback(
		_.debounce((term: string) => {
			setLoading(true);
			if (term) {
				TenorActionCreators.search(term).then((results) => {
					setGifs(results);
					setLoading(false);
				});
			} else {
				setGifs([]);
				setLoading(false);
			}
		}, 300),
		[],
	);

	React.useEffect(() => {
		handleSearch(searchTerm);
	}, [searchTerm, handleSearch]);

	const fetchSuggestions = React.useCallback(
		_.debounce((term: string) => {
			if (term) {
				TenorActionCreators.suggest(term).then((suggestions) => {
					setSuggestions(suggestions);
				});
			}
		}, 300),
		[],
	);

	React.useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && searchTerm) {
					fetchSuggestions(searchTerm);
				}
			},
			{root: null, rootMargin: '0px', threshold: 1.0},
		);

		const bottomDivElement = bottomDivRef.current;
		if (bottomDivElement) {
			observer.observe(bottomDivElement);
		}

		return () => {
			if (bottomDivElement) {
				observer.unobserve(bottomDivElement);
			}
		};
	}, [searchTerm, fetchSuggestions]);

	React.useEffect(() => {
		if (view === 'trending') {
			setLoading(true);
			TenorActionCreators.getTrending().then((results) => {
				setGifs(results);
				setLoading(false);
			});
		}
	}, [view]);

	const renderHeader = () => {
		if (view !== 'default') {
			return (
				<GifPickerSearchBarWithoutInput
					title={view === 'trending' ? i18n.Messages.TRENDING_GIFS : i18n.Messages.FAVORITES}
					reset={() => setView('default')}
				/>
			);
		}

		return <GifPickerSearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />;
	};

	const renderContent = () => {
		if (loading) {
			return <SkeletonView />;
		}

		if (view === 'default' && !searchTerm) {
			return <FeaturedGrid featured={featured} setView={setView} setSearchTerm={setSearchTerm} />;
		}

		const gifsToRender = view === 'favorites' ? favoriteGifs : gifs;
		const formattedGifs = gifsToRender.map((gif) => {
			const {dimensions} = mediaCalculator.calculate(
				{
					width: gif.width,
					height: gif.height,
				},
				{forceScale: true},
			);
			const newWidth = dimensions.width;
			const newHeight = dimensions.height;

			return {
				id: view === 'favorites' ? gif.url : isTenorGif(gif) ? gif.id : gif.url,
				title: isTenorGif(gif) ? gif.title : '',
				onClick: () => {
					if (isTenorGif(gif)) {
						TenorActionCreators.registerShare(gif.id, searchTerm);
					}
					ComponentDispatch.dispatch('GIF_SELECT', {gif});
					ExpressionPickerActionCreators.close();
				},
				url: gif.url,
				src: gif.src,
				proxySrc: gif.proxy_src,
				isCategory: false,
				isTenor: isTenorGif(gif),
				width: newWidth,
				height: newHeight,
				naturalWidth: gif.width,
				naturalHeight: gif.height,
			};
		});

		return <GifGridRenderer gifs={formattedGifs} />;
	};

	if (view === 'favorites' && favoriteGifs.length === 0) {
		return (
			<div className={styles.gifPickerContainer}>
				{renderHeader()}
				<div className={styles.gifPickerMain}>
					<Slate
						icon={SmileySad}
						title={i18n.Messages.NO_FAVORITES_TITLE}
						description={i18n.Messages.NO_FAVORITES_DESCRIPTION}
					/>
				</div>
			</div>
		);
	}

	if (!loading && (view === 'trending' || (view === 'default' && searchTerm)) && gifs.length === 0) {
		return (
			<div className={styles.gifPickerContainer}>
				{renderHeader()}
				<div className={styles.gifPickerMain}>
					<Slate
						icon={SmileySad}
						title={i18n.Messages.NO_SEARCH_RESULTS}
						description={i18n.Messages.NO_SEARCH_RESULTS_DESCRIPTION}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.gifPickerContainer}>
			{renderHeader()}
			<div className={styles.gifPickerMain}>
				<ScrollArea className={styles.scrollArea}>
					<AnimatePresence>{renderContent()}</AnimatePresence>
					{suggestions.length > 0 && (
						<div className={styles.suggestionsContainer}>
							{suggestions.map((suggestion) => (
								<button
									type="button"
									key={suggestion}
									className={styles.suggestionTag}
									onClick={() => setSearchTerm(suggestion)}
								>
									{suggestion}
								</button>
							))}
						</div>
					)}
					<div ref={bottomDivRef} style={{height: '1px'}} />
				</ScrollArea>
			</div>
		</div>
	);
};

type SearchBarProps = {
	searchTerm: string;
	setSearchTerm: (term: string) => void;
};

const GifPickerSearchBar = ({searchTerm, setSearchTerm}: SearchBarProps) => (
	<div className={styles.searchBarContainer}>
		{searchTerm && (
			<button type="button" className={styles.searchBarBackButton} onClick={() => setSearchTerm('')}>
				<ArrowLeft weight="regular" />
			</button>
		)}
		<div className={styles.searchBar}>
			<div className={styles.searchBarInputContainer}>
				<input
					// biome-ignore lint/a11y/noAutofocus: <explanation>
					autoFocus={true}
					value={searchTerm}
					placeholder={i18n.Messages.GIF_PICKER_SEARCH_PLACEHOLDER}
					onChange={(event) => setSearchTerm(event.target.value)}
					className={styles.searchBarInput}
				/>
				<div
					role="button"
					tabIndex={searchTerm ? 0 : -1}
					aria-hidden={!searchTerm}
					aria-label={i18n.Messages.CLEAR_SEARCH}
					onClick={searchTerm ? () => setSearchTerm('') : undefined}
					onKeyDown={searchTerm ? (event) => event.key === 'Enter' && setSearchTerm('') : undefined}
					className={clsx(styles.searchBarIconContainer, searchTerm ? styles.cursorPointer : styles.cursorText)}
				>
					<div className={styles.searchBarIconWrapper}>
						<MagnifyingGlass className={clsx(styles.icon, !searchTerm && styles.visible)} weight="regular" />
						<X className={clsx(styles.icon, searchTerm && styles.visible)} weight="regular" />
					</div>
				</div>
			</div>
		</div>
	</div>
);

const GifPickerSearchBarWithoutInput = ({title, reset}: {title: string; reset: () => void}) => (
	<div className={styles.searchBarContainer}>
		<div className={styles.searchBarTitleWrapper}>
			<button type="button" className={styles.searchBarBackButton} onClick={reset}>
				<ArrowLeft weight="regular" />
			</button>
			<div className={styles.searchBarTitle}>{title}</div>
		</div>
	</div>
);

type GridItemProps = {
	id: string;
	icon?: React.ReactNode;
	title: string;
	onClick: () => void;
	url: string;
	src: string;
	proxySrc: string;
	isCategory: boolean;
	isTenor: boolean;
	width: number;
	height: number;
	naturalWidth?: number;
	naturalHeight?: number;
	className?: string;
};

const GifGridRenderer = ({gifs}: {gifs: Array<GridItemProps>}) => {
	const [firstColumnGifs, setFirstColumnGifs] = React.useState<Array<GridItemProps>>([]);
	const [secondColumnGifs, setSecondColumnGifs] = React.useState<Array<GridItemProps>>([]);

	React.useEffect(() => {
		let firstColumnHeight = 0;
		let secondColumnHeight = 0;
		const firstColumn: Array<GridItemProps> = [];
		const secondColumn: Array<GridItemProps> = [];

		for (const gif of gifs) {
			if (firstColumnHeight <= secondColumnHeight) {
				firstColumn.push(gif);
				firstColumnHeight += gif.height;
			} else {
				secondColumn.push(gif);
				secondColumnHeight += gif.height;
			}
		}

		setFirstColumnGifs(firstColumn);
		setSecondColumnGifs(secondColumn);
	}, [gifs]);

	return (
		<div className={styles.grid}>
			<div className={styles.column}>
				{firstColumnGifs.map((gif) => (
					<GridItem key={gif.id} {...gif} />
				))}
			</div>
			<div className={styles.column}>
				{secondColumnGifs.map((gif) => (
					<GridItem key={gif.id} {...gif} />
				))}
			</div>
		</div>
	);
};

type FeaturedGridProps = {
	featured: TenorFeatured;
	setView: (view: View) => void;
	setSearchTerm: (term: string) => void;
};

const FeaturedGrid = ({featured, setView, setSearchTerm}: FeaturedGridProps) => {
	const {favorite_gifs: favoriteGifs} = UserSettingsStore.useStore();
	const randomFavoriteGif = React.useState(() => favoriteGifs[Math.floor(Math.random() * favoriteGifs.length)])[0];

	const allFeaturedItems = [
		{
			id: 'favorites',
			title: i18n.Messages.FAVORITES,
			icon: <Star className={styles.gridItemIcon} />,
			onClick: () => setView('favorites'),
			url: randomFavoriteGif?.url,
			src: randomFavoriteGif?.src,
			proxySrc: randomFavoriteGif?.proxy_src,
			isCategory: true,
			isTenor: randomFavoriteGif ? isTenorGif(randomFavoriteGif) : false,
			width: 227,
			height: 110,
			className: styles.gridItemFavorites,
		},
		{
			id: 'trending',
			title: i18n.Messages.TRENDING_GIFS,
			icon: <TrendUp className={styles.gridItemIcon} />,
			onClick: () => setView('trending'),
			url: featured.gifs[0]?.url,
			src: featured.gifs[0]?.src,
			proxySrc: featured.gifs[0]?.proxy_src,
			isCategory: true,
			isTenor: true,
			width: 227,
			height: 110,
		},
		...featured.categories.map((category) => ({
			id: category.name,
			title: category.name,
			onClick: () => setSearchTerm(category.name),
			url: category.src,
			src: category.src,
			proxySrc: category.proxy_src,
			isCategory: true,
			isTenor: true,
			width: 227,
			height: 110,
		})),
	];

	return <GifGridRenderer gifs={allFeaturedItems} />;
};

const GridItem = ({
	icon,
	title,
	onClick,
	url,
	src,
	proxySrc,
	isCategory,
	isTenor,
	className,
	width,
	height,
	naturalWidth,
	naturalHeight,
}: GridItemProps) => {
	const [hoverRef, isHovering] = useHover();
	const {favorite_gifs: favoriteGifs} = UserSettingsStore.useStore();
	const isFavorited = favoriteGifs.some((gif) => gif.url === url);

	const toggleFavorite = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (isFavorited) {
			UserSettingsActionCreators.removeFavoriteGif(url);
		} else if (naturalWidth && naturalHeight) {
			UserSettingsActionCreators.addFavoriteGif({
				url,
				src,
				proxy_src: proxySrc,
				width: naturalWidth,
				height: naturalHeight,
			});
		}
	};

	return (
		<motion.div
			role="button"
			tabIndex={0}
			className={clsx(styles.gridItem, isCategory ? styles.gridItemCategory : styles.gridItemGif, className)}
			onClick={onClick}
			onKeyDown={(event) => event.key === 'Enter' && onClick?.()}
			ref={hoverRef}
			style={{width, height}}
			initial={{opacity: 0}}
			animate={{opacity: 1}}
			exit={{opacity: 0}}
		>
			<div className={styles.gridItemBackdrop} />

			{isHovering && naturalWidth && naturalHeight && (
				<Tooltip text={isFavorited ? i18n.Messages.REMOVE_FROM_FAVORITES : i18n.Messages.ADD_TO_FAVORITES}>
					<button
						type="button"
						className={clsx(styles.favoriteButton, isFavorited && styles.favoriteButtonActive)}
						onClick={toggleFavorite}
					>
						<Star weight={isFavorited ? 'fill' : 'bold'} className={styles.favoriteButtonIcon} />
					</button>
				</Tooltip>
			)}

			{isCategory && (
				<div className={styles.gridItemCategoryTitle}>
					{icon}
					<span className={styles.gridItemCategoryTitleText}>{title}</span>
				</div>
			)}

			{proxySrc && isTenor && (
				// biome-ignore lint/a11y/useMediaCaption: <explanation>
				<video className={styles.gif} autoPlay={true} loop={true} preload="auto" src={proxySrc} />
			)}

			{proxySrc && !isTenor && (
				<img className={styles.gif} width={width} height={height} src={proxySrc} alt={title} loading="lazy" />
			)}
		</motion.div>
	);
};

const Slate = ({
	icon: Icon,
	title,
	description,
}: {
	icon: Icon;
	title: string;
	description: string;
}) => (
	<div className="flex h-[calc(100%-64px)] w-full items-center justify-center px-4">
		<div className="flex flex-col items-center gap-2">
			<Icon className="h-14 w-14 text-text-primary-muted" />
			<div className="flex flex-col items-center gap-2 text-center">
				<h3 className="font-semibold text-text-primary text-xl">{title}</h3>
				<p className="text-base text-text-primary-muted">{description}</p>
			</div>
		</div>
	</div>
);

const SkeletonView = () => {
	const skeletonItems = new Array(6).fill(null).map((_, index) => (
		// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
		<div key={index} className={styles.skeletonItem} />
	));

	return (
		<div className={styles.grid}>
			<div className={styles.column}>{skeletonItems.filter((_, index) => index % 2 === 0)}</div>
			<div className={styles.column}>{skeletonItems.filter((_, index) => index % 2 !== 0)}</div>
		</div>
	);
};
