import {Trash} from '@phosphor-icons/react';
import React from 'react';
import {useForm} from 'react-hook-form';
import {ChannelTypes} from '~/Constants';
import * as ChannelActionCreators from '~/actions/ChannelActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Form} from '~/components/form/Form';
import {Input, Textarea} from '~/components/form/Input';
import {ConfirmModal} from '~/components/modals/ConfirmModal';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import type {ChannelRecord} from '~/records/ChannelRecord';
import ChannelStore from '~/stores/ChannelStore';
import * as FormUtils from '~/utils/FormUtils';

export type ChannelSettingsTabType = 'overview' | 'permissions' | 'invites' | 'webhooks';

type SettingsTab = {
	type: ChannelSettingsTabType;
	label: string;
	component: React.ReactNode;
};

const DeleteChannelModal = ({channelId}: {channelId: string}) => (
	<ConfirmModal
		title={i18n.Messages.DELETE_CHANNEL_MODAL_TITLE}
		description={i18n.Messages.DELETE_CHANNEL_MODAL_DESCRIPTION}
		primaryText={i18n.Messages.DELETE_CHANNEL_MODAL_PRIMARY_ACTION}
		secondaryText={i18n.Messages.DELETE_CHANNEL_MODAL_SECONDARY_ACTION}
		onPrimary={() => ChannelActionCreators.remove(channelId)}
	/>
);

const ChannelContext = React.createContext<ChannelRecord | undefined>(undefined);

const useChannel = () => {
	const context = React.useContext(ChannelContext);
	if (context == null) {
		throw new Error('useChannel must be used within a <ChannelProvider />');
	}
	return context;
};

type ChannelProviderProps = {
	channel: ChannelRecord;
	children: React.ReactNode;
};

const ChannelProvider = ({channel, children}: ChannelProviderProps) => (
	<ChannelContext.Provider value={channel}>{children}</ChannelContext.Provider>
);

export const ChannelSettingsModal = ({channelId}: {channelId: string}) => {
	const channel = ChannelStore.useChannel(channelId);
	const [selectedTab, setSelectedTab] = React.useState<ChannelSettingsTabType>('overview');

	React.useEffect(() => {
		if (!channel) {
			ModalActionCreators.pop();
		}
	}, [channel]);

	if (!channel) {
		return null;
	}

	return (
		<ChannelProvider channel={channel}>
			<Modal.Root label={i18n.Messages.CHANNEL_SETTINGS} size="large">
				<div className="grid h-full flex-auto grid-cols-[1fr,3fr]">
					<div className="grid h-full grid-rows-[1fr,auto] bg-background-primary p-2">
						<ScrollArea className="flex flex-col">
							<div className="px-[10px] py-[6px] font-semibold text-sm text-text-tertiary">#{channel.name}</div>
							<div className="mb-2">
								{SETTINGS_TABS.map((tab) => (
									<div
										key={tab.label}
										onClick={() => setSelectedTab(tab.type)}
										onKeyDown={(event) => event.key === 'Enter' && setSelectedTab(tab.type)}
										tabIndex={0}
										aria-selected={tab.type === selectedTab}
										aria-label={tab.label}
										aria-controls={tab.type}
										role="tab"
										className="active:translate-z-0 mb-[2px] transform-gpu cursor-pointer rounded-md px-[10px] py-[6px] text-text-primary-muted leading-[20px] transition-colors duration-200 ease-in-out hover:bg-background-modifier-hover hover:text-text-primary active:translate-y-px aria-selected:bg-background-modifier-selected aria-selected:text-text-primary"
									>
										{tab.label}
									</div>
								))}
							</div>
						</ScrollArea>

						<div className="flex flex-col gap-4">
							<div
								role="button"
								onClick={() => ModalActionCreators.push(() => <DeleteChannelModal channelId={channel.id} />)}
								onKeyDown={(event) =>
									event.key === 'Enter' && ModalActionCreators.push(() => <DeleteChannelModal channelId={channel.id} />)
								}
								tabIndex={0}
								className="active:translate-z-0 mb-[2px] flex transform-gpu cursor-pointer items-center justify-between rounded-md px-[10px] py-[6px] text-text-primary-muted leading-[20px] transition-colors duration-200 ease-in-out hover:bg-background-modifier-hover hover:text-text-primary active:translate-y-px aria-selected:bg-background-modifier-selected aria-selected:text-text-primary"
							>
								{i18n.Messages.DELETE_CHANNEL}
								<Trash className="mt-px h-4 w-4" />
							</div>
						</div>
					</div>

					<ScrollArea className="relative flex h-full min-h-0 min-w-0 flex-1 flex-grow flex-col border border-background-header-secondary">
						<div className="p-5">{SETTINGS_TABS.find((tab) => tab.type === selectedTab)?.component}</div>
					</ScrollArea>
				</div>
			</Modal.Root>
		</ChannelProvider>
	);
};

type FormInputs = {
	name: string;
	topic: string | null;
	url: string | null;
};

const ChannelOverviewTab = () => {
	const channel = useChannel();
	const form = useForm<FormInputs>({
		defaultValues: React.useMemo(() => ({name: channel.name, topic: channel.topic, url: channel.url}), [channel]),
	});

	const onSubmit = async (data: FormInputs) => {
		try {
			const newChannel = await ChannelActionCreators.update(channel.id, {
				name: data.name,
				topic: data.topic,
				url: data.url,
			});

			form.reset({
				name: newChannel.name,
				topic: newChannel.topic,
				url: newChannel.url,
			});

			ToastActionCreators.createToast({
				type: 'success',
				children: i18n.Messages.CHANNEL_UPDATED,
			});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'name');
		}
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-1">
					<h2 className="font-semibold text-xl">{i18n.Messages.CHANNEL_OVERVIEW}</h2>
				</div>
			</div>

			<Form
				form={form}
				onSubmit={onSubmit}
				className="flex h-full flex-col items-center rounded-md border border-background-header-secondary bg-background-tertiary p-4"
			>
				<div className="flex w-full flex-col gap-3">
					<Input
						{...form.register('name')}
						type="text"
						label={i18n.Messages.NAME}
						placeholder={i18n.Messages.CHANNEL_NAME_PLACEHOLDER}
						minLength={1}
						maxLength={100}
						error={form.formState.errors.name?.message}
					/>

					{channel.type === ChannelTypes.GUILD_LINK ? (
						<Input
							{...form.register('url')}
							error={form.formState.errors.url?.message}
							label={i18n.Messages.URL}
							maxLength={1024}
							placeholder={i18n.Messages.URL_PLACEHOLDER}
							required={true}
							type="url"
						/>
					) : (
						<Textarea
							{...form.register('topic')}
							label={i18n.Messages.TOPIC}
							placeholder={i18n.Messages.TOPIC_PLACEHOLDER}
							maxLength={1024}
							error={form.formState.errors.topic?.message}
						/>
					)}
				</div>

				<div className="mt-4 flex">
					<Button variant="ghost" onClick={form.reset}>
						{i18n.Messages.RESET}
					</Button>
					<Button type="submit" variant="primary" submitting={form.formState.isSubmitting}>
						{i18n.Messages.SAVE_CHANGES}
					</Button>
				</div>
			</Form>
		</div>
	);
};

const ChannelPermissionsTab = () => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-1">
					<h2 className="font-semibold text-xl">{i18n.Messages.PERMISSIONS}</h2>
				</div>
			</div>
		</div>
	);
};

const ChannelInvitesTab = () => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-1">
					<h2 className="font-semibold text-xl">{i18n.Messages.INVITES}</h2>
				</div>
			</div>
		</div>
	);
};

const ChannelWebhooksTab = () => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-1">
					<h2 className="font-semibold text-xl">{i18n.Messages.WEBHOOKS}</h2>
				</div>
			</div>
		</div>
	);
};

const SETTINGS_TABS: Array<SettingsTab> = [
	{
		type: 'overview',
		label: i18n.Messages.OVERVIEW,
		component: <ChannelOverviewTab />,
	},
	{
		type: 'permissions',
		label: i18n.Messages.PERMISSIONS,
		component: <ChannelPermissionsTab />,
	},
	{
		type: 'invites',
		label: i18n.Messages.INVITES,
		component: <ChannelInvitesTab />,
	},
	{
		type: 'webhooks',
		label: i18n.Messages.WEBHOOKS,
		component: <ChannelWebhooksTab />,
	},
];
