import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';

export const sendTyping = async (channelId: string) => {
	await HttpClient.post({url: Endpoints.CHANNEL_TYPING(channelId)});
};

export const stopTyping = (channelId: string, userId: string) => {
	Dispatcher.dispatch({type: 'TYPING_STOP', channelId, userId});
};
