import {useForm} from 'react-hook-form';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import * as FormUtils from '~/utils/FormUtils';

type FormInputs = {
	password: string;
};

export const GuildDeleteModal = ({guildId}: {guildId: string}) => {
	const form = useForm<FormInputs>();

	const onSubmit = async (data: FormInputs) => {
		try {
			await GuildActionCreators.remove(guildId, data.password);
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: i18n.Messages.GUILD_DELETED});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'password');
		}
	};

	return (
		<Form form={form} onSubmit={onSubmit}>
			<Modal.Root label={i18n.Messages.DELETE_GUILD_MODAL_TITLE} size="small">
				<Modal.Header title={i18n.Messages.DELETE_GUILD_MODAL_TITLE} />
				<Modal.Content className={styles.content}>
					<Input
						{...form.register('password')}
						autoFocus={true}
						autoComplete="current-password"
						error={form.formState.errors.password?.message}
						label={i18n.Messages.PASSWORD}
						maxLength={128}
						minLength={8}
						placeholder={'•'.repeat(32)}
						required={true}
						type="password"
					/>
				</Modal.Content>
				<Modal.Footer>
					<div className={styles.footer}>
						<Button onClick={ModalActionCreators.pop} variant="ghost">
							{i18n.Messages.DELETE_GUILD_MODAL_SECONDARY_ACTION}
						</Button>
						<Button type="submit" submitting={form.formState.isSubmitting} variant="danger">
							{i18n.Messages.DELETE_GUILD_MODAL_PRIMARY_ACTION}
						</Button>
					</div>
				</Modal.Footer>
			</Modal.Root>
		</Form>
	);
};
