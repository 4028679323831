import {Check, X} from '@phosphor-icons/react';
import {motion} from 'motion/react';
import {useCallback, useEffect} from 'react';
import type {ToastPropsExtended} from '~/components/uikit/Toast';

const MINIMUM_TIMEOUT = 1500;

export const Toast = ({
	id,
	type,
	children,
	timeout = MINIMUM_TIMEOUT,
	onClick,
	onTimeout,
	onClose,
	closeToast,
}: ToastPropsExtended) => {
	useEffect(() => {
		const finalTimeout = Math.max(timeout, MINIMUM_TIMEOUT);
		const timer = setTimeout(() => {
			if (onTimeout) onTimeout();
			else closeToast(id);
		}, finalTimeout);
		return () => clearTimeout(timer);
	}, [timeout, onTimeout, closeToast, id]);

	useEffect(() => {
		return () => {
			if (onClose) onClose();
		};
	}, [onClose]);

	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			if (onClick) onClick(event);
			else closeToast(id);
		},
		[onClick, closeToast, id],
	);

	return (
		<motion.div
			onClick={handleClick}
			className="inline-flex max-w-[90vw] cursor-pointer items-center gap-3 rounded-full border-background-header-secondary bg-background-primary px-5 py-3 shadow-lg"
			initial={{opacity: 0, y: -30}}
			animate={{opacity: 1, y: 0}}
			exit={{opacity: 0, y: -30}}
			transition={{
				duration: 0.2,
				ease: 'easeOut',
			}}
		>
			{type === 'success' ? (
				<Check weight="bold" className="h-5 w-5 flex-shrink-0 text-status-online" />
			) : (
				<X weight="bold" className="h-5 w-5 flex-shrink-0 text-status-danger" />
			)}
			<span className="font-medium text-base text-text-primary leading-snug">{children}</span>
		</motion.div>
	);
};
