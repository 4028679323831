import React from 'react';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import ChannelStore from '~/stores/ChannelStore';
import * as MarkupUtils from '~/utils/MarkupUtils';

export const ChannelTopicModal = ({channelId}: {channelId: string}) => {
	const channel = ChannelStore.useChannel(channelId);

	React.useEffect(() => {
		if (!channel?.topic) {
			ModalActionCreators.pop();
		}
	}, [channel]);

	if (!channel?.topic) {
		return null;
	}

	return (
		<Modal.Root label={`#${channel.name}`} size="small">
			<Modal.Header title={`#${channel.name}`} />
			<Modal.Content className={styles.content}>{MarkupUtils.safeParse(channel.topic)}</Modal.Content>
		</Modal.Root>
	);
};
