import React from 'react';
import * as AutoUpdateActionCreators from '~/actions/AutoUpdateActionCreators';
import AutoUpdateStore from '~/stores/AutoUpdateStore';

export const AutoUpdateManager = () => {
	const {updateReady} = AutoUpdateStore.useStore();

	React.useEffect(() => {
		if (updateReady || import.meta.env.MODE === 'development') {
			return;
		}
		AutoUpdateActionCreators.checkForUpdates();
		const interval = setInterval(() => AutoUpdateActionCreators.checkForUpdates(), 1000 * 60 * 5);
		return () => {
			clearInterval(interval);
		};
	}, [updateReady]);

	return null;
};
