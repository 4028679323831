import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import * as HttpClient from '~/lib/HttpClient';
import type {Guild} from '~/records/GuildRecord';
import type {GuildRole} from '~/records/GuildRoleRecord';
import ChannelStore from '~/stores/ChannelStore';

export const create = async (params: Pick<Guild, 'name'> & {icon?: string | null}) => {
	const {body} = await HttpClient.post<Guild>({url: Endpoints.GUILDS, body: params});
	return body;
};

export const update = async (guildId: string, params: Pick<Guild, 'name' | 'description'> & {icon?: string | null}) => {
	const {body} = await HttpClient.patch<Guild>({url: Endpoints.GUILD(guildId), body: params});
	return body;
};

export const verifyDomain = async (guildId: string, domain: string, password: string): Promise<Guild> => {
	const {body} = await HttpClient.post<Guild>({
		url: Endpoints.GUILD_DOMAIN_VERIFY(guildId),
		body: {domain, password},
	});
	return body;
};

export const sortChannels = async (guildId: string, positions: Array<string>) => {
	const channelPositionsCopy = ChannelStore.getGuildChannels(guildId)
		.slice()
		.sort((a, b) => a.position - b.position)
		.map((channel) => channel.id);
	try {
		Dispatcher.dispatch({type: 'CHANNEL_POSITIONS_UPDATE', guildId, positions});
		await HttpClient.patch({url: Endpoints.GUILD_CHANNELS(guildId), body: positions});
	} catch (error) {
		Dispatcher.dispatch({type: 'CHANNEL_POSITIONS_UPDATE', guildId, positions: channelPositionsCopy});
		throw error;
	}
};

export const updateVanityURL = async (guildId: string, code: string | null) => {
	const {body} = await HttpClient.patch<{code: string}>({url: Endpoints.GUILD_VANITY_URL(guildId), body: {code}});
	return body.code;
};

export const createRole = async (guildId: string, name: string) => {
	await HttpClient.post({url: Endpoints.GUILD_ROLES(guildId), body: {name}});
};

export const updateRole = async (guildId: string, roleId: string, patch: Partial<GuildRole>) => {
	await HttpClient.patch({url: Endpoints.GUILD_ROLE(guildId, roleId), body: patch});
};

export const deleteRole = async (guildId: string, roleId: string) => {
	await HttpClient.del({url: Endpoints.GUILD_ROLE(guildId, roleId)});
};

export const sortRoles = async (guildId: string, positions: Array<string>) => {
	await HttpClient.patch({url: Endpoints.GUILD_ROLES(guildId), body: positions});
};

export const remove = async (guildId: string, password: string) => {
	await HttpClient.post({url: Endpoints.GUILD_DELETE(guildId), body: {password}});
};

export const leave = async (guildId: string) => {
	await HttpClient.del({url: Endpoints.USER_GUILDS(guildId)});
};
