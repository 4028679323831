import {clsx} from 'clsx';
import {i18n} from '~/i18n';

export const Spinner = ({className = 'h-8 w-8'}: {className?: string}) => (
	<div
		className={clsx(
			'inline-block animate-spin rounded-full border-4 border-brand-primary border-r-transparent border-solid align-middle motion-reduce:animate-[spin_1.5s_linear_infinite]',
			className,
		)}
	>
		<span className="sr-only">{i18n.Messages.LOADING}</span>
	</div>
);
