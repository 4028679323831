import React from 'react';
import * as InviteActionCreators from '~/actions/InviteActionCreators';
import {Input} from '~/components/form/Input';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {i18n} from '~/i18n';
import ChannelStore from '~/stores/ChannelStore';
import GuildStore from '~/stores/GuildStore';
import type {Invite} from '~/stores/InviteStore';

export const InviteModal = ({channelId}: {channelId: string}) => {
	const [invite, setInvite] = React.useState<Invite | null>(null);

	React.useEffect(() => {
		InviteActionCreators.create(channelId).then(setInvite);
	}, [channelId]);

	const channel = ChannelStore.getChannel(channelId)!;
	const guild = GuildStore.getGuild(channel.guildId)!;
	const title = i18n.format(i18n.Messages.INVITE_MODAL_TITLE, {guildName: guild.name});

	if (!invite) {
		return null;
	}

	return (
		<Modal.Root label={title} size="small">
			<Modal.Header title={title} />
			<Modal.Content className={styles.content}>
				<Input
					autoFocus={true}
					label={i18n.Messages.INVITE_LINK}
					name="code"
					onClick={(event) => event.currentTarget.select()}
					readOnly={true}
					type="text"
					value={`${location.protocol}//${location.host}/invite/${invite.code}`}
				/>
			</Modal.Content>
		</Modal.Root>
	);
};
