import clsx from 'clsx';
import React from 'react';
import * as ExpressionPickerActionCreators from '~/actions/ExpressionPickerActionCreators';
import {EmojiPicker} from '~/components/channel/EmojiPicker';
import {GifPicker} from '~/components/channel/GifPicker';
import {i18n} from '~/i18n';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import ExpressionPickerStore from '~/stores/ExpressionPickerStore';

const EXPRESSION_PICKER_CATEGORIES = [
	{
		type: 'gifs' as const,
		label: i18n.Messages.GIFS,
		component: GifPicker,
	},
	{
		type: 'stickers' as const,
		label: i18n.Messages.STICKERS,
		component: () => <div className="flex h-full items-center justify-center">TBD</div>,
	},
	{
		type: 'emojis' as const,
		label: i18n.Messages.EMOJIS,
		component: ({channelId}: {channelId: string}) => (
			<EmojiPicker
				channelId={channelId}
				handleSelect={(emoji) => {
					ComponentDispatch.dispatch('EMOJI_SELECT', {emoji});
					ExpressionPickerActionCreators.close();
				}}
			/>
		),
	},
];

export const ExpressionPickerPopout = ({channelId}: {channelId: string}) => {
	const {selectedTab} = ExpressionPickerStore.useStore();
	const selectedCategory = EXPRESSION_PICKER_CATEGORIES.find((category) => category.type === selectedTab)!;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	React.useEffect(() => {
		const firstInput = document.querySelector('input[type="text"]') as HTMLInputElement | null;
		if (firstInput) {
			firstInput.focus();
		}
	}, [selectedCategory]);

	return (
		<div className="relative grid h-[498px] w-[498px] grid-rows-[24px_auto] gap-2 overflow-hidden rounded-md border border-background-header-secondary bg-background-primary pt-4">
			<nav className="px-4">
				<div className="flex gap-2" role="tablist" aria-label={i18n.Messages.EXPRESSION_PICKER_CATEGORIES}>
					{EXPRESSION_PICKER_CATEGORIES.map((category) => (
						<button
							key={category.type}
							id={category.type}
							role="tab"
							type="button"
							aria-selected={selectedTab === category.type}
							className={clsx(
								'flex-none rounded-md px-2 py-0.5 font-semibold text-base leading-5 transition-colors duration-200 ease-in-out focus:outline-none',
								selectedTab === category.type
									? 'bg-background-modifier-selected text-text-primary'
									: 'bg-background-primary text-text-primary-muted hover:bg-background-modifier-hover hover:text-text-primary',
							)}
							onClick={() => ExpressionPickerActionCreators.selectTab(category.type)}
						>
							{category.label}
						</button>
					))}
				</div>
			</nav>
			<div className="relative grid grid-rows-[1fr_auto] overflow-hidden rounded-md">
				<selectedCategory.component channelId={channelId} />
			</div>
		</div>
	);
};
